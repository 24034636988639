import { Trans } from '@lingui/macro'
import { ButtonLight } from 'components/Button'
import { fetchEventRegister } from 'connectors/CompetitionEvent'
import { eventAirdrop } from 'connectors/Solana/methods'
import { LEADERBOARD_PATH, RULES_PATH } from 'constants/common'
import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc'
import { useParticipateInfo } from 'hooks/competitionEvent/useParticipateInfo'
import { useAccount } from 'hooks/solana/useAccount'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { darken } from 'polished'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useAddPopup, useLoading, useSetLoading, useWalletModalToggle } from 'state/application/hooks'
import styled from 'styled-components/macro'

import { ReactComponent as BannerCoin } from '../../assets/svg/bannerCoin.svg'
import { ReactComponent as Place1 } from '../../assets/svg/place1.svg'
import { ReactComponent as Place2 } from '../../assets/svg/place2.svg'
import { ReactComponent as Place3 } from '../../assets/svg/place3.svg'

const BannerContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: fixed;
  height: 103px;
  margin-top: 16px;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.bg11};
  color: ${({ theme }) => theme.primaryText1};
`

const CoinContainer = styled.div`
  width: 25%;
  display: flex;
`

const DescriptionContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ActionContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const PlaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  > div:not(:last-of-type) {
    margin-right: 16px;
  }
`

const PlaceCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const RewardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`

const RewardTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.primaryText1};
`

const RewardDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.primaryText1};
`

const EventDescription = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.primaryText1};
  margin-top: 16px;
  white-space: nowrap;
`

const StyledExternalLink = styled(Link)`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primary6};
  text-decoration: underline;

  :hover {
    color: ${({ theme }) => darken(0.05, theme.primary6)};
  }

  :focus {
    outline: none;
  }

  :active {
    text-decoration: none;
  }
`

const CompetitionBanner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  position: fixed;
  height: 33px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
  background-color: ${({ theme }) => theme.bg11};
`

const CompetitionText = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.primaryText1};
`

const CompetitionLink = styled(Link)`
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.primaryText1};
  text-decoration: underline;
`

const CompetitionRulesLink = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 8px;
  margin-left: 8px;

  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primary6};

  :hover {
    color: ${({ theme }) => darken(0.05, theme.primary6)};
  }

  :focus {
    outline: none;
  }

  :active {
    text-decoration: none;
  }
`

const EventBanner = () => {
  const { library, connector } = useActiveWeb3React()
  const { pathname } = useLocation()
  const account = useAccount()
  const addPopup = useAddPopup()
  const toggleWalletModal = useWalletModalToggle()
  const loading = useLoading()
  const setLoading = useSetLoading()
  const history = useHistory()

  const { isUserParticipant, refetch } = useParticipateInfo(account)

  const handleRegister = async () => {
    try {
      setLoading(true)
      try {
        await eventAirdrop(library, connector, account)
      } catch (err) {
        throw new Error('Cannot airdrop tokens.')
      }
      try {
        await fetchEventRegister(account)
      } catch (err) {
        throw new Error(`Cannot register user. ${err.message}`)
      }
      addPopup(
        {
          text: `Successful registration`,
        },
        'register',
        DEFAULT_TXN_DISMISS_MS
      )
      history.push('/swap')
    } catch (err) {
      console.error('Register cancelled.', err)
      addPopup(
        {
          text: `Register cancelled. (${err?.message})`,
        },
        'register',
        DEFAULT_TXN_DISMISS_MS
      )
    } finally {
      refetch()
      setLoading(false)
    }
  }

  return pathname !== LEADERBOARD_PATH && pathname !== RULES_PATH && !isUserParticipant ? (
    <BannerContainer>
      <CoinContainer>
        <BannerCoin></BannerCoin>
      </CoinContainer>
      <DescriptionContainer>
        <EventDescription>Competition, win tokens, competition, win tokens, participate now. </EventDescription>
        <PlaceContainer>
          <PlaceCard>
            <Place1></Place1>
            <RewardContainer>
              <RewardTitle>Reward</RewardTitle>
              <RewardDescription>100 tokens</RewardDescription>
            </RewardContainer>
          </PlaceCard>
          <PlaceCard>
            <Place2></Place2>
            <RewardContainer>
              <RewardTitle>Reward</RewardTitle>
              <RewardDescription>60 tokens</RewardDescription>
            </RewardContainer>
          </PlaceCard>
          <PlaceCard>
            <Place3></Place3>
            <RewardContainer>
              <RewardTitle>Reward</RewardTitle>
              <RewardDescription>30 tokens</RewardDescription>
            </RewardContainer>
          </PlaceCard>
        </PlaceContainer>
      </DescriptionContainer>
      <ActionContainer>
        {!!account ? (
          <ButtonLight width="240px" height="48px" padding="4px" onClick={handleRegister} disabled={loading}>
            <Trans>Participate now</Trans>
          </ButtonLight>
        ) : (
          <ButtonLight width="240px" height="48px" padding="4px" onClick={toggleWalletModal}>
            <Trans>Connect Wallet</Trans>
          </ButtonLight>
        )}
        <StyledExternalLink to="/rules">Terms & Conditions</StyledExternalLink>
      </ActionContainer>
    </BannerContainer>
  ) : pathname !== '/leaderboard' && !!account && isUserParticipant ? (
    <CompetitionBanner>
      <CompetitionText>
        Competition, win tokens, competition <CompetitionLink to="/rules">rules</CompetitionLink>, win tokens.
      </CompetitionText>
      <CompetitionRulesLink to="/leaderboard">View Leaderboard</CompetitionRulesLink>
    </CompetitionBanner>
  ) : null
}

export default EventBanner
