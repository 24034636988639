import { Web3Provider } from '@ethersproject/providers'
import * as BufferLayout from '@solana/buffer-layout'
import { MintInfo, MintLayout, u64 } from '@solana/spl-token'
import { AccountInfo as TokenAccountInfo, AccountLayout } from '@solana/spl-token'
import { AccountInfo, PublicKey } from '@solana/web3.js'
import { isSolanaAddress } from 'utils'

export enum PoolOperation {
  Add,
  SwapGivenInput,
  SwapGivenProceeds,
}

export enum CurveType {
  ConstantProduct = 0,
  ConstantPrice = 1,
  Stable = 2,
  ConstantProductWithOffset = 3,
}

export interface PoolConfig {
  curveType: CurveType
  fees: {
    tradeFeeNumerator: number
    tradeFeeDenominator: number
    ownerTradeFeeNumerator: number
    ownerTradeFeeDenominator: number
    ownerWithdrawFeeNumerator: number
    ownerWithdrawFeeDenominator: number
    hostFeeNumerator: number
    hostFeeDenominator: number
  }

  token_b_offset?: number
  token_b_price?: number
}

export interface PoolInfo {
  pubkeys: {
    program: PublicKey
    account: PublicKey
    holdingAccounts: PublicKey[]
    holdingMints: PublicKey[]
    mint: PublicKey
    feeAccount: PublicKey
    lpProvider?: PublicKey
    feeMint: PublicKey
    rewardsAccount: PublicKey
    rewardsMint: PublicKey
  }
  legacy: boolean
  fee?: number
  raw: {
    pubkey: PublicKey
    data: any
    account: AccountInfo<Buffer>
  }
}

export interface TokenAccount {
  pubkey: PublicKey
  account: AccountInfo<Buffer>
  info: TokenAccountInfo
}

/**
 * Layout for a public key
 */
export const publicKey = (property = 'publicKey'): any => {
  return BufferLayout.blob(32, property)
}

/**
 * Layout for a 64bit unsigned value
 */
export const uint64 = (property = 'uint64'): any => {
  return BufferLayout.blob(8, property)
}

export const formatPriceNumber = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 8,
})

export const TokenSwapLayoutV1 = BufferLayout.struct([
  BufferLayout.u8('isInitialized'),
  BufferLayout.u8('nonce'),
  publicKey('tokenProgramId'),
  publicKey('tokenAccountA'),
  publicKey('tokenAccountB'),
  publicKey('tokenPool'),
  publicKey('mintA'),
  publicKey('mintB'),
  publicKey('feeAccount'),
  BufferLayout.u8('curveType'),
  uint64('tradeFeeNumerator'),
  uint64('tradeFeeDenominator'),
  uint64('ownerTradeFeeNumerator'),
  uint64('ownerTradeFeeDenominator'),
  uint64('ownerWithdrawFeeNumerator'),
  uint64('ownerWithdrawFeeDenominator'),
  BufferLayout.blob(16, 'padding'),
])

export const convertAmount = (amount: string, mint?: MintInfo) => {
  return parseFloat(amount) * Math.pow(10, mint?.decimals || 0)
}

export const deserializeMint = (data: Buffer) => {
  if (data.length !== MintLayout.span) {
    throw new Error('Not a valid Mint')
  }

  const mintInfo = MintLayout.decode(data)

  if (mintInfo.mintAuthorityOption === 0) {
    mintInfo.mintAuthority = null
  } else {
    mintInfo.mintAuthority = new PublicKey(mintInfo.mintAuthority)
  }

  mintInfo.supply = u64.fromBuffer(mintInfo.supply)
  mintInfo.isInitialized = mintInfo.isInitialized !== 0

  if (mintInfo.freezeAuthorityOption === 0) {
    mintInfo.freezeAuthority = null
  } else {
    mintInfo.freezeAuthority = new PublicKey(mintInfo.freezeAuthority)
  }

  return mintInfo as MintInfo
}

export const deserializeAccount = (data: Buffer) => {
  const accountInfo = AccountLayout.decode(data)
  accountInfo.mint = new PublicKey(accountInfo.mint)
  accountInfo.owner = new PublicKey(accountInfo.owner)
  accountInfo.amount = u64.fromBuffer(accountInfo.amount)

  if (accountInfo.delegateOption === 0) {
    accountInfo.delegate = null
    accountInfo.delegatedAmount = new u64(0)
  } else {
    accountInfo.delegate = new PublicKey(accountInfo.delegate)
    accountInfo.delegatedAmount = u64.fromBuffer(accountInfo.delegatedAmount)
  }

  accountInfo.isInitialized = accountInfo.state !== 0
  accountInfo.isFrozen = accountInfo.state === 2

  if (accountInfo.isNativeOption === 1) {
    accountInfo.rentExemptReserve = u64.fromBuffer(accountInfo.isNative)
    accountInfo.isNative = true
  } else {
    accountInfo.rentExemptReserve = null
    accountInfo.isNative = false
  }

  if (accountInfo.closeAuthorityOption === 0) {
    accountInfo.closeAuthority = null
  } else {
    accountInfo.closeAuthority = new PublicKey(accountInfo.closeAuthority)
  }

  return accountInfo
}

export const getMintInfo = async (pubKey: string | PublicKey, library: Web3Provider | undefined) => {
  if (!pubKey || !library || (typeof pubKey === 'string' && !isSolanaAddress(pubKey))) {
    return
  }

  let id: PublicKey
  if (typeof pubKey === 'string') {
    id = new PublicKey(pubKey)
  } else {
    id = pubKey
  }

  const info = await library?.send('sol_getAccountInfo', [id])
  if (!info) {
    console.error('Failed to find mint')
    return undefined
  }

  try {
    const data = Buffer.from(info.data)
    return deserializeMint(data)
  } catch (e) {
    return undefined
  }
}

export const getAccountInfo = async (pubKey: string | PublicKey, library: Web3Provider | undefined) => {
  if (!pubKey || !library || (typeof pubKey === 'string' && !isSolanaAddress(pubKey))) {
    return
  }

  let id: PublicKey
  if (typeof pubKey === 'string') {
    id = new PublicKey(pubKey)
  } else {
    id = pubKey
  }

  const info = await library?.send('sol_getAccountInfo', [id])
  if (!info) {
    console.error('Failed to find account')
    return undefined
  }

  try {
    const data = Buffer.from(info.data)
    return deserializeAccount(data)
  } catch {
    return undefined
  }
}

export const toPoolInfo = (item: any, program: PublicKey) => {
  const mint = new PublicKey(item.data.tokenPool)
  return {
    pubkeys: {
      account: item.pubkey,
      program,
      mint,
      holdingMints: [] as PublicKey[],
      holdingAccounts: [item.data.tokenAccountA, item.data.tokenAccountB].map((a) => new PublicKey(a)),
    },
    legacy: false,
    raw: item,
  } as PoolInfo
}

export const tokenAccountFactory = (pubKey: PublicKey, info: AccountInfo<Buffer> | undefined) => {
  if (!info?.data) {
    return null
  }

  const buffer = Buffer.from(info.data)

  const data = deserializeAccount(buffer)

  const details = {
    pubkey: pubKey,
    account: {
      ...info,
    },
    info: data,
  } as TokenAccount

  return details
}

export const tokenMintFactory = (info: AccountInfo<Buffer> | undefined) => {
  if (!info?.data) {
    return null
  }

  const buffer = Buffer.from(info.data)

  const data = deserializeMint(buffer)

  return data
}

export const queryAccountInfo = async (library: Web3Provider | undefined, pubKey: string | PublicKey) => {
  if (!pubKey || !library || (typeof pubKey === 'string' && !isSolanaAddress(pubKey))) {
    return
  }

  let id: PublicKey
  if (typeof pubKey === 'string') {
    id = new PublicKey(pubKey)
  } else {
    id = pubKey
  }

  const query = library?.send('sol_getAccountInfo', [id]) as Promise<AccountInfo<Buffer>>

  return query
}

export const queryMintInfo = async (library: Web3Provider | undefined, pubKey: string | PublicKey) => {
  let id: PublicKey
  if (typeof pubKey === 'string') {
    id = new PublicKey(pubKey)
  } else {
    id = pubKey
  }

  const query = library?.send('sol_getAccountInfo', [id]).then((info: any) => info) as Promise<AccountInfo<Buffer>>

  return query
}

export const queryMultipleAccountsInfo = async (library: Web3Provider | undefined, pubKeys: PublicKey[] | string[]) => {
  try {
    const formattedKeys = pubKeys.map((key) => {
      let id: PublicKey
      if (typeof key === 'string') {
        id = new PublicKey(key)
      } else {
        id = key
      }

      return id
    })

    const accounts = await library?.send('sol_getMultipleAccounts', [formattedKeys])

    return (
      (accounts?.map((account: any, index: number) => tokenAccountFactory(formattedKeys[index], account)) as Promise<
        TokenAccount[]
      >) || []
    )
  } catch (e) {
    return []
  }
}

export const queryMultipleMintInfo = async (library: Web3Provider | undefined, pubKeys: PublicKey[]) => {
  try {
    const formattedKeys = pubKeys.map((key) => {
      let id: PublicKey
      if (typeof key === 'string') {
        id = new PublicKey(key)
      } else {
        id = key
      }

      return id
    })

    const accounts = await library?.send('sol_getMultipleAccounts', [formattedKeys])

    return (
      (accounts?.map((account: any) => {
        const data = Buffer.from(account.data)

        return deserializeMint(data)
      }) as MintInfo[]) || []
    )
  } catch (e) {
    return []
  }
}

export const estimateInputFromProceeds = (
  inputQuantityInPool: number,
  proceedsQuantityInPool: number,
  proceedsAmount: number
): number | string => {
  if (proceedsAmount >= proceedsQuantityInPool) {
    return 'Not possible'
  }

  return (inputQuantityInPool * proceedsAmount) / (proceedsQuantityInPool - proceedsAmount)
}

export const estimateProceedsFromInput = (
  inputQuantityInPool: number,
  proceedsQuantityInPool: number,
  inputAmount: number
): number => {
  return (proceedsQuantityInPool * inputAmount) / (inputQuantityInPool + inputAmount)
}

export async function calculateDependentAmount(
  library: Web3Provider | undefined,
  independent: string,
  amount: number,
  pool: PoolInfo,
  op: PoolOperation,
  cachedAccounts: Map<string, AccountInfo<Buffer>>,
  setCachedAccounts: (nextValue: any) => void
): Promise<number | string | undefined> {
  let poolMint: any = null
  if (cachedAccounts.has(pool.pubkeys.mint.toBase58())) {
    poolMint = tokenMintFactory(cachedAccounts.get(pool.pubkeys.mint.toBase58()))
  } else {
    poolMint = await queryMintInfo(library, new PublicKey(pool.pubkeys.mint))
    setCachedAccounts((prev: any) => new Map(prev).set(pool.pubkeys.mint.toBase58(), poolMint))
    poolMint = tokenMintFactory(poolMint)
  }

  let accountA: any = null
  if (cachedAccounts.has(pool.pubkeys.holdingAccounts[0].toBase58())) {
    accountA = tokenAccountFactory(
      new PublicKey(pool.pubkeys.holdingAccounts[0]),
      cachedAccounts.get(pool.pubkeys.holdingAccounts[0].toBase58())
    )
  } else {
    accountA = await queryAccountInfo(library, new PublicKey(pool.pubkeys.holdingAccounts[0]))
    setCachedAccounts((prev: any) => new Map(prev).set(pool.pubkeys.holdingAccounts[0].toBase58(), accountA))
    accountA = tokenAccountFactory(new PublicKey(pool.pubkeys.holdingAccounts[0]), accountA)
  }

  let accountB: any = null
  if (cachedAccounts.has(pool.pubkeys.holdingAccounts[1].toBase58())) {
    accountB = tokenAccountFactory(
      new PublicKey(pool.pubkeys.holdingAccounts[1]),
      cachedAccounts.get(pool.pubkeys.holdingAccounts[1].toBase58())
    )
  } else {
    accountB = await queryAccountInfo(library, new PublicKey(pool.pubkeys.holdingAccounts[1]))
    setCachedAccounts((prev: any) => new Map(prev).set(pool.pubkeys.holdingAccounts[1].toBase58(), accountB))
    accountB = tokenAccountFactory(new PublicKey(pool.pubkeys.holdingAccounts[1]), accountB)
  }

  const amountA = accountA?.info.amount.toNumber() ?? 0
  let amountB = accountB?.info.amount.toNumber() ?? 0

  if (!poolMint?.mintAuthority) {
    throw new Error('Mint doesnt have authority')
  }

  if (poolMint.supply.eqn(0)) {
    return
  }

  let offsetAmount = 0
  const offsetCurve = pool.raw?.data?.curve?.offset
  if (offsetCurve) {
    offsetAmount = offsetCurve.token_b_offset
    amountB = amountB + offsetAmount
  }

  let mintA: any = null
  if (cachedAccounts.has(accountA?.info.mint.toBase58() || '')) {
    mintA = tokenMintFactory(cachedAccounts.get(accountA?.info.mint.toBase58() || ''))
  } else {
    mintA = await queryMintInfo(library, new PublicKey(accountA?.info.mint || ''))
    setCachedAccounts((prev: any) => new Map(prev).set(accountA?.info.mint.toBase58() || '', mintA))
    mintA = tokenMintFactory(mintA)
  }

  let mintB: any = null
  if (cachedAccounts.has(accountB?.info.mint.toBase58() || '')) {
    mintB = tokenMintFactory(cachedAccounts.get(accountB?.info.mint.toBase58() || ''))
  } else {
    mintB = await queryMintInfo(library, new PublicKey(accountB?.info.mint || ''))
    setCachedAccounts((prev: any) => new Map(prev).set(accountB?.info.mint.toBase58() || '', mintB))
    mintB = tokenMintFactory(mintB)
  }

  if (!mintA || !mintB) {
    return
  }

  const isFirstIndependent = accountA?.info.mint.toBase58() === independent
  const depPrecision = Math.pow(10, isFirstIndependent ? mintB.decimals : mintA.decimals)
  const indPrecision = Math.pow(10, isFirstIndependent ? mintA.decimals : mintB.decimals)
  const indAdjustedAmount = amount * indPrecision

  const indBasketQuantity = isFirstIndependent ? amountA : amountB

  const depBasketQuantity = isFirstIndependent ? amountB : amountA

  let depAdjustedAmount

  const constantPrice = pool.raw?.data?.curve?.constantPrice
  if (constantPrice) {
    depAdjustedAmount = (amount * depPrecision) / constantPrice.token_b_price
  } else {
    switch (+op) {
      case PoolOperation.Add:
        depAdjustedAmount = (depBasketQuantity / indBasketQuantity) * indAdjustedAmount
        break
      case PoolOperation.SwapGivenProceeds:
        depAdjustedAmount = estimateInputFromProceeds(depBasketQuantity, indBasketQuantity, indAdjustedAmount)
        break
      case PoolOperation.SwapGivenInput:
        depAdjustedAmount = estimateProceedsFromInput(indBasketQuantity, depBasketQuantity, indAdjustedAmount)
        break
    }
  }

  if (typeof depAdjustedAmount === 'string') {
    return depAdjustedAmount
  }
  if (depAdjustedAmount === undefined) {
    return undefined
  }
  return depAdjustedAmount / depPrecision
}
