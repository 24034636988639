import { darken } from 'polished'
import styled from 'styled-components/macro'

import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'

export const TextDot = styled.div`
  height: 3px;
  width: 3px;
  background-color: ${({ theme }) => theme.text2};
  border-radius: 50%;
`

export const Checkbox = styled.input`
  border: 1px solid ${({ theme }) => theme.red3};
  height: 20px;
  margin: 0;
`

export const PaddedColumn = styled(AutoColumn)``

export const MenuItem = styled(RowBetween)`
  padding: 4px 20px;
  height: 55px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  background-color: ${({ theme, disabled }) => !disabled && theme.primary7};
  border-radius: 16px;
  :hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.primary7)};
  }
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
`

export const SearchInput = styled.input`
  position: relative;
  display: flex;
  padding: 16px;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.primaryText1};
  -webkit-appearance: none;
  font-size: 16px;
  ::placeholder {
    color: ${({ theme }) => theme.primaryText1};
  }
  :focus {
    outline: none;
  }
`
export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg2};
`

export const SeparatorDark = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`
