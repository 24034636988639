//feature CHAIN
import solanaLogo from 'assets/svg/solana-sol-logo.svg'

import { SupportedChainId, SupportedL1ChainId, SupportedL2ChainId } from './chains'

export enum NetworkType {
  L1,
  L2,
}

interface BaseChainInfo {
  readonly networkType: NetworkType
  readonly blockWaitMsBeforeWarning?: number
  readonly docs: string
  readonly bridge?: string
  readonly explorer: string
  readonly infoLink: string
  readonly logoUrl: string
  readonly label: string
  readonly helpCenterUrl?: string
  readonly nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
}

export interface L1ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L1
}

export interface L2ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L2
  readonly bridge: string
  readonly statusPage?: string
  readonly defaultListUrl: string
}

export type ChainInfoMap = { readonly [chainId: number]: L1ChainInfo | L2ChainInfo } & {
  readonly [chainId in SupportedL2ChainId]: L2ChainInfo
} &
  { readonly [chainId in SupportedL1ChainId]: L1ChainInfo }

export const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.SOLANA_MAINNET]: {
    networkType: NetworkType.L1,
    docs: 'https://docs.solana.com/',
    explorer: 'https://explorer.solana.com/',
    infoLink: 'https://solana.com/',
    label: 'Solana Mainnet',
    logoUrl: solanaLogo,
    nativeCurrency: { name: 'Solana', symbol: 'SOL', decimals: 9 },
  },
  [SupportedChainId.SOLANA_TESTNET]: {
    networkType: NetworkType.L1,
    docs: 'https://docs.solana.com/',
    explorer: 'https://explorer.solana.com/?cluster=testnet',
    infoLink: 'https://solana.com/',
    label: 'Solana Testnet',
    logoUrl: solanaLogo,
    nativeCurrency: { name: 'Solana', symbol: 'SOL', decimals: 9 },
  },
  [SupportedChainId.SOLANA_DEVNET]: {
    networkType: NetworkType.L1,
    docs: 'https://docs.solana.com/',
    explorer: 'https://explorer.solana.com/?cluster=devnet',
    infoLink: 'https://solana.com/',
    label: 'Solana Devnet',
    logoUrl: solanaLogo,
    nativeCurrency: { name: 'Solana', symbol: 'SOL', decimals: 9 },
  },
}
