import { blob, Layout, struct, u8 } from '@solana/buffer-layout'
import type { Signer } from '@solana/web3.js'
import { PublicKey, SYSVAR_RENT_PUBKEY, TransactionInstruction } from '@solana/web3.js'
import { toBigIntLE, toBufferLE } from 'bigint-buffer'
import { Buffer } from 'buffer'

export interface EncodeDecode<T> {
  decode(buffer: Buffer, offset?: number): T
  encode(src: T, buffer: Buffer, offset?: number): number
}

export const encodeDecode = <T>(layout: Layout<T>): EncodeDecode<T> => {
  const decode = layout.decode.bind(layout)
  const encode = layout.encode.bind(layout)
  return { decode, encode }
}

export const bigInt =
  (length: number) =>
  (property?: string): Layout<bigint> => {
    const layout = blob(length, property)
    const { encode, decode } = encodeDecode(layout)

    const bigIntLayout = layout as Layout<unknown> as Layout<bigint>

    bigIntLayout.decode = (buffer: Buffer, offset: number) => {
      const src = decode(buffer, offset)
      return toBigIntLE(Buffer.from(src))
    }

    bigIntLayout.encode = (bigInt: bigint, buffer: Buffer, offset: number) => {
      const src = toBufferLE(bigInt, length)
      return encode(src, buffer, offset)
    }

    return bigIntLayout
  }

export const u64 = bigInt(8)

export enum TokenInstruction {
  InitializeMint = 0,
  InitializeAccount = 1,
  InitializeMultisig = 2,
  Transfer = 3,
  Approve = 4,
  Revoke = 5,
  SetAuthority = 6,
  MintTo = 7,
  Burn = 8,
  CloseAccount = 9,
  FreezeAccount = 10,
  ThawAccount = 11,
  TransferChecked = 12,
  ApproveChecked = 13,
  MintToChecked = 14,
  BurnChecked = 15,
  InitializeAccount2 = 16,
  SyncNative = 17,
  InitializeAccount3 = 18,
  InitializeMultisig2 = 19,
  InitializeMint2 = 20,
  GetAccountDataSize = 21,
  InitializeImmutableOwner = 22,
  AmountToUiAmount = 23,
  UiAmountToAmount = 24,
  InitializeMintCloseAuthority = 25,
  TransferFeeExtension = 26,
  ConfidentialTransferExtension = 27,
  DefaultAccountStateExtension = 28,
  Reallocate = 29,
  MemoTransferExtension = 30,
  CreateNativeMint = 31,
}

export interface InitializeAccountInstructionData {
  instruction: TokenInstruction.InitializeAccount
}

export const initializeAccountInstructionData = struct<InitializeAccountInstructionData>([u8('instruction')])

export interface CloseAccountInstructionData {
  instruction: TokenInstruction.CloseAccount
}

export const closeAccountInstructionData = struct<CloseAccountInstructionData>([u8('instruction')])

export interface ApproveInstructionData {
  instruction: TokenInstruction.Approve
  amount: bigint
}

export const approveInstructionData = struct<ApproveInstructionData>([u8('instruction'), u64('amount')])

export interface RevokeInstructionData {
  instruction: TokenInstruction.Revoke
}

export const revokeInstructionData = struct<RevokeInstructionData>([u8('instruction')])

export const createInitAccountInstruction = (
  programId: PublicKey,
  mint: PublicKey,
  account: PublicKey,
  owner: PublicKey
): TransactionInstruction => {
  const keys = [
    { pubkey: account, isSigner: false, isWritable: true },
    { pubkey: mint, isSigner: false, isWritable: false },
    { pubkey: owner, isSigner: false, isWritable: false },
    { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false },
  ]

  const data = Buffer.alloc(initializeAccountInstructionData.span)
  initializeAccountInstructionData.encode({ instruction: TokenInstruction.InitializeAccount }, data)

  return new TransactionInstruction({
    keys,
    programId,
    data,
  })
}

export const createCloseAccountInstruction = (
  programId: PublicKey,
  account: PublicKey,
  dest: PublicKey,
  owner: PublicKey,
  multiSigners: Array<Signer>
): TransactionInstruction => {
  const data = Buffer.alloc(closeAccountInstructionData.span)
  closeAccountInstructionData.encode({ instruction: TokenInstruction.CloseAccount }, data)

  const keys = [
    { pubkey: account, isSigner: false, isWritable: true },
    { pubkey: dest, isSigner: false, isWritable: true },
  ]
  if (multiSigners.length === 0) {
    keys.push({ pubkey: owner, isSigner: true, isWritable: false })
  } else {
    keys.push({ pubkey: owner, isSigner: false, isWritable: false })
    multiSigners.forEach((signer) =>
      keys.push({
        pubkey: signer.publicKey,
        isSigner: true,
        isWritable: false,
      })
    )
  }

  return new TransactionInstruction({
    keys,
    programId,
    data,
  })
}

export const createApproveInstruction = (
  programId: PublicKey,
  account: PublicKey,
  delegate: PublicKey,
  owner: PublicKey,
  multiSigners: Array<Signer>,
  amount: number | bigint
): TransactionInstruction => {
  const data = Buffer.alloc(approveInstructionData.span)
  approveInstructionData.encode(
    {
      instruction: TokenInstruction.Approve,
      amount: BigInt(amount),
    },
    data
  )

  const keys = [
    { pubkey: account, isSigner: false, isWritable: true },
    { pubkey: delegate, isSigner: false, isWritable: false },
  ]
  if (multiSigners.length === 0) {
    keys.push({ pubkey: owner, isSigner: true, isWritable: false })
  } else {
    keys.push({ pubkey: owner, isSigner: false, isWritable: false })
    multiSigners.forEach((signer) =>
      keys.push({
        pubkey: signer.publicKey,
        isSigner: true,
        isWritable: false,
      })
    )
  }

  return new TransactionInstruction({
    keys,
    programId,
    data,
  })
}

export const createRevokeInstruction = (
  programId: PublicKey,
  account: PublicKey,
  owner: PublicKey,
  multiSigners: Array<Signer>
): TransactionInstruction => {
  const data = Buffer.alloc(revokeInstructionData.span)
  revokeInstructionData.encode({ instruction: TokenInstruction.Revoke }, data)

  const keys = [{ pubkey: account, isSigner: false, isWritable: true }]
  if (multiSigners.length === 0) {
    keys.push({ pubkey: owner, isSigner: true, isWritable: false })
  } else {
    keys.push({ pubkey: owner, isSigner: false, isWritable: false })
    multiSigners.forEach((signer) =>
      keys.push({
        pubkey: signer.publicKey,
        isSigner: true,
        isWritable: false,
      })
    )
  }

  return new TransactionInstruction({
    keys,
    programId,
    data,
  })
}
