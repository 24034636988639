import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react'
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from 'constants/chains'
import { NETWORK_URLS } from 'constants/solana'
import { NETWORK_TYPE, SOLANA_CHAIN_IDS, SOLANA_NETWORKS } from 'constants/solana'
import { InjectedConnector } from 'web3-react-injected-connector'

import { NetworkConnector } from './NetworkConnector'
import { PhantomConnector, SolflareConnector } from './Solana/SolanaConnector'

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: SupportedChainId.SOLANA_MAINNET,
})

export const phantomConnector = new PhantomConnector({
  url: SOLANA_NETWORKS[process.env.REACT_APP_SOLANA_NETWORK as NETWORK_TYPE],
  chainId: SOLANA_CHAIN_IDS[process.env.REACT_APP_SOLANA_NETWORK as NETWORK_TYPE],
  supportedChainIds: [SupportedChainId.SOLANA_DEVNET, SupportedChainId.SOLANA_TESTNET, SupportedChainId.SOLANA_MAINNET],
})

export const solflareConnector = new SolflareConnector({
  url: SOLANA_NETWORKS[process.env.REACT_APP_SOLANA_NETWORK as NETWORK_TYPE],
  chainId: SOLANA_CHAIN_IDS[process.env.REACT_APP_SOLANA_NETWORK as NETWORK_TYPE],
  supportedChainIds: [SupportedChainId.SOLANA_DEVNET, SupportedChainId.SOLANA_TESTNET, SupportedChainId.SOLANA_MAINNET],
})

export const injected = new InjectedConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
})

export const gnosisSafe = new SafeAppConnector()
