import * as anchor from '@project-serum/anchor'
import { BN, Program } from '@project-serum/anchor'
import { AccountLayout, ASSOCIATED_TOKEN_PROGRAM_ID, Token } from '@solana/spl-token'
import { Account, ComputeBudgetProgram, PublicKey, TransactionInstruction } from '@solana/web3.js'
import {
  AIRDROP_MINT,
  CHAINLINK_BTC_ORACLE_ACCOUNT,
  CHAINLINK_PROGRAM_ACCOUNT,
  CHAINLINK_USDC_ORACLE_ACCOUNT,
  DEFAULT_RENT_EXEMPT,
  HYPERSEA_PROGRAM_ID,
  PARTICIPATE_MINT,
  POOL_WITH_AIRDROP,
  PYTH_BTC_ORACLE_ACCOUNT,
  PYTH_USDC_ORACLE_ACCOUNT,
  TOKEN_PROGRAM_ID,
  WRAPPED_SOL_MINT,
} from 'constants/solana'
import { Field } from 'state/burn/actions'
import { IDL } from 'types/hypersea'

import { createSplAccount, getWrappedAccount, sendTransaction } from '../../utils/solana'
import { deserializeAccount, PoolConfig, PoolInfo, TokenAccount } from '../../utils/solana/accounts'

export const programID = new PublicKey('4sN8PnN2ki2W4TFXAfzR645FWs8nimmsYeNtxM8RBK6A')

export interface LiquidityComponent {
  amount: number
  account?: TokenAccount
  mintAddress: string
}

export const addLiquidity = async (
  library: any,
  wallet: any,
  components: LiquidityComponent[],
  account: any,
  slippage: number,
  pool?: PoolInfo,
  options?: PoolConfig,
  depositType = 'both'
) => {
  const instructions: TransactionInstruction[] = []
  const cleanupInstructions: TransactionInstruction[] = []
  const signers: Account[] = []

  const program = new Program(IDL, HYPERSEA_PROGRAM_ID, anchor.getProvider())

  const responseWalletAccounts = await library?.send('sol_getTokenBalances', [account])
  const accountRentExempt = await library?.send('sol_getMinimumBalanceForRentExemption', [AccountLayout.span])
  const accounts =
    responseWalletAccounts?.map((balance: any) => ({
      info: deserializeAccount(balance.account.data),
      pubkey: balance.pubkey,
    })) || []

  let accountA: any = {}
  if (components[0].mintAddress === WRAPPED_SOL_MINT.toBase58() && components[0].amount > 0) {
    accountA.pubkey = getWrappedAccount(
      instructions,
      cleanupInstructions,
      components[0].account,
      wallet?.solanaWallet?.publicKey,
      components[0].amount + accountRentExempt,
      signers
    )
  } else {
    accountA = accounts.find((acc: any) => acc.info.mint.toBase58() === components[0].mintAddress)
  }

  let accountB: any = {}
  if (components[1].mintAddress === WRAPPED_SOL_MINT.toBase58() && components[1].amount > 0) {
    accountB.pubkey = getWrappedAccount(
      instructions,
      cleanupInstructions,
      components[1].account,
      wallet?.solanaWallet?.publicKey,
      components[1].amount + accountRentExempt,
      signers
    )
  } else {
    accountB = accounts.find((acc: any) => acc.info.mint.toBase58() === components[1].mintAddress)
  }

  let accountLp = accounts.find((acc: any) => acc.info.mint.toBase58() === pool?.pubkeys.mint.toBase58())?.pubkey

  const revert = pool?.pubkeys.holdingMints.findIndex((mint) => mint.toBase58() === components[0].mintAddress)

  const [authority] = await anchor.web3.PublicKey.findProgramAddress(
    [pool?.pubkeys.account.toBuffer() ?? Buffer.from([])],
    HYPERSEA_PROGRAM_ID
  )

  if (!accountLp) {
    const lpAccount = createSplAccount(
      instructions,
      wallet?.solanaWallet?.publicKey,
      DEFAULT_RENT_EXEMPT,
      pool?.pubkeys.mint ?? new PublicKey(0),
      wallet?.solanaWallet?.publicKey,
      AccountLayout.span
    )

    accountLp = lpAccount.publicKey

    signers.push(lpAccount)
  }

  if (instructions.length > 0) {
    await sendTransaction(library, wallet, instructions, signers)
  }

  const participateToken = accounts.find((acc: any) => acc.info.mint.toBase58() === PARTICIPATE_MINT.toBase58())?.pubkey

  const addLiq = await program.methods
    .depositAllTokens(new BN(components[0].amount), new BN(components[1].amount))
    .accounts({
      vaultA: pool?.pubkeys.holdingAccounts[revert ? 1 : 0],
      vaultB: pool?.pubkeys.holdingAccounts[revert ? 0 : 1],
      participateToken,
      tokenA: accountA.pubkey,
      tokenB: accountB.pubkey,
      tokenAMint: components[0].mintAddress,
      tokenBMint: components[1].mintAddress,
      poolMint: pool?.pubkeys.mint,
      poolState: pool?.pubkeys.account,
      destination: accountLp,
      authority,
      userAuthority: wallet?.solanaWallet?.publicKey,
      tokenProgram: TOKEN_PROGRAM_ID,
    })
    .instruction()

  await sendTransaction(library, wallet, [addLiq].concat(cleanupInstructions.reverse()), [])
}

export const eventAirdrop = async (library: any, wallet: any, account: any) => {
  const instructions: TransactionInstruction[] = []
  const cleanupInstructions: TransactionInstruction[] = []
  const signers: Account[] = []

  const program = new Program(IDL, new PublicKey(HYPERSEA_PROGRAM_ID), anchor.getProvider())

  const accountRentExempt = await library?.send('sol_getMinimumBalanceForRentExemption', [AccountLayout.span])
  const responseWalletAccounts = await library?.send('sol_getTokenBalances', [account])
  const accounts =
    responseWalletAccounts?.map((balance: any) => ({
      info: deserializeAccount(balance.account.data),
      pubkey: balance.pubkey,
    })) || []

  let tokenAccount = accounts.find((acc: any) => acc.info.mint.toBase58() === AIRDROP_MINT.toBase58())?.pubkey

  if (!tokenAccount) {
    const account = createSplAccount(
      instructions,
      wallet?.solanaWallet?.publicKey,
      accountRentExempt,
      AIRDROP_MINT,
      wallet?.solanaWallet?.publicKey,
      AccountLayout.span
    )

    tokenAccount = account.publicKey

    signers.push(account)
  }

  let participateToken = accounts.find((acc: any) => acc.info.mint.toBase58() === PARTICIPATE_MINT.toBase58())?.pubkey

  if (!participateToken) {
    const account = createSplAccount(
      instructions,
      wallet?.solanaWallet?.publicKey,
      accountRentExempt,
      PARTICIPATE_MINT,
      wallet?.solanaWallet?.publicKey,
      AccountLayout.span
    )

    participateToken = account.publicKey

    signers.push(account)
  } else {
    return
  }

  if (!tokenAccount && !participateToken) {
    throw Error('Not have address in the wallet')
  }

  const [authority] = await anchor.web3.PublicKey.findProgramAddress(
    [POOL_WITH_AIRDROP.toBuffer() ?? Buffer.from([])],
    HYPERSEA_PROGRAM_ID
  )

  const additionalComputeBudgetInstruction = ComputeBudgetProgram.requestUnits({
    units: 1500000,
    additionalFee: 0,
  })
  instructions.push(additionalComputeBudgetInstruction)

  const swapInstruction = await program.methods
    .airdrop()
    .accounts({
      token: tokenAccount,
      tokenMint: AIRDROP_MINT,
      participateToken,
      participateTokenMint: PARTICIPATE_MINT,
      poolState: POOL_WITH_AIRDROP,
      authority,
      tokenProgram: TOKEN_PROGRAM_ID,
    })
    .instruction()

  instructions.push(swapInstruction)

  await sendTransaction(library, wallet, [...instructions].concat(cleanupInstructions.reverse()), signers)
}

export const removeLiquidity = async (library: any, wallet: any, parsedAmounts: any, account: any, pool?: PoolInfo) => {
  const instructions: TransactionInstruction[] = []
  const cleanupInstructions: TransactionInstruction[] = []
  const signers: Account[] = []

  const program = new Program(IDL, HYPERSEA_PROGRAM_ID, anchor.getProvider())

  const responseWalletAccounts = await library?.send('sol_getTokenBalances', [account])
  const accountRentExempt = await library?.send('sol_getMinimumBalanceForRentExemption', [AccountLayout.span])
  const accounts =
    responseWalletAccounts?.map((balance: any) => ({
      info: deserializeAccount(balance.account.data),
      pubkey: balance.pubkey,
    })) || []

  let accountA: any = {}
  if (pool?.pubkeys.holdingMints[0].toBase58() === WRAPPED_SOL_MINT.toBase58()) {
    accountA.pubkey = getWrappedAccount(
      instructions,
      cleanupInstructions,
      accounts.find((acc: any) => acc.info.mint.toBase58() === pool?.pubkeys.holdingMints[0].toBase58()),
      wallet?.solanaWallet?.publicKey,
      Number(parsedAmounts[Field.CURRENCY_A]?.quotient.toString()) + accountRentExempt,
      signers
    )
  } else {
    accountA = accounts.find((acc: any) => acc.info.mint.toBase58() === pool?.pubkeys.holdingMints[0].toBase58())
  }

  let accountB: any = {}
  if (pool?.pubkeys.holdingMints[1].toBase58() === WRAPPED_SOL_MINT.toBase58()) {
    accountB.pubkey = getWrappedAccount(
      instructions,
      cleanupInstructions,
      accounts.find((acc: any) => acc.info.mint.toBase58() === pool?.pubkeys.holdingMints[1].toBase58()),
      wallet?.solanaWallet?.publicKey,
      Number(parsedAmounts[Field.CURRENCY_B]?.quotient.toString()) + accountRentExempt,
      signers
    )
  } else {
    accountB = accounts.find((acc: any) => acc.info.mint.toBase58() === pool?.pubkeys.holdingMints[1].toBase58())
  }

  const accountLp = accounts.find((acc: any) => acc.info.mint.toBase58() === pool?.pubkeys.mint.toBase58())?.pubkey

  const [authority] = await anchor.web3.PublicKey.findProgramAddress(
    [pool?.pubkeys.account.toBuffer() ?? Buffer.from([])],
    HYPERSEA_PROGRAM_ID
  )

  if (!accountLp) {
    throw Error('No liq account')
  }

  if (instructions.length > 0) {
    await sendTransaction(library, wallet, instructions, signers)
  }

  const participateToken = accounts.find((acc: any) => acc.info.mint.toBase58() === PARTICIPATE_MINT.toBase58())?.pubkey

  let rewardToken = accounts.find(
    (acc: any) => acc.info.mint.toBase58() === pool?.pubkeys.rewardsMint.toBase58()
  )?.pubkey

  if (!rewardToken) {
    const rewardAccount = createSplAccount(
      instructions,
      wallet?.solanaWallet?.publicKey,
      DEFAULT_RENT_EXEMPT,
      pool?.pubkeys.rewardsMint ?? new PublicKey(0),
      wallet?.solanaWallet?.publicKey,
      AccountLayout.span
    )

    rewardToken = rewardAccount.publicKey

    signers.push(rewardAccount)
  }

  if (instructions.length > 0) {
    await sendTransaction(library, wallet, instructions, signers)
  }

  const removeLiq = await program.methods
    .withdrawAllTokens(new BN(parsedAmounts[Field.LIQUIDITY]?.quotient.toString()), new BN(0), new BN(0))
    .accounts({
      vaultA: pool?.pubkeys.holdingAccounts[0],
      vaultB: pool?.pubkeys.holdingAccounts[1],
      participateToken,
      tokenA: accountA.pubkey,
      tokenAMint: pool?.pubkeys.holdingMints[0],
      tokenB: accountB.pubkey,
      tokenBMint: pool?.pubkeys.holdingMints[1],
      poolMint: pool?.pubkeys.mint,
      poolState: pool?.pubkeys.account,
      poolRewardMint: pool?.pubkeys.rewardsMint,
      poolRewards: pool?.pubkeys.rewardsAccount,
      rewardToken,
      source: accountLp,
      authority,
      userAuthority: wallet?.solanaWallet?.publicKey,
      tokenProgram: TOKEN_PROGRAM_ID,
    })
    .instruction()

  await sendTransaction(library, wallet, [removeLiq].concat(cleanupInstructions.reverse()), [])
}

export const swap = async (
  library: any,
  wallet: any,
  components: LiquidityComponent[],
  SLIPPAGE: number,
  account: any,
  pool?: PoolInfo
) => {
  if ((!components[0].amount && !components[1].amount) || !wallet?.solanaWallet?.publicKey) {
    return
  }

  const amountIn = components[0].amount
  const minAmountOut = components[1].amount * (1 - SLIPPAGE)

  const instructions: TransactionInstruction[] = []
  const cleanupInstructions: TransactionInstruction[] = []
  const signers: Account[] = []

  const program = new Program(IDL, new PublicKey(HYPERSEA_PROGRAM_ID), anchor.getProvider())

  const accountRentExempt = await library?.send('sol_getMinimumBalanceForRentExemption', [AccountLayout.span])
  const responseWalletAccounts = await library?.send('sol_getTokenBalances', [account])
  const accounts =
    responseWalletAccounts?.map((balance: any) => ({
      info: deserializeAccount(balance.account.data),
      pubkey: balance.pubkey,
    })) || []

  let sourceAccount: any = {}
  if (components[0].amount > 0) {
    sourceAccount = accounts.find((acc: any) => acc.info.mint.toBase58() === components[0].mintAddress) ?? {}

    if (!sourceAccount?.pubkey && components[0].mintAddress !== WRAPPED_SOL_MINT.toBase58()) {
      const newAccount = createSplAccount(
        instructions,
        wallet?.solanaWallet?.publicKey,
        accountRentExempt,
        new PublicKey(components[0].mintAddress),
        wallet?.solanaWallet?.publicKey,
        AccountLayout.span
      )

      signers.push(newAccount)

      sourceAccount.pubkey = newAccount?.publicKey
    } else if (!sourceAccount?.pubkey && components[0].mintAddress === WRAPPED_SOL_MINT.toBase58()) {
      sourceAccount.pubkey = getWrappedAccount(
        instructions,
        cleanupInstructions,
        components[0].account,
        wallet?.solanaWallet?.publicKey,
        components[0].amount + accountRentExempt,
        signers
      )
    }
  }

  let destinationAccount: any = {}
  if (components[1].amount > 0) {
    destinationAccount = accounts.find((acc: any) => acc.info.mint.toBase58() === components[1].mintAddress) ?? {}

    if (!destinationAccount?.pubkey && components[1].mintAddress !== WRAPPED_SOL_MINT.toBase58()) {
      const newAccount = createSplAccount(
        instructions,
        wallet?.solanaWallet?.publicKey,
        accountRentExempt,
        new PublicKey(components[1].mintAddress),
        wallet?.solanaWallet?.publicKey,
        AccountLayout.span
      )

      signers.push(newAccount)

      destinationAccount.pubkey = newAccount?.publicKey
    } else if (!destinationAccount?.pubkey && components[1].mintAddress === WRAPPED_SOL_MINT.toBase58()) {
      destinationAccount.pubkey = getWrappedAccount(
        instructions,
        cleanupInstructions,
        components[1].account,
        wallet?.solanaWallet?.publicKey,
        components[1].amount + accountRentExempt,
        signers
      )
    }
  }

  if (!sourceAccount && !destinationAccount) {
    throw Error('Not have address in the wallet')
  }

  const [authority] = await anchor.web3.PublicKey.findProgramAddress(
    [pool?.pubkeys.account.toBuffer() ?? Buffer.from([])],
    HYPERSEA_PROGRAM_ID
  )

  const additionalComputeBudgetInstruction = ComputeBudgetProgram.requestUnits({
    units: 1500000,
    additionalFee: 0,
  })
  instructions.push(additionalComputeBudgetInstruction)

  const participateToken = accounts.find((acc: any) => acc.info.mint.toBase58() === PARTICIPATE_MINT.toBase58())?.pubkey

  const swapInstruction = await program.methods
    .swap(new BN(amountIn), new BN(minAmountOut))
    .accounts({
      poolMint: pool?.pubkeys.mint,
      poolFees: pool?.pubkeys.feeAccount,
      poolState: pool?.pubkeys.account,
      vaultA: pool?.pubkeys.holdingAccounts[0],
      vaultB: pool?.pubkeys.holdingAccounts[1],
      participateToken,
      poolFeeMint: pool?.pubkeys.feeMint,
      poolRewardMint: pool?.pubkeys.rewardsMint,
      poolRewards: pool?.pubkeys.rewardsAccount,
      oracles: {
        pyth: {
          tokenA: PYTH_BTC_ORACLE_ACCOUNT,
          tokenB: PYTH_USDC_ORACLE_ACCOUNT,
        },
        chainlink: {
          tokenA: CHAINLINK_BTC_ORACLE_ACCOUNT,
          tokenB: CHAINLINK_USDC_ORACLE_ACCOUNT,
        },
        chainlinkProgram: CHAINLINK_PROGRAM_ACCOUNT,
      },
      tokenInput: sourceAccount.pubkey,
      tokenInputMint: pool?.pubkeys.holdingMints[0],
      tokenOutput: destinationAccount.pubkey,
      tokenOutputMint: pool?.pubkeys.holdingMints[1],
      authority,
      userAuthority: wallet?.solanaWallet?.publicKey,
      tokenProgram: TOKEN_PROGRAM_ID,
    })
    .instruction()

  instructions.push(swapInstruction)

  await sendTransaction(library, wallet, [...instructions].concat(cleanupInstructions.reverse()), signers)
}

export const splAirdrop = async (mint: string, wallet: any, library: any, amount: any = 10, decimals: any = 9) => {
  const instructions = []
  const amountToAirdrop = amount * Math.pow(10, decimals)

  const authAcc = new Account([
    17, 223, 211, 149, 64, 26, 254, 238, 197, 73, 160, 163, 199, 196, 218, 83, 7, 54, 17, 24, 225, 48, 205, 89, 114,
    207, 212, 120, 18, 56, 117, 26, 226, 162, 93, 67, 215, 211, 229, 166, 192, 136, 79, 186, 214, 185, 244, 54, 196,
    211, 38, 239, 141, 26, 254, 184, 236, 205, 229, 27, 116, 58, 21, 191,
  ])

  try {
    const associatedTokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      new PublicKey(mint),
      wallet?.solanaWallet?.publicKey,
      true
    )

    const info = await library?.send('sol_getAccountInfo', [associatedTokenAccount])

    if (info === null) {
      const cr = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        new PublicKey(mint),
        associatedTokenAccount,
        wallet?.solanaWallet?.publicKey,
        wallet?.solanaWallet?.publicKey
      )

      instructions.push(cr)
    }

    const mt = Token.createMintToInstruction(
      TOKEN_PROGRAM_ID,
      new PublicKey(mint),
      associatedTokenAccount,
      authAcc.publicKey,
      [],
      amountToAirdrop
    )

    instructions.push(mt)

    await sendTransaction(library, wallet, instructions, [authAcc])
  } catch (err) {
    console.error(err)
  }
}
