import { createReducer } from '@reduxjs/toolkit'
import { DEFAULT_LIST_OF_POOLS } from 'constants/pools'

import { updateErrorState, updateList, updateLoadingState } from './actions'

export interface PoolsList {
  legacy: boolean
  fee: number
  pubkeys: {
    program: string
    account: string
    holdingAccounts: [string, string]
    holdingMints: [string, string]
    mint: string
    feeAccount: string
  }
}

export interface PoolsState {
  readonly byUrl: {
    readonly [url: string]: {
      readonly current: PoolsList | null
      readonly loading: boolean | null
      readonly error: string | null
    }
  }
}

type Mutable<T> = { -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U> ? U[] : T[P] }

const NEW_POOL_STATE = {
  error: null,
  current: null,
  loading: null,
}

const initialState = {
  byUrl: {
    ...DEFAULT_LIST_OF_POOLS.reduce<Mutable<PoolsState['byUrl']>>((memo, url) => {
      memo[url] = NEW_POOL_STATE

      return memo
    }, {}),
  },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateList, (state, { payload: { poolList, url } }) => {
      state.byUrl[url] = {
        ...state.byUrl[url],
        current: poolList,
      }
    })
    .addCase(updateLoadingState, (state, { payload: { loading, url } }) => {
      state.byUrl[url] = {
        ...state.byUrl[url],
        loading,
      }
    })
    .addCase(updateErrorState, (state, { payload: { error, url } }) => {
      state.byUrl[url] = {
        ...state.byUrl[url],
        error,
      }
    })
)
