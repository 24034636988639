import { Connector } from '@web3-react/types'
import { AbstractConnector } from 'web3-react-abstract-connector'

import PhantomWalletIcon from '../../assets/svg/phantom.svg'
import SolflareWalletIcon from '../../assets/svg/solflare.svg'
import { injected, phantomConnector, solflareConnector } from '../../connectors'
import Identicon from '../Identicon'

export default function StatusIcon({ connector }: { connector: AbstractConnector | Connector }) {
  switch (connector) {
    case injected:
      return <Identicon />
    case phantomConnector:
      return <img src={PhantomWalletIcon} alt={'Phantom Wallet'} />
    case solflareConnector:
      return <img src={SolflareWalletIcon} alt={'Solflare Wallet'} />
    default:
      return null
  }
}
