import { Trans } from '@lingui/macro'
import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useAllTokens } from 'hooks/Tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

import { useTotalSupply } from '../../hooks/useTotalSupply'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ThemedText } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/unwrappedToken'
import { ButtonPrimary } from '../Button'
import { GreyCard, LightCard } from '../Card'
import { AutoColumn } from '../Column'
import DoubleCurrencyLogo from '../DoubleLogo'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

const StyledPositionCard = styled(LightCard)<{ bgColor: any }>`
  border: none;
  background-color: ${({ theme }) => theme.bg8};
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.primary8};
  position: relative;
  overflow: hidden;
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 42px;
  padding: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
  stakedBalance?: CurrencyAmount<Token> // optional balance to indicate that liquidity is deposited in mining pool
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.quotient, JSBI.BigInt(0)) ? (
        <GreyCard border={border}>
          <AutoColumn gap="12px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={500} fontSize={16}>
                  <Trans>Your position</Trans>
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow onClick={() => setShowMore(!showMore)}>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
                <Text fontWeight={500} fontSize={20}>
                  {currency0.symbol}/{currency1.symbol}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontWeight={500} fontSize={20}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  <Trans>Your pool share:</Trans>
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  {currency0.symbol}:
                </Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  {currency1.symbol}:
                </Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
          </AutoColumn>
        </GreyCard>
      ) : (
        <LightCard>
          <ThemedText.SubHeader style={{ textAlign: 'center' }}>
            <span role="img" aria-label="wizard-icon">
              ⭐️
            </span>{' '}
            <Trans>
              By adding liquidity you&apos;ll earn 0.3% of all trades on this pair proportional to your share of the
              pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
            </Trans>{' '}
          </ThemedText.SubHeader>
        </LightCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, border, liqInfo }: any) {
  const tokens = useAllTokens()
  const native: any = useNativeCurrency()

  const allTokens: any = {
    ...tokens,
    ...(native?.wrapped?.tokenInfo?.address
      ? {
          [native.wrapped.tokenInfo.address]: native.wrapped,
        }
      : null),
  }

  const currency0 = allTokens[pair?.pubkeys?.holdingMints[0]?.toBase58() || '']
  const currency1 = allTokens[pair?.pubkeys?.holdingMints[1]?.toBase58() || '']

  return (
    <StyledPositionCard border="#40444F" bgColor="#231B43">
      <AutoColumn gap="12px">
        <FixedHeightRow>
          <AutoRow gap="8px" style={{ marginLeft: '8px' }}>
            <DoubleCurrencyLogo currency0={currency1} currency1={currency0} size={20} />
            <Text fontWeight={500} fontSize={20}>
              {!currency0 || !currency1 ? (
                <Dots>
                  <Trans>Loading</Trans>
                </Dots>
              ) : (
                `${currency0.symbol}/${currency1.symbol}`
              )}
            </Text>
          </AutoRow>
        </FixedHeightRow>

        <AutoColumn gap="8px">
          <FixedHeightRow>
            <Text fontSize={16} fontWeight={500}>
              <Trans>Your total pool tokens:</Trans>
            </Text>
            <Text fontSize={16} fontWeight={500}>
              {liqInfo?.amount ? (liqInfo.amount.toNumber() / Math.pow(10, liqInfo.poolMintDecimals)).toFixed(4) : '-'}
            </Text>
          </FixedHeightRow>
          <FixedHeightRow>
            <Text fontSize={16} fontWeight={500}>
              <Trans>Your total {currency0?.tokenInfo?.name ?? ''} tokens:</Trans>
            </Text>
            <Text fontSize={16} fontWeight={500}>
              {liqInfo?.tokenAmountA
                ? (liqInfo.tokenAmountA.toNumber() / Math.pow(10, liqInfo.mintADecimals)).toFixed(4)
                : '-'}
            </Text>
          </FixedHeightRow>
          <FixedHeightRow marginBottom="16px">
            <Text fontSize={16} fontWeight={500}>
              <Trans>Your total {currency1?.tokenInfo?.name ?? ''} tokens:</Trans>
            </Text>
            <Text fontSize={16} fontWeight={500}>
              {liqInfo?.tokenAmountB
                ? (liqInfo.tokenAmountB.toNumber() / Math.pow(10, liqInfo.mintBDecimals)).toFixed(4)
                : '-'}
            </Text>
          </FixedHeightRow>
          <FixedHeightRow>
            <Text fontSize={16} fontWeight={500}>
              <Trans>Total pool tokens:</Trans>
            </Text>
            <Text fontSize={16} fontWeight={500}>
              {liqInfo?.totalSupply
                ? (liqInfo.totalSupply.toNumber() / Math.pow(10, liqInfo.poolMintDecimals)).toFixed(4)
                : '-'}
            </Text>
          </FixedHeightRow>
          <FixedHeightRow>
            <Text fontSize={16} fontWeight={500}>
              <Trans>Total {currency0?.tokenInfo?.name ?? ''} tokens:</Trans>
            </Text>
            <Text fontSize={16} fontWeight={500}>
              {liqInfo?.totalValueA
                ? (liqInfo.totalValueA.toNumber() / Math.pow(10, liqInfo.mintADecimals)).toFixed(4)
                : '-'}
            </Text>
          </FixedHeightRow>
          <FixedHeightRow>
            <Text fontSize={16} fontWeight={500}>
              <Trans>Total {currency1?.tokenInfo?.name ?? ''} tokens:</Trans>
            </Text>
            <Text fontSize={16} fontWeight={500}>
              {liqInfo?.totalValueB
                ? (liqInfo.totalValueB.toNumber() / Math.pow(10, liqInfo.mintBDecimals)).toFixed(4)
                : '-'}
            </Text>
          </FixedHeightRow>
          <RowBetween marginTop="10px">
            <ResponsiveButtonPrimary
              padding="8px"
              $borderRadius="8px"
              as={Link}
              to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
              width="32%"
            >
              <Trans>Add</Trans>
            </ResponsiveButtonPrimary>
            <ResponsiveButtonPrimary
              padding="8px"
              $borderRadius="8px"
              as={Link}
              width="32%"
              to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
            >
              <Trans>Remove</Trans>
            </ResponsiveButtonPrimary>
          </RowBetween>
        </AutoColumn>
      </AutoColumn>
    </StyledPositionCard>
  )
}
