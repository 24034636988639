import * as anchor from '@project-serum/anchor'
import { BN, Program } from '@project-serum/anchor'
import * as borsh from '@project-serum/borsh'
import { ComputeBudgetProgram, Transaction } from '@solana/web3.js'
import * as base64 from 'base64-js'
import {
  AUTH_ACCOUNT,
  CHAINLINK_BTC_ORACLE_ACCOUNT,
  CHAINLINK_PROGRAM_ACCOUNT,
  CHAINLINK_USDC_ORACLE_ACCOUNT,
  DEFAULT_DENOMINATOR,
  HYPERSEA_PROGRAM_ID,
  PYTH_BTC_ORACLE_ACCOUNT,
  PYTH_USDC_ORACLE_ACCOUNT,
} from 'constants/solana'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useContext, useEffect, useState } from 'react'
import { IDL } from 'types/hypersea'
import { CurveType, PoolConfig, PoolOperation } from 'utils/solana/accounts'

import { useCurrencyLeg } from './useCurrencyLeg'
import { PoolsContext, RequestState, usePoolForBasket } from './usePools'

export const useSolanaCurrencyPair = ({ shouldUseAddLiqRatio = false }: { shouldUseAddLiqRatio?: boolean } = {}) => {
  const { library } = useActiveWeb3React() as any

  const [lastTypedAccount, setLastTypedAccount] = useState('')
  const [price, setPrice] = useState(new BN(0))
  const [theBestPrice, setTheBestPrice] = useState(new BN(0))
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [poolOperation, setPoolOperation] = useState<PoolOperation>(PoolOperation.Add)

  const [options, setOptions] = useState<PoolConfig>({
    curveType: CurveType.ConstantProduct,
    fees: {
      tradeFeeNumerator: 25,
      tradeFeeDenominator: DEFAULT_DENOMINATOR,
      ownerTradeFeeNumerator: 5,
      ownerTradeFeeDenominator: DEFAULT_DENOMINATOR,
      ownerWithdrawFeeNumerator: 0,
      ownerWithdrawFeeDenominator: 0,
      hostFeeNumerator: 20,
      hostFeeDenominator: 100,
    },
  })

  const base = useCurrencyLeg(options, library)
  const mintAddressA = base.mintAddress
  const setMintAddressA = base.setMint
  const amountA = base.amount
  const setAmountA = base.setAmount
  const setTheBestAmountA = base.setTheBestAmount

  const quote = useCurrencyLeg(options, library)
  const mintAddressB = quote.mintAddress
  const setMintAddressB = quote.setMint
  const amountB = quote.amount
  const setAmountB = quote.setAmount
  const setTheBestAmountB = quote.setTheBestAmount

  const pool = usePoolForBasket([base.mintAddress, quote.mintAddress], library)
  const { requestsState, cachedAccounts } = useContext(PoolsContext)

  useEffect(() => {
    const getPrice = async () => {
      try {
        const program = new Program(IDL, HYPERSEA_PROGRAM_ID, anchor.getProvider())

        let revert = 0
        let amount = ''
        let decimals = undefined
        if (lastTypedAccount === mintAddressA) {
          amount = amountA
          decimals = base?.mint?.decimals
          revert = 0
        } else {
          amount = amountB
          decimals = quote?.mint?.decimals
          revert = 1
        }

        const additionalComputeBudgetInstruction = ComputeBudgetProgram.requestUnits({
          units: 1500000,
          additionalFee: 0,
        })

        const priceInstruction = await program.methods
          .viewSwapPrice(new BN(Number(amount) * Math.pow(10, Number(decimals ?? 0))), revert ?? 0)
          .accounts({
            vaultA: pool?.pubkeys.holdingAccounts[0],
            vaultB: pool?.pubkeys.holdingAccounts[1],
            poolState: pool?.pubkeys.account,
            oracles: {
              pyth: {
                tokenA: PYTH_BTC_ORACLE_ACCOUNT,
                tokenB: PYTH_USDC_ORACLE_ACCOUNT,
              },
              chainlink: {
                tokenA: CHAINLINK_BTC_ORACLE_ACCOUNT,
                tokenB: CHAINLINK_USDC_ORACLE_ACCOUNT,
              },
              chainlinkProgram: CHAINLINK_PROGRAM_ACCOUNT,
            },
          })
          .instruction()

        const transaction = new Transaction().add(priceInstruction, additionalComputeBudgetInstruction)
        transaction.feePayer = AUTH_ACCOUNT.publicKey
        transaction.recentBlockhash = (await library?.send('sol_getRecentBlockhash', ['max'])).blockhash

        const txResponse = await library?.send('sol_simulateTransaction', [transaction])
        const returnPrefix = `Program return: ${HYPERSEA_PROGRAM_ID} `
        const returnLog = txResponse?.value?.logs?.find?.((l: string) => l.startsWith(returnPrefix))
        const returnData = Buffer.from(base64.toByteArray(returnLog.slice(returnPrefix.length)))

        const price = borsh.u64('price').decode(returnData)

        setPrice(price)
      } catch (e) {
        setPrice(new BN(0))
      }
    }

    if (pool?.pubkeys.holdingAccounts[0] && pool?.pubkeys.holdingAccounts[1] && (amountA || amountB)) {
      getPrice()
    }
  }, [
    amountA,
    amountB,
    base?.mint?.decimals,
    lastTypedAccount,
    library,
    mintAddressA,
    pool?.pubkeys.account,
    pool?.pubkeys.holdingAccounts,
    pool?.pubkeys.holdingMints,
    quote?.mint?.decimals,
  ])

  useEffect(() => {
    const getPrice = async () => {
      try {
        const program = new Program(IDL, HYPERSEA_PROGRAM_ID, anchor.getProvider())

        let revert = 0
        if (lastTypedAccount === mintAddressA) {
          revert = 0
        } else {
          revert = 1
        }

        const additionalComputeBudgetInstruction = ComputeBudgetProgram.requestUnits({
          units: 1500000,
          additionalFee: 0,
        })

        const priceInstruction = await program.methods
          .viewSwapPrice(new BN(1000), revert ?? 0)
          .accounts({
            vaultA: pool?.pubkeys.holdingAccounts[0],
            vaultB: pool?.pubkeys.holdingAccounts[1],
            poolState: pool?.pubkeys.account,
            oracles: {
              pyth: {
                tokenA: PYTH_BTC_ORACLE_ACCOUNT,
                tokenB: PYTH_USDC_ORACLE_ACCOUNT,
              },
              chainlink: {
                tokenA: CHAINLINK_BTC_ORACLE_ACCOUNT,
                tokenB: CHAINLINK_USDC_ORACLE_ACCOUNT,
              },
              chainlinkProgram: CHAINLINK_PROGRAM_ACCOUNT,
            },
          })
          .instruction()

        const transaction = new Transaction().add(priceInstruction, additionalComputeBudgetInstruction)
        transaction.feePayer = AUTH_ACCOUNT.publicKey
        transaction.recentBlockhash = (await library?.send('sol_getRecentBlockhash', ['max'])).blockhash

        const txResponse = await library?.send('sol_simulateTransaction', [transaction])
        const returnPrefix = `Program return: ${HYPERSEA_PROGRAM_ID} `
        const returnLog = txResponse?.value?.logs?.find?.((l: string) => l.startsWith(returnPrefix))
        const returnData = Buffer.from(base64.toByteArray(returnLog.slice(returnPrefix.length)))

        const price = borsh.u64('price').decode(returnData)

        setTheBestPrice(price)
      } catch (e) {
        setTheBestPrice(new BN(0))
      }
    }

    if (pool?.pubkeys.holdingAccounts[0] && pool?.pubkeys.holdingAccounts[1] && !shouldUseAddLiqRatio) {
      getPrice()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pool?.pubkeys.holdingAccounts[0], pool?.pubkeys.holdingAccounts[1], lastTypedAccount, library, mintAddressA])

  const calculateDependent = useCallback(async () => {
    if (pool && mintAddressA && mintAddressB) {
      let setDependent
      let calculatedPrice
      if (lastTypedAccount === mintAddressA) {
        setDependent = setAmountB
        calculatedPrice = price.toNumber() / Math.pow(10, base?.mint?.decimals ?? 1)
        calculatedPrice = calculatedPrice === Infinity ? 0 : calculatedPrice
      } else {
        setDependent = setAmountA
        calculatedPrice = price.toNumber() / Math.pow(10, quote?.mint?.decimals ?? 1)
        calculatedPrice = calculatedPrice === Infinity ? 0 : calculatedPrice
      }

      if (isNaN(calculatedPrice) || !calculatedPrice) {
        setDependent('')

        return
      }

      setDependent(calculatedPrice.toFixed(6))
    }
  }, [
    pool,
    mintAddressA,
    mintAddressB,
    lastTypedAccount,
    price,
    base?.mint?.decimals,
    setAmountB,
    quote?.mint?.decimals,
    setAmountA,
  ])

  const calculateTheBestDependent = useCallback(async () => {
    if (pool && mintAddressA && mintAddressB) {
      let setDependent
      let amount
      let calculatedPrice
      if (lastTypedAccount === mintAddressA) {
        setDependent = setTheBestAmountB
        setTheBestAmountA('')
        amount = parseFloat(amountA)
        calculatedPrice = (theBestPrice.toNumber() / Math.pow(10, 3)) * amount
        calculatedPrice = calculatedPrice === Infinity ? 0 : calculatedPrice
      } else {
        setDependent = setTheBestAmountA
        setTheBestAmountB('')
        amount = parseFloat(amountB)
        calculatedPrice = amount / (theBestPrice.toNumber() / Math.pow(10, 3))
        calculatedPrice = calculatedPrice === Infinity ? 0 : calculatedPrice
      }

      if (isNaN(calculatedPrice) || !calculatedPrice) {
        setDependent('')

        return
      }

      setDependent(calculatedPrice.toFixed(6))
    }
  }, [
    pool,
    mintAddressA,
    mintAddressB,
    lastTypedAccount,
    setTheBestAmountB,
    setTheBestAmountA,
    amountA,
    theBestPrice,
    amountB,
  ])

  useEffect(() => {
    if (requestsState.current?.[HYPERSEA_PROGRAM_ID.toBase58()] === RequestState.succeed && cachedAccounts.size) {
      calculateDependent()
      calculateTheBestDependent()
    }
  }, [amountB, amountA, lastTypedAccount, calculateDependent, requestsState, cachedAccounts, calculateTheBestDependent])

  return {
    A: base,
    B: quote,
    pool,
    lastTypedAccount,
    setLastTypedAccount,
    setPoolOperation,
    options,
    setOptions,
    setMintAddressA,
    setMintAddressB,
    setAmountA,
    setAmountB,
  }
}
