import { nanoid } from '@reduxjs/toolkit'
import { TokenList } from '@uniswap/token-lists'
import getTokenList from 'lib/hooks/useTokenList/fetchTokenList'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'

import { fetchTokenList } from '../state/lists/actions'

export function useFetchListCallback(): (listUrl: string, sendDispatch?: boolean) => Promise<TokenList> {
  const dispatch = useAppDispatch()

  const ensResolver = useCallback(async (ensName: string) => {
    throw new Error('Could not construct mainnet ENS resolver')
    // if (!library || chainId !== 1) {
    //   const networkLibrary = getNetworkLibrary()
    //   const network = await networkLibrary.getNetwork()
    //   if (networkLibrary && network.chainId === 1) {
    //     return resolveENSContentHash(ensName, networkLibrary)
    //   }
    //   throw new Error('Could not construct mainnet ENS resolver')
    // }
    // return resolveENSContentHash(ensName, library)
  }, [])

  // note: prevent dispatch if using for list search or unsupported list
  return useCallback(
    async (listUrl: string, sendDispatch = true) => {
      const requestId = nanoid()
      sendDispatch && dispatch(fetchTokenList.pending({ requestId, url: listUrl }))
      return getTokenList(listUrl, ensResolver)
        .then((tokenList) => {
          sendDispatch && dispatch(fetchTokenList.fulfilled({ url: listUrl, tokenList, requestId }))
          return tokenList
        })
        .catch((error) => {
          console.debug(`Failed to get list at url ${listUrl}`, error)
          sendDispatch && dispatch(fetchTokenList.rejected({ url: listUrl, requestId, errorMessage: error.message }))
          throw error
        })
    },
    [dispatch, ensResolver]
  )
}
