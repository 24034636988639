import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import styled from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { RowBetween, RowFixed } from '../Row'

const StyledSwapHeader = styled.div`
  padding: 1rem 1.25rem 0.5rem 1.25rem;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`

const StyledHeaderContainer = styled(RowFixed)`
  flex: 1;
  justify-content: center;
  width: 100%;
  display: flex;
  margin-right: -20px;
`

const StyledContainer = styled(RowBetween)`
  margin-bottom: 48px;
`

export default function SwapHeader({ allowedSlippage }: { allowedSlippage: Percent }) {
  return (
    <StyledSwapHeader>
      <StyledContainer>
        <StyledHeaderContainer>
          <ThemedText.Black fontWeight={600} fontSize={24}>
            <Trans>Swap</Trans>
          </ThemedText.Black>
        </StyledHeaderContainer>
        {/* <RowFixed>
          <SettingsTab placeholderSlippage={allowedSlippage} />
        </RowFixed> */}
      </StyledContainer>
    </StyledSwapHeader>
  )
}
