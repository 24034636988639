import * as anchor from '@project-serum/anchor'
import { AnchorProvider } from '@project-serum/anchor'
import { Connection, PublicKey, Transaction } from '@solana/web3.js'
import { NETWORK_TYPE, SOLANA_NETWORK_TYPE, SOLANA_NETWORKS, SUPPORTED_SOLANA_CHAIN_ID } from 'constants/solana'
import { AbstractConnector } from 'web3-react-abstract-connector'
import { ConnectorUpdate } from 'web3-react-types'

import { RpcSolanaProvider } from './Bridge'

interface SolanaConnectorArguments {
  url: string
  chainId: number
  supportedChainIds: number[]
}

type DisplayEncoding = 'utf8' | 'hex'
type SolanaWalletEvent = 'disconnect' | 'connect' | 'accountChanged'
type SolanaWalletRequestMethod = 'connect' | 'disconnect' | 'signTransaction' | 'signAllTransactions' | 'signMessage'

interface ConnectOpts {
  onlyIfTrusted: boolean
}

interface SolanaProvider {
  publicKey: PublicKey | null
  isConnected: boolean | null
  signTransaction: (transaction: Transaction) => Promise<Transaction>
  signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>
  signMessage: (message: Uint8Array | string, display?: DisplayEncoding) => Promise<any>
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>
  disconnect: () => Promise<void>
  on: (event: SolanaWalletEvent, handler: (args: any) => void) => void
  request: (method: SolanaWalletRequestMethod, params: any) => Promise<unknown>
}

const getPhantomProvider = (): SolanaProvider | undefined => {
  if ('solana' in window) {
    const anyWindow: any = window
    const provider = anyWindow.solana
    if (provider.isPhantom) {
      return provider
    }
  }
  return
}

const getSolflareProvider = (): SolanaProvider | undefined => {
  if ('solflare' in window) {
    const anyWindow: any = window
    const provider = anyWindow.solflare
    if (provider.isSolflare) {
      return provider
    }
  }
  return
}

export abstract class SolanaConnector extends AbstractConnector {
  protected solanaProvider: RpcSolanaProvider
  protected solanaWallet: SolanaProvider | undefined
  protected currentChainId: number
  protected activated: boolean

  constructor({ url, chainId, supportedChainIds }: SolanaConnectorArguments) {
    super({ supportedChainIds })

    this.activated = false

    this.currentChainId = chainId
    this.solanaProvider = new RpcSolanaProvider(this, chainId, url, new Connection(url))

    anchor.setProvider(
      new AnchorProvider(new Connection(url), this.solanaWallet as any, {
        preflightCommitment: 'processed',
      })
    )
  }

  public get provider(): RpcSolanaProvider {
    return this.solanaProvider
  }

  public async getProvider(): Promise<RpcSolanaProvider> {
    return this.solanaProvider
  }

  public async getChainId(): Promise<number> {
    return this.currentChainId
  }

  public changeChainId(chainId: SUPPORTED_SOLANA_CHAIN_ID) {
    const network: NETWORK_TYPE = SOLANA_NETWORK_TYPE[chainId]
    const url: string = SOLANA_NETWORKS[network]

    this.currentChainId = chainId

    this.solanaProvider = new RpcSolanaProvider(this, chainId, url, new Connection(url))

    anchor.setProvider(
      new AnchorProvider(new Connection(url), this.solanaWallet as any, {
        preflightCommitment: 'processed',
      })
    )

    this.emitUpdate({
      chainId,
      account: null,
      provider: this.solanaProvider,
    })
  }

  public close(): Promise<void> {
    try {
      this.emitDeactivate()
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  public async getAccount(): Promise<string | null> {
    return this.solanaWallet?.publicKey?.toBase58() || null
  }

  public async deactivate() {
    await this.solanaWallet?.disconnect()
    this.solanaWallet = undefined
    return
  }
}

export class PhantomConnector extends SolanaConnector {
  constructor({ url, chainId, supportedChainIds }: SolanaConnectorArguments) {
    super({ url, chainId, supportedChainIds })
  }

  public async activate(): Promise<ConnectorUpdate> {
    if (!this.solanaWallet) {
      this.solanaWallet = getPhantomProvider()
    }

    if (this.activated) {
      await this.solanaWallet?.connect()
    }
    this.activated = true

    anchor.setProvider(
      new AnchorProvider(new Connection(this.solanaProvider.url), this.solanaWallet as any, {
        preflightCommitment: 'processed',
      })
    )

    return {
      provider: this.solanaProvider,
      chainId: this.currentChainId,
      account: null,
    }
  }
}

export class SolflareConnector extends SolanaConnector {
  constructor({ url, chainId, supportedChainIds }: SolanaConnectorArguments) {
    super({ url, chainId, supportedChainIds })
  }

  public async activate(): Promise<ConnectorUpdate> {
    if (!this.solanaWallet) {
      this.solanaWallet = getSolflareProvider()
    }

    if (this.activated) {
      await this.solanaWallet?.connect()
    }
    this.activated = true

    anchor.setProvider(
      new AnchorProvider(new Connection(this.solanaProvider.url), this.solanaWallet as any, {
        preflightCommitment: 'processed',
      })
    )

    return {
      provider: this.solanaProvider,
      chainId: this.currentChainId,
      account: null,
    }
  }
}
