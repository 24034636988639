import { AccountLayout } from '@solana/spl-token'
import { TokenSwapLayout } from '@solana/spl-token-swap'
import { Account, AccountInfo, PublicKey, SystemProgram, Transaction, TransactionInstruction } from '@solana/web3.js'
import { HYPERSEA_PROGRAM_ID, TOKEN_PROGRAM_ID, WRAPPED_SOL_MINT } from 'constants/solana'

import {
  createApproveInstruction,
  createCloseAccountInstruction,
  createInitAccountInstruction,
  createRevokeInstruction,
} from '../instructions'
import { TokenAccount } from './accounts'

export interface LiquidityComponent {
  amount: number
  account?: TokenAccount
  mintAddress: string
}

export const isLatest = (swap: AccountInfo<Buffer>) => {
  return swap.data.length === TokenSwapLayout.span
}

export const getWrappedAccount = (
  instructions: TransactionInstruction[],
  cleanupInstructions: TransactionInstruction[],
  toCheck: TokenAccount | undefined,
  payer: PublicKey,
  amount: number,
  signers: Account[]
) => {
  const account = new Account()
  instructions.push(
    SystemProgram.createAccount({
      fromPubkey: payer,
      newAccountPubkey: account.publicKey,
      lamports: amount,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    })
  )
  instructions.push(createInitAccountInstruction(TOKEN_PROGRAM_ID, WRAPPED_SOL_MINT, account.publicKey, payer))
  cleanupInstructions.push(createCloseAccountInstruction(TOKEN_PROGRAM_ID, account.publicKey, payer, payer, []))
  signers.push(account)
  return account.publicKey
}

export const createSplAccount = (
  instructions: TransactionInstruction[],
  payer: PublicKey,
  accountRentExempt: number,
  mint: PublicKey,
  owner: PublicKey,
  space: number
) => {
  const account = new Account()
  instructions.push(
    SystemProgram.createAccount({
      fromPubkey: payer,
      newAccountPubkey: account.publicKey,
      lamports: accountRentExempt,
      space,
      programId: TOKEN_PROGRAM_ID,
    })
  )
  instructions.push(createInitAccountInstruction(TOKEN_PROGRAM_ID, mint, account.publicKey, owner))
  return account
}

export const findOrCreateAccountByMint = (
  payer: PublicKey,
  owner: PublicKey,
  instructions: TransactionInstruction[],
  cleanupInstructions: TransactionInstruction[],
  accountRentExempt: number,
  mint: PublicKey,
  signers: Account[],
  excluded?: Set<string>
) => {
  const accountToFind = mint.toBase58()
  const account = { pubkey: new PublicKey(accountToFind) }

  const isWrappedSol = accountToFind === WRAPPED_SOL_MINT.toBase58()
  let toAccount: PublicKey
  if (account && !isWrappedSol) {
    toAccount = account?.pubkey
  } else {
    const newToAccount = createSplAccount(instructions, payer, accountRentExempt, mint, owner, AccountLayout.span)
    toAccount = newToAccount.publicKey
    signers.push(newToAccount)
    if (isWrappedSol) {
      cleanupInstructions.push(createCloseAccountInstruction(HYPERSEA_PROGRAM_ID, toAccount, payer, payer, []))
    }
  }
  return toAccount
}

export const approveAmount = (
  instructions: TransactionInstruction[],
  cleanupInstructions: TransactionInstruction[],
  account: PublicKey,
  owner: PublicKey,
  amount: number,
  delegate?: PublicKey
) => {
  const tokenProgram = TOKEN_PROGRAM_ID
  const transferAuthority = new Account()
  instructions.push(
    createApproveInstruction(tokenProgram, account, delegate ?? transferAuthority.publicKey, owner, [], amount)
  )
  cleanupInstructions.push(createRevokeInstruction(tokenProgram, account, owner, []))
  return transferAuthority
}

export const sendTransaction = async (
  library: any,
  connector: any,
  instructions: TransactionInstruction[],
  signers: Account[],
  awaitConfirmation = true
) => {
  let transaction = new Transaction().add(...instructions)
  transaction.recentBlockhash = (await library?.send('sol_getRecentBlockhash', ['max'])).blockhash
  transaction.setSigners(connector?.solanaWallet?.publicKey, ...signers.map((s) => s.publicKey))
  if (signers.length > 0) {
    transaction.partialSign(...signers)
  }
  transaction = await connector?.solanaWallet.signTransaction(transaction)
  const rawTransaction = transaction.serialize()
  const options = {
    skipPreflight: true,
    commitment: 'singleGossip',
  }
  const txid = await library?.send('sol_sendRawTransaction', [rawTransaction, options])
  if (awaitConfirmation) {
    const status = await library?.send('sol_confirmTransaction', [txid, options && (options.commitment as any)])
    if (status?.value?.err) {
      throw new Error(`Raw transaction ${txid} failed (${JSON.stringify(status.value.err)})`)
    }
  }
  return txid
}
