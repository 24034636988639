/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { Trans } from '@lingui/macro'
import { BN } from '@project-serum/anchor'
import { PublicKey } from '@solana/web3.js'
import Card from 'components/Card'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { SOLANA_POOLS_DEVNET, SOLANA_POOLS_MAINNET, SOLANA_POOLS_TESTNET } from 'constants/pools'
import { SOLANA_CHAIN_IDS } from 'constants/solana'
import { useAccount } from 'hooks/solana/useAccount'
import { PoolsContext } from 'hooks/solana/usePools'
import { useAllTokens } from 'hooks/Tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTable } from 'react-table'
import { Text } from 'rebass'
import { useAppSelector } from 'state/hooks'
import styled, { ThemeContext } from 'styled-components/macro'
import { convertToBalance, gtZero } from 'utils/bn'
import { numberConversation } from 'utils/conversation'
import { currencyId } from 'utils/currencyId'
import { deserializeAccount, deserializeMint } from 'utils/solana/accounts'

import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { RowBetween } from '../../components/Row'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { HideSmall, ThemedText } from '../../theme'

const PageWrapper = styled(AutoColumn)`
  max-width: 900px;
  width: 100%;
`

const TitleRow = styled(RowBetween)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  background: ${({ theme }) => theme.bg7};
  border-radius: 30px;
  padding: 48px 43px;
`

const ActionButtonPrimary = styled(ButtonPrimary)`
  border-radius: 42px;
  min-width: fit-content;
  padding: 4px 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const PairContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;

  > a:not(:last-of-type) {
    margin-right: 4px;
  }
`

const EmptyProposals = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`

const LiqContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  table {
    width: 100%;
    border-spacing: 0 16px;

    tr {
      padding-left: 8px;
      th:not(:first-of-type):not(:last-of-type) {
        text-align: right;
      }
    }

    td,
    th {
      margin: 0;
      padding: 8px;
      text-align: left;
    }
    td:not(:first-of-type):not(:last-of-type) {
      text-align: right;
    }

    tbody {
      tr {
        background-color: ${({ theme }) => theme.primary7};

        td:not(:first-of-type) {
          border-left: 1px solid ${({ theme }) => theme.primary7};
        }

        td:first-child {
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }
        td:last-child {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }
    }
  }
`

export default function Pool() {
  const theme = useContext(ThemeContext)
  const { chainId, library } = useActiveWeb3React()
  const account = useAccount()
  const [poolsOfLiq, setPoolsOfLiq] = useState<any>([])
  const { cachedAccounts, cachedUserAccounts } = useContext(PoolsContext)
  const tokens = useAllTokens()
  const native: any = useNativeCurrency()
  const poolList: any = useAppSelector((state) => state.pools.byUrl)

  const allTokens: any = {
    ...tokens,
    ...(native?.wrapped?.tokenInfo?.address
      ? {
          [native.wrapped.tokenInfo.address]: native.wrapped,
        }
      : null),
  }

  const columns = useMemo(
    () => [
      {
        Header: <Trans>Pair</Trans>,
        id: 'pair',
        accessor: 'currencies',
        Cell: ({ value }: any) => (
          <PairContainer>
            <DoubleCurrencyLogo currency0={value[1]} currency1={value[0]} size={20} />
            <Text fontWeight={500} fontSize={14} marginLeft="8px">
              {value[0].symbol}/{value[1].symbol} ({value[2]}%)
            </Text>
          </PairContainer>
        ),
      },
      {
        Header: <Trans>Total</Trans>,
        id: 'total',
        minWidth: 150,
        accessor: (originalRow: any): any => ({
          totalValueA: originalRow.totalValueA,
          totalValueB: originalRow.totalValueB,
        }),
        Cell: ({ value }: any) => {
          const { totalValueA = [], totalValueB = [] } = value || {}

          const [valueA, decimalsA] = totalValueA
          const [valueB, decimalsB] = totalValueB

          return (
            <Text fontSize={14} fontWeight={500}>
              <>
                {gtZero(valueA) ? numberConversation(convertToBalance(valueA, decimalsA).toString()) : '-'}/
                {gtZero(valueB) ? numberConversation(convertToBalance(valueB, decimalsB).toString()) : '-'}
              </>
            </Text>
          )
        },
      },
      {
        Header: <Trans>TVL</Trans>,
        accessor: 'totalSupply',
        minWidth: 150,
        Cell: ({ value }: any) => {
          const [amount, decimals] = value || []

          return (
            <Text fontSize={14} fontWeight={500}>
              {gtZero(amount) ? `$${numberConversation(convertToBalance(amount, decimals).toString())}` : '-'}
            </Text>
          )
        },
        Footer: ({ rows }: any) => {
          const total = useMemo(() => {
            return rows.reduce((sum: any, row: any) => {
              let tvl = new BN(0)
              const [totalSupply, supplyDecimals] = row?.values?.totalSupply || []

              if (gtZero(totalSupply)) {
                tvl = convertToBalance(totalSupply, supplyDecimals)
              }

              return sum.add(tvl)
            }, new BN(0))
          }, [rows])

          return (
            <Text fontSize={14} fontWeight={700}>
              <Trans>Total: ${numberConversation(total.toString())}</Trans>
            </Text>
          )
        },
      },
      {
        Header: <Trans>My Position</Trans>,
        id: 'myPosition',
        minWidth: 150,
        accessor: (originalRow: any): any => ({
          amount: originalRow.amount,
          tokenAmountA: originalRow.tokenAmountA,
          tokenAmountB: originalRow.tokenAmountB,
        }),
        Cell: ({ value }: any) => {
          const { amount = [], tokenAmountA = [], tokenAmountB = [] } = value || {}

          const [userSupply, supplyDecimals] = amount
          const [tokenA, tokenADecimals] = tokenAmountA
          const [tokenB, tokenBDecimals] = tokenAmountB

          return (
            <Text fontSize={14} fontWeight={500}>
              {gtZero(userSupply) ? (
                <>
                  {gtZero(tokenA) ? numberConversation(convertToBalance(tokenA, tokenADecimals).toString()) : '-'}/
                  {gtZero(tokenB) ? numberConversation(convertToBalance(tokenB, tokenBDecimals).toString()) : '-'} ( $
                  {gtZero(userSupply)
                    ? numberConversation(convertToBalance(userSupply, supplyDecimals).toString())
                    : '-'}
                  )
                </>
              ) : (
                '-'
              )}
            </Text>
          )
        },
      },
      {
        Header: <Trans>Actions</Trans>,
        id: 'actions',
        accessor: 'currencies',
        Cell: ({ value }: any) => (
          <ActionContainer>
            <ActionButtonPrimary width="32px" as={Link} to={`/add/${currencyId(value[0])}/${currencyId(value[1])}`}>
              <Trans>Add</Trans>
            </ActionButtonPrimary>
            {value[3] ? (
              <ActionButtonPrimary as={Link} to={`/remove/${currencyId(value[0])}/${currencyId(value[1])}`}>
                <Trans>Remove</Trans>
              </ActionButtonPrimary>
            ) : null}
          </ActionContainer>
        ),
        maxWidth: '40px',
      },
    ],
    []
  ) as any
  const data = useMemo(() => poolsOfLiq, [poolsOfLiq])

  const { getTableProps, getTableBodyProps, headerGroups, rows, footerGroups, prepareRow } = useTable({
    columns,
    data,
  })

  useEffect(() => {
    const getPoolOfLiq = async () => {
      const accounts =
        Array.from(cachedUserAccounts, ([name, value]) => ({
          info: deserializeAccount(value.data),
          pubkey: new PublicKey(name),
        })) || []

      let url = ''
      switch (chainId) {
        case SOLANA_CHAIN_IDS['mainnet-beta']:
          url = SOLANA_POOLS_MAINNET
          break
        case SOLANA_CHAIN_IDS.testnet:
          url = SOLANA_POOLS_TESTNET
          break
        case SOLANA_CHAIN_IDS.devnet:
          url = SOLANA_POOLS_DEVNET
      }

      setPoolsOfLiq(
        poolList?.[url]?.current?.length
          ? poolList[url].current
              .map((pool: any) => ({
                ...pool,
                pubkeys: {
                  program: new PublicKey(pool?.pubkeys?.program),
                  account: new PublicKey(pool?.pubkeys?.account),
                  holdingAccounts: [
                    new PublicKey(pool?.pubkeys?.holdingAccounts[0]),
                    new PublicKey(pool?.pubkeys?.holdingAccounts[1]),
                  ],
                  holdingMints: [
                    new PublicKey(pool?.pubkeys?.holdingMints[0]),
                    new PublicKey(pool?.pubkeys?.holdingMints[1]),
                  ],
                  mint: new PublicKey(pool?.pubkeys?.mint),
                  feeAccount: new PublicKey(pool?.pubkeys?.feeAccount),
                  feeMint: new PublicKey(pool?.pubkeys?.feeMint),
                  rewardsAccount: new PublicKey(pool?.pubkeys?.rewardsAccount),
                  rewardsMint: new PublicKey(pool?.pubkeys?.rewardsMint),
                },
                raw: {
                  pubkey: new PublicKey(0),
                  data: null,
                  account: {
                    executable: true,
                    owner: new PublicKey(0),
                    lamports: 100000000,
                    data: new Buffer(''),
                    rentEpoch: 123,
                  },
                },
              }))
              .map((pool: any) => {
                const foundedAccount = accounts.find(
                  (userAccount: any) => userAccount.info.mint.toBase58() === pool.pubkeys.mint.toBase58()
                )

                const poolMint = deserializeMint(
                  cachedAccounts.get(pool.pubkeys.mint.toBase58())?.data ?? new Buffer('')
                )

                const totalSupply = poolMint?.supply || new BN(0)
                const poolMintDecimals = poolMint?.decimals || 9

                const mintA = deserializeMint(
                  cachedAccounts.get(pool.pubkeys.holdingMints[0].toBase58())?.data ?? new Buffer('')
                )
                const mintB = deserializeMint(
                  cachedAccounts.get(pool.pubkeys.holdingMints[1].toBase58())?.data ?? new Buffer('')
                )

                const currency0 = allTokens[pool.pubkeys.holdingMints[0]?.toBase58() || '']
                const currency1 = allTokens[pool.pubkeys.holdingMints[1]?.toBase58() || '']

                const totalValueA =
                  deserializeAccount(
                    cachedAccounts.get(pool.pubkeys.holdingAccounts[0].toBase58())?.data ?? new Buffer('')
                  )?.amount || new BN(0)
                const totalValueB =
                  deserializeAccount(
                    cachedAccounts.get(pool.pubkeys.holdingAccounts[1].toBase58())?.data ?? new Buffer('')
                  )?.amount || new BN(0)

                if (foundedAccount) {
                  const tokenAmountA = totalSupply.isZero()
                    ? new BN(0)
                    : foundedAccount.info.amount.mul(totalValueA).div(totalSupply)
                  const tokenAmountB = totalSupply.isZero()
                    ? new BN(0)
                    : foundedAccount.info.amount.mul(totalValueB).div(totalSupply)

                  return {
                    pool,
                    amount: [foundedAccount.info.amount, poolMintDecimals],
                    currencies: [currency0, currency1, pool.fee, true],
                    totalSupply: [totalSupply, poolMintDecimals],
                    totalValueA: [totalValueA, mintA?.decimals || 9],
                    totalValueB: [totalValueB, mintB?.decimals || 9],
                    tokenAmountA: [tokenAmountA, mintA?.decimals || 9],
                    tokenAmountB: [tokenAmountB, mintB?.decimals || 9],
                  }
                } else {
                  return {
                    pool,
                    amount: [new BN(0), poolMintDecimals],
                    currencies: [currency0, currency1, pool.fee, false],
                    totalSupply: [totalSupply, poolMintDecimals],
                    totalValueA: [totalValueA, mintA?.decimals || 9],
                    totalValueB: [totalValueB, mintB?.decimals || 9],
                    tokenAmountA: [new BN(0), mintA?.decimals || 9],
                    tokenAmountB: [new BN(0), mintB?.decimals || 9],
                  }
                }
              })
          : []
      )
    }

    if (cachedAccounts.size > 0) {
      getPoolOfLiq()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, cachedAccounts, cachedUserAccounts, chainId, library, poolList])

  return (
    <>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="md" style={{ width: '100%' }}>
            <TitleRow>
              <HideSmall>
                <ThemedText.Body fontSize={24} fontWeight="600" style={{ marginBottom: '40px' }}>
                  <Trans>Liquidity Pools</Trans>
                </ThemedText.Body>
              </HideSmall>
              {!account ? (
                <Card padding="0">
                  <ThemedText.Body color={theme.primaryText1} textAlign="center" fontSize="12px" fontWeight="400">
                    <Trans>Connect to a wallet to view your liquidity.</Trans>
                  </ThemedText.Body>
                </Card>
              ) : null}
              {poolsOfLiq.length > 0 ? (
                <LiqContainer>
                  <table {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      {footerGroups.map((group) => (
                        <tr {...group.getFooterGroupProps()}>
                          {group.headers.map((column) => (
                            <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                          ))}
                        </tr>
                      ))}
                    </tfoot>
                  </table>
                </LiqContainer>
              ) : (
                <EmptyProposals>
                  <ThemedText.Body color={theme.primaryText1} textAlign="center" fontSize="12px" fontWeight="400">
                    <Trans>No liquidity found.</Trans>
                  </ThemedText.Body>
                </EmptyProposals>
              )}
            </TitleRow>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  )
}
