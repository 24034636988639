import getPoolList from 'lib/hooks/usePoolList/fetchPoolList'
import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { updateErrorState, updateList, updateLoadingState } from 'state/pools/actions'

export function useFetchPoolsCallback(): (listUrl: string) => any {
  const dispatch = useAppDispatch()
  const poolList: any = useAppSelector((state) => state.pools.byUrl)

  return useCallback(
    async (listUrl: string) => {
      if (!listUrl || poolList?.[listUrl]?.loading) {
        return []
      }

      dispatch(updateLoadingState({ loading: true, url: listUrl }))
      return getPoolList(listUrl)
        .then((poolList) => {
          dispatch(updateList({ url: listUrl, poolList: poolList?.pools }))
          return poolList?.pools
        })
        .catch((error) => {
          console.debug(`Failed to get list at url ${listUrl}`, error)
          dispatch(updateErrorState({ url: listUrl, error: error.message }))
          throw error
        })
        .finally(() => {
          dispatch(updateLoadingState({ loading: false, url: listUrl }))
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}
