import { Trans } from '@lingui/macro'
import { ButtonLight } from 'components/Button'
import Modal from 'components/Modal'
import { LEADERBOARD_PATH, RULES_PATH } from 'constants/common'
import { add, formatDuration, intervalToDuration } from 'date-fns'
import { useAccount } from 'hooks/solana/useAccount'
import { darken } from 'polished'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useWalletModalToggle } from 'state/application/hooks'
import styled from 'styled-components/macro'

import { ReactComponent as Close } from '../../assets/images/x.svg'
import { ReactComponent as Coin1 } from '../../assets/svg/coin1.svg'
import { ReactComponent as Coin2 } from '../../assets/svg/coin2.svg'
import { ReactComponent as Coin3 } from '../../assets/svg/coin3.svg'
import { ReactComponent as Coin4 } from '../../assets/svg/coin4.svg'
import { ReactComponent as Place1 } from '../../assets/svg/place1.svg'
import { ReactComponent as Place2 } from '../../assets/svg/place2.svg'
import { ReactComponent as Place3 } from '../../assets/svg/place3.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  margin: 0 24px 10px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 24px 32px;
`

const CloseIcon = styled.div`
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const Title = styled.span`
  color: ${({ theme }) => theme.primaryText1};
  font-size: 24px;
  font-weight: 600;
`

const CloseColor = styled(Close)`
  position: absolute;
  top: 39px;
  right: 31px;
  path {
    stroke: ${({ theme }) => theme.primaryText1};
  }
`

const CoinBackground = styled.div`
  display: flex;
  position: relative;
  height: 200px;
`

const StyledCoin1 = styled(Coin1)`
  position: absolute;
  top: 15px;
  left: 0px;
`

const StyledCoin2 = styled(Coin2)`
  position: absolute;
  top: 0;
  left: 140px;
`

const StyledCoin3 = styled(Coin3)`
  position: absolute;
  top: 82px;
  left: 229px;
`

const StyledCoin4 = styled(Coin4)`
  position: absolute;
  top: 106px;
  right: 0px;
`

const TitlePeriod = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: ${({ theme }) => theme.primaryText2};
`

const DescriptionPeriod = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.primaryText2};
  margin-bottom: 10px;
`

const EventDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.primaryText1};
  margin-bottom: 32px;
`

const RewardsTitle = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.primaryText1};
`

const RewardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex: 1;
  margin-bottom: 32px;
`

const RewardCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  width: 110px;
  height: 110px;

  padding: 8px;
  margin: 4px;

  background: ${({ theme }) => theme.bg8};
  border-radius: 16px;
`

const RewarsText = styled.div`
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryText1};
`

const StyledExternalLink = styled(Link)`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-top: 16px;

  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primary6};
  text-decoration: underline;

  :hover {
    color: ${({ theme }) => darken(0.05, theme.primary6)};
  }

  :focus {
    outline: none;
  }

  :active {
    text-decoration: none;
  }
`

const EventModal = () => {
  const [isOpen, setOpen] = useState(false)
  const location = useLocation()
  const account = useAccount()
  const toggleWalletModal = useWalletModalToggle()

  const hasEvent = new URLSearchParams(location.search).get('event')

  useEffect(() => {
    if (hasEvent && !account) {
      setOpen(true)
    }

    if (location.pathname === RULES_PATH || location.pathname === LEADERBOARD_PATH) {
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const handleDismiss = () => setOpen(false)

  const handleConnectWallet = () => {
    setOpen(false)
    toggleWalletModal()
  }

  return (
    <Modal isOpen={isOpen} onDismiss={handleDismiss}>
      <Wrapper>
        <CoinBackground>
          <StyledCoin1 />
          <StyledCoin2 />
          <StyledCoin3 />
          <StyledCoin4 />
          <CloseIcon onClick={handleDismiss}>
            <CloseColor />
          </CloseIcon>
        </CoinBackground>
        <Header>
          <Title>
            <Trans>Trading Competition -</Trans>
            <br />
            <Trans>$15k in Rewards to Be Won!</Trans>
          </Title>
        </Header>
        <Body>
          <TitlePeriod>
            <Trans>Competition period:</Trans>
          </TitlePeriod>
          <DescriptionPeriod>
            <Trans>
              {formatDuration(
                intervalToDuration({ start: new Date(), end: add(new Date(), { days: 10, hours: 3, minutes: 30 }) }),
                {
                  format: ['days', 'hours', 'minutes'],
                }
              )}
            </Trans>
          </DescriptionPeriod>
          <EventDescription>
            All users that during the competition period and trade 100USDT ​​or more effective trading volume (including
            buys & sells)
          </EventDescription>
          <RewardsTitle>Rewards</RewardsTitle>
          <RewardsContainer>
            <RewardCard>
              <Place1></Place1>
              <RewarsText>100 tokens</RewarsText>
            </RewardCard>
            <RewardCard>
              <Place2></Place2>
              <RewarsText>60 tokens</RewarsText>
            </RewardCard>
            <RewardCard>
              <Place3></Place3>
              <RewarsText>30 tokens</RewarsText>
            </RewardCard>
          </RewardsContainer>
          <ButtonLight onClick={handleConnectWallet}>
            <Trans>Connect Wallet to start</Trans>
          </ButtonLight>
          <StyledExternalLink to="/rules">Terms & Conditions</StyledExternalLink>
        </Body>
      </Wrapper>
    </Modal>
  )
}

export default EventModal
