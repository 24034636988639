import { Connection, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import { TOKEN_PROGRAM_ID } from 'constants/solana'
import { AbstractConnector } from 'web3-react-abstract-connector'

type AsyncSendable = {
  isMetaMask?: boolean
  host?: string
  path?: string
  sendAsync?: (request: any, callback: (error: any, response: any) => void) => void
  send?: (request: any, callback: (error: any, response: any) => void) => void
}

interface SolanaConnector extends AbstractConnector {
  changeChainId: (chainId: number) => void
}

interface BatchItem {
  request: { jsonrpc: '2.0'; id: number; method: string; params: unknown }
  resolve: (result: any) => void
  reject: (error: Error) => void
}

class RequestError extends Error {
  constructor(message: string, public code: number, public data?: unknown) {
    super(message)
  }
}

export class RpcSolanaProvider implements AsyncSendable {
  public readonly chainId: number
  public readonly url: string
  public readonly host: string
  public readonly path: string
  public readonly batchWaitTimeMs: number
  public readonly solanaProvider: Connection

  private readonly connector: SolanaConnector

  private loadingAccounts = false
  private nextId = 1
  private batchTimeoutId: ReturnType<typeof setTimeout> | null = null
  private batch: BatchItem[] = []

  constructor(
    connector: SolanaConnector,
    chainId: number,
    url: string,
    solanaProvider: Connection,
    batchWaitTimeMs?: number
  ) {
    this.connector = connector
    this.chainId = chainId
    this.url = url
    const parsed = new URL(url)
    this.host = parsed.host
    this.path = parsed.pathname
    this.solanaProvider = solanaProvider
    // how long to wait to batch calls
    this.batchWaitTimeMs = batchWaitTimeMs ?? 50
  }

  public readonly clearBatch = async () => {
    console.debug('Clearing batch', this.batch)
    let batch = this.batch

    batch = batch.filter((b) => {
      if (b.request.method === 'wallet_switchEthereumChain') {
        try {
          this.connector.changeChainId(parseInt((b.request.params as [{ chainId: string }])[0].chainId))
          b.resolve({ id: b.request.id })
        } catch (error) {
          b.reject(error)
        }
        return false
      }
      return true
    })

    this.batch = []
    this.batchTimeoutId = null
    let response: Response
    try {
      response = await fetch(this.url, {
        method: 'POST',
        headers: { 'content-type': 'application/json', accept: 'application/json' },
        body: JSON.stringify(batch.map((item) => item.request)),
      })
    } catch (error) {
      batch.forEach(({ reject }) => reject(new Error('Failed to send batch call')))
      return
    }

    if (!response.ok) {
      batch.forEach(({ reject }) => reject(new RequestError(`${response.status}: ${response.statusText}`, -32000)))
      return
    }

    let json
    try {
      json = await response.json()
    } catch (error) {
      batch.forEach(({ reject }) => reject(new Error('Failed to parse JSON response')))
      return
    }
    const byKey = batch.reduce<{ [id: number]: BatchItem }>((memo, current) => {
      memo[current.request.id] = current
      return memo
    }, {})
    for (const result of json) {
      const {
        resolve,
        reject,
        request: { method },
      } = byKey[result.id]
      if ('error' in result) {
        reject(new RequestError(result?.error?.message, result?.error?.code, result?.error?.data))
      } else if ('result' in result && resolve) {
        resolve(result.result)
      } else {
        reject(new RequestError(`Received unexpected JSON-RPC response to ${method} request.`, -32000, result))
      }
    }
  }

  public readonly sendAsync = (
    request: {
      jsonrpc: '2.0'
      id: number | string | null
      method: string
      params?: any[]
    },
    callback: (error: any, response: any) => void
  ): void => {
    this.request(request.method, request.params)
      .then((result) => callback(null, { jsonrpc: '2.0', id: request.id, result }))
      .catch((error) => callback(error, null))
  }

  public readonly request = async (
    method: string | { method: string; params: any[] },
    params?: any[]
  ): Promise<any> => {
    if (typeof method !== 'string') {
      return this.request(method.method, method.params)
    }

    switch (method) {
      case 'eth_gasPrice': {
        return ''
      }
      case 'eth_accounts': {
        return []
      }
      case 'eth_blockNumber': {
        const response = await this.solanaProvider.getLatestBlockhashAndContext('finalized')
        return response.context.slot
      }
      case 'eth_chainId': {
        return this.chainId
      }
      case 'eth_getBalance': {
        const publicKey = params?.[0] || ''

        if (!publicKey) {
          return 0
        }

        const response = await this.solanaProvider.getBalance(new PublicKey(publicKey), 'single')
        return response / LAMPORTS_PER_SOL
      }
      case 'sol_getTokenBalances': {
        const publicKey = params?.[0] || ''

        if (!publicKey) {
          return []
        }

        try {
          const response = await this.solanaProvider.getTokenAccountsByOwner(
            new PublicKey(publicKey),
            {
              programId: TOKEN_PROGRAM_ID,
            },
            'single'
          )

          return response?.value
        } catch {
          console.error('Cannot get token balance')
          return []
        }
      }
      case 'sol_getAccountInfo': {
        const publicKey = params?.[0] || ''

        if (!publicKey) {
          return {}
        }

        const response = await this.solanaProvider.getAccountInfo(publicKey)

        return response
      }
      case 'sol_getMultipleAccounts': {
        const publicKeys = params?.[0] || []
        const commitment = params?.[1] || 'single'

        if (!publicKeys?.length || publicKeys?.length === 0) {
          return []
        }

        const formattedKeys = publicKeys.map((key: any) => {
          let id: PublicKey
          if (typeof key === 'string') {
            id = new PublicKey(key)
          } else {
            id = key
          }

          return id
        })

        const response = await this.solanaProvider.getMultipleAccountsInfo(formattedKeys, commitment)

        return response
      }
      case 'sol_getProgramAccounts': {
        const publicKey = params?.[0] || ''

        if (!publicKey) {
          return []
        }

        const response = await this.solanaProvider.getProgramAccounts(publicKey)

        return response
      }
      case 'sol_getMinimumBalanceForRentExemption': {
        const length = params?.[0] || 0

        if (!length) {
          return {}
        }

        const response = await this.solanaProvider.getMinimumBalanceForRentExemption(length)

        return response
      }
      case 'sol_getRecentBlockhash': {
        const response = await this.solanaProvider.getLatestBlockhashAndContext('finalized')
        return response.value
      }
      case 'sol_sendRawTransaction': {
        const rawTransaction = params?.[0] || []
        const options = params?.[1] || {}

        if (!rawTransaction) {
          return {}
        }

        const response = await this.solanaProvider.sendRawTransaction(rawTransaction, options)

        return response
      }
      case 'sol_confirmTransaction': {
        const id = params?.[0] || []

        if (!id) {
          return {}
        }

        const response = await this.solanaProvider.confirmTransaction(id, 'finalized')

        return response
      }
      case 'sol_simulateTransaction': {
        const rawTransaction = params?.[0] || []

        if (!rawTransaction) {
          return {}
        }

        const response = await this.solanaProvider.simulateTransaction(rawTransaction)

        return response
      }
      case 'eth_getStorageAt': {
        return ''
      }
      case 'eth_getTransactionCount': {
        return ''
      }
      case 'eth_getBlockTransactionCountByHash':
      case 'eth_getBlockTransactionCountByNumber': {
        return ''
      }
      case 'eth_getCode': {
        return ''
      }
      case 'eth_sendRawTransaction': {
        return ''
      }
      case 'eth_call': {
        return
      }
      case 'estimateGas': {
        return ''
      }

      case 'eth_getBlockByHash':
      case 'eth_getBlockByNumber': {
        return ''
      }
      case 'eth_getTransactionByHash': {
        return ''
      }
      case 'eth_getTransactionReceipt': {
        return ''
      }

      case 'eth_sign': {
        return ''
      }

      case 'eth_sendTransaction': {
        return ''
      }

      case 'eth_getUncleCountByBlockHash':
      case 'eth_getUncleCountByBlockNumber':
        break

      case 'eth_getTransactionByBlockHashAndIndex':
      case 'eth_getTransactionByBlockNumberAndIndex':
      case 'eth_getUncleByBlockHashAndIndex':
      case 'eth_getUncleByBlockNumberAndIndex':
      case 'eth_newFilter':
      case 'eth_newBlockFilter':
      case 'eth_newPendingTransactionFilter':
      case 'eth_uninstallFilter':
      case 'eth_getFilterChanges':
      case 'eth_getFilterLogs':
      case 'eth_getLogs':
        break

      default:
        break
    }

    const promise = new Promise((resolve, reject) => {
      this.batch.push({
        request: {
          jsonrpc: '2.0',
          id: this.nextId++,
          method,
          params,
        },
        resolve,
        reject,
      })
    })
    this.batchTimeoutId = this.batchTimeoutId ?? setTimeout(this.clearBatch, this.batchWaitTimeMs)
    return promise
  }
}
