const poolsCache = new Map<string, any>()

export default async function fetchPoolList(url: string): Promise<any> {
  try {
    const cached = poolsCache?.get(url)
    if (cached) {
      return cached
    }

    const response = await fetch(url)
    const json = await response.json()
    poolsCache?.set(url, json)

    return json
  } catch (error) {
    const message = `failed to fetch list: ${url}`
    console.debug(message, error)
    throw new Error(message)
  }
}
