import { BigintIsh } from '@uniswap/sdk-core'

/**
 * Converts a big int to a hex string
 * @param bigintIsh
 * @returns The hex encoded calldata
 */
//FIXME
export function toHex(bigintIsh: BigintIsh): string {
  return ''
}
