import { AbstractConnector } from 'web3-react-abstract-connector'

import PHANTOM_ICON_URL from '../assets/svg/phantom.svg'
import SOLFLARE_ICON_URL from '../assets/svg/solflare.svg'
import { phantomConnector, solflareConnector } from '../connectors'

interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconURL: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  PHANTOM: {
    connector: phantomConnector,
    name: 'Phantom',
    iconURL: PHANTOM_ICON_URL,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#315CF5',
  },
  SOLFLARE: {
    connector: solflareConnector,
    name: 'Solflare',
    iconURL: SOLFLARE_ICON_URL,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#315CF5',
  },
}
