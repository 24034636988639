import { Account, clusterApiUrl, PublicKey } from '@solana/web3.js'

import { SupportedChainId } from '../chains'

enum NETWORK_TYPE {
  DEVNET = 'devnet',
  TESTNET = 'testnet',
  MAINNET = 'mainnet-beta',
}

enum SUPPORTED_SOLANA_CHAIN_ID {
  SOLANA_MAINNET = 101,
  SOLANA_TESTNET = 102,
  SOLANA_DEVNET = 103,
}

const DEFAULT_DENOMINATOR = 10_000
const DEFAULT_SLIPPAGE = 0.5
const DEFAULT_RENT_EXEMPT = 2039280

const SUPPORTED_AIRDROP_TOKENS: string[] = []

const AIRDROP_MINT = new PublicKey('48cfWPgvT9KnX3e9MzXMpww2T473JmbRiudFympzYftJ')
const DEFAULT_PUBLIC_KEY = new PublicKey(0)
const HYPERSEA_PROGRAM_ID = new PublicKey('AKgmxFEoocrV6ZP4kVHyEeiiPi2ZLcznC7PaHEMmNRKz')
const PARTICIPATE_MINT = new PublicKey('6MZqSfFAspAWxv27tiBLa51wxdcHF1M7V2M5jmGFRLPq')
const POOL_WITH_AIRDROP = new PublicKey('QDUa59ub5V82ub6ty7MR1rkrughScci7EkRdqTruKV6')
const PYTH_ACCOUNT = new PublicKey('7VJsBtJzgTftYzEeooSDYyjKXvYRWJHdwvbwfBvTg9K')
const SWAP_FEE_ADDRESS = new PublicKey('HfoTxFR1Tm6kGmWgYWD6J7YHVy1UwqSULUGVLXkJqaKN')
const SWAP_PROGRAM_ID = new PublicKey('SwaPpA9LAaLfeLi3a68M4DjnLqgtticKg6CnyNwgAC8')
const TOKEN_PROGRAM_ID = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA')
const WRAPPED_SOL_MINT = new PublicKey('So11111111111111111111111111111111111111112')

const PYTH_BTC_ORACLE_ACCOUNT = new PublicKey('HovQMDrbAgAYPCmHVSrezcSmkMtXSSUsLDFANExrZh2J')
const PYTH_USDC_ORACLE_ACCOUNT = new PublicKey('5SSkXsEKQepHHAewytPVwdej4epN1nxgLVM84L4KXgy7')

const CHAINLINK_BTC_ORACLE_ACCOUNT = new PublicKey('CzZQBrJCLqjXRfMjRN3fhbxur2QYHUzkpaRwkWsiPqbz')
const CHAINLINK_USDC_ORACLE_ACCOUNT = new PublicKey('4NmRgDfAZrfBHQBuzstMP5Bu1pgBzVn8u1djSvNrNkrN')
const CHAINLINK_PROGRAM_ACCOUNT = new PublicKey('HEvSKofvBgfaexv23kMabbYqxasxU3mQ4ibBMEmJWHny')

const AUTH_ACCOUNT = new Account([
  137, 94, 119, 3, 94, 20, 35, 193, 128, 9, 104, 215, 161, 231, 160, 151, 49, 110, 77, 174, 83, 187, 154, 149, 243, 104,
  181, 235, 105, 82, 176, 168, 157, 6, 9, 204, 142, 100, 116, 72, 75, 142, 124, 54, 126, 26, 151, 234, 255, 107, 204,
  55, 36, 123, 103, 103, 69, 91, 151, 102, 84, 214, 4, 103,
])

const SOLANA_NETWORKS = {
  [NETWORK_TYPE.DEVNET]:
    'https://small-billowing-card.solana-devnet.discover.quiknode.pro/00cd5707b04873db4881b4df25c062c8870068d8',
  [NETWORK_TYPE.TESTNET]: clusterApiUrl(NETWORK_TYPE.TESTNET),
  [NETWORK_TYPE.MAINNET]: clusterApiUrl(NETWORK_TYPE.MAINNET),
}

const SOLANA_CHAIN_IDS = {
  [NETWORK_TYPE.DEVNET]: SupportedChainId.SOLANA_DEVNET,
  [NETWORK_TYPE.TESTNET]: SupportedChainId.SOLANA_TESTNET,
  [NETWORK_TYPE.MAINNET]: SupportedChainId.SOLANA_MAINNET,
}

const SOLANA_NETWORK_TYPE = {
  [SupportedChainId.SOLANA_DEVNET]: NETWORK_TYPE.DEVNET,
  [SupportedChainId.SOLANA_TESTNET]: NETWORK_TYPE.TESTNET,
  [SupportedChainId.SOLANA_MAINNET]: NETWORK_TYPE.MAINNET,
}

export const NETWORK_URLS = {
  [SupportedChainId.SOLANA_MAINNET]: SOLANA_NETWORKS['mainnet-beta'],
  [SupportedChainId.SOLANA_TESTNET]: SOLANA_NETWORKS['testnet'],
  [SupportedChainId.SOLANA_DEVNET]: SOLANA_NETWORKS['devnet'],
}

export {
  AIRDROP_MINT,
  AUTH_ACCOUNT,
  CHAINLINK_BTC_ORACLE_ACCOUNT,
  CHAINLINK_PROGRAM_ACCOUNT,
  CHAINLINK_USDC_ORACLE_ACCOUNT,
  DEFAULT_DENOMINATOR,
  DEFAULT_PUBLIC_KEY,
  DEFAULT_RENT_EXEMPT,
  DEFAULT_SLIPPAGE,
  HYPERSEA_PROGRAM_ID,
  NETWORK_TYPE,
  PARTICIPATE_MINT,
  POOL_WITH_AIRDROP,
  PYTH_ACCOUNT,
  PYTH_BTC_ORACLE_ACCOUNT,
  PYTH_USDC_ORACLE_ACCOUNT,
  SOLANA_CHAIN_IDS,
  SOLANA_NETWORK_TYPE,
  SOLANA_NETWORKS,
  SUPPORTED_AIRDROP_TOKENS,
  SUPPORTED_SOLANA_CHAIN_ID,
  SWAP_FEE_ADDRESS,
  SWAP_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  WRAPPED_SOL_MINT,
}
