import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { AutoColumn } from 'components/Column'
import { loadingOpacityMixin } from 'components/Loader/styled'
import { MenuFlyout, ToggleMenuItem } from 'components/Menu'
import { SUPPORTED_AIRDROP_TOKENS } from 'constants/solana'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { debounce } from 'lodash'
import { darken } from 'polished'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Lock } from 'react-feather'
import { useModalOpen, useToggleModal } from 'state/application/hooks'
import styled from 'styled-components/macro'
import { getPriceImpact } from 'utils/calculatePriceImpact'

import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { useAccount } from '../../hooks/solana/useAccount'
import useTheme from '../../hooks/useTheme'
import { useSolanaCurrencyBalance } from '../../lib/hooks/useCurrencyBalance'
import { ThemedText } from '../../theme'
import { formatCurrencyAmount } from '../../utils/formatCurrencyAmount'
import { ButtonGray } from '../Button'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { Input as NumericalInput } from '../NumericalInput'
import { RowFixed } from '../Row'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { FiatValue } from './FiatValue'

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: 14px;
  background-color: ${({ theme, hideInput }) => (hideInput ? 'transparent' : theme.primary7)};
  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  transition: height 1s ease;
  will-change: height;
`

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.primary7};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Container = styled.div<{ hideInput: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.primary8};
  background-color: ${({ theme }) => theme.bg9};
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  height: 120px;

  :focus,
  :hover {
    border: 1px solid ${({ theme, hideInput }) => (hideInput ? ' transparent' : theme.bg3)};
  }
`

const CurrencySelect = styled(ButtonGray)<{ visible: boolean; selected: boolean; hideInput?: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.primary8};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  color: ${({ theme }) => theme.primaryText1};
  cursor: pointer;
  border-radius: 101px;
  outline: none;
  user-select: none;
  border: none;
  font-size: 24px;
  font-weight: 500;
  height: 40px;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  padding: 0 8px;
  justify-content: space-between;
  margin-left: ${({ hideInput }) => (hideInput ? '0' : '12px')};

  :focus,
  :hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary8)};
  }

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 24px;
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.primaryText1};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.35rem;
  height: 35%;

  path {
    stroke: ${({ theme }) => theme.primaryText1};
    stroke-width: 1.5px;
  }
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? ' margin: 0 4px 0 4px;' : ' margin: 0 4px 0 4px;')}
  font-size: ${({ active }) => (active ? '14px' : '14px')};
`

const StyledBalanceMax = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  background-color: ${({ theme }) => theme.primary8};
  border: none;
  border-radius: 6px;
  color: ${({ theme }) => theme.primaryText1};
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  margin-left: 0.25rem;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  padding: 4px 6px;
  pointer-events: ${({ disabled }) => (!disabled ? 'initial' : 'none')};

  :hover {
    opacity: ${({ disabled }) => (!disabled ? 0.8 : 0.4)};
  }

  :focus {
    outline: none;
  }
`

const StyledNumericalInput = styled(NumericalInput)<{ $loading: boolean }>`
  ${loadingOpacityMixin};
  text-align: left;
`

const StyledSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const FiatRow = styled.div`
  display: flex;
  position: absolute;
  flex: 1;
  left: 24px;
  bottom: 25px;
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  onAirdrop?: any
  showMaxButton: boolean
  label?: ReactNode
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: CurrencyAmount<Token> | null
  priceImpact?: string
  id: string
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  loading?: boolean
  style?: React.CSSProperties
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  onAirdrop,
  showMaxButton,
  onCurrencySelect,
  currency,
  otherCurrency,
  id,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  renderBalance,
  fiatValue,
  priceImpact,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  loading = false,
  ...rest
}: CurrencyInputPanelProps) {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(currency?.symbol ?? '')
  const toggleMenu = useToggleModal(currency?.symbol ?? '')
  useOnClickOutside(node, open ? toggleMenu : undefined)
  const [modalOpen, setModalOpen] = useState(false)

  const account = useAccount()

  const selectedCurrencyBalance = useSolanaCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const [pct, setPCT] = useState<any>(0)

  const calculatePriceImpact = useRef(debounce((v, p) => setPCT(getPriceImpact(v, p)), 600))

  useEffect(() => calculatePriceImpact.current(value, priceImpact), [value, priceImpact])

  const handleAirdrop = (tokens: any) => () => {
    toggleMenu()
    onAirdrop(tokens)()
  }

  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <Lock />
            <ThemedText.Label fontSize="12px" textAlign="center" padding="0 12px">
              <Trans>The market price is outside your specified price range. Single-asset deposit only.</Trans>
            </ThemedText.Label>
          </AutoColumn>
        </FixedContainer>
      )}
      <Container hideInput={hideInput}>
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={!onCurrencySelect}>
          {!hideInput && (
            <InputContainer>
              <LabelRow>{rest.label}</LabelRow>
              <StyledNumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={onUserInput}
                $loading={loading}
              />
            </InputContainer>
          )}

          <StyledSelectedContainer>
            <CurrencySelect
              visible={currency !== undefined}
              selected={!!currency}
              hideInput={hideInput && loading}
              className="open-currency-select-button"
              onClick={() => {
                if (onCurrencySelect) {
                  setModalOpen(true)
                }
              }}
            >
              <Aligner>
                <RowFixed>
                  {pair ? (
                    <span style={{ marginRight: '0.5rem' }}>
                      <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
                    </span>
                  ) : currency ? (
                    <CurrencyLogo currency={currency} size={'24px'} />
                  ) : null}
                  {pair ? (
                    <StyledTokenName className="pair-name-container">
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </StyledTokenName>
                  ) : (
                    <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                      {(currency && currency.symbol && currency.symbol.length > 20
                        ? currency.symbol.slice(0, 4) +
                          '...' +
                          currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                        : currency?.symbol) || <Trans>Select a token</Trans>}
                    </StyledTokenName>
                  )}
                </RowFixed>
                {onCurrencySelect && <StyledDropDown selected={!!currency} />}
              </Aligner>
            </CurrencySelect>
            {account ? (
              <RowFixed style={{ marginTop: '8px' }}>
                <ThemedText.Body
                  onClick={onMax}
                  color={theme.primaryText1}
                  fontWeight={600}
                  fontSize={14}
                  style={{ display: 'inline', cursor: 'pointer' }}
                >
                  {!hideBalance && currency && selectedCurrencyBalance ? (
                    renderBalance ? (
                      renderBalance(selectedCurrencyBalance)
                    ) : (
                      <Trans>Balance: {formatCurrencyAmount(selectedCurrencyBalance, 4)}</Trans>
                    )
                  ) : null}
                </ThemedText.Body>
                {showMaxButton && selectedCurrencyBalance ? (
                  <StyledBalanceMax onClick={onMax}>
                    <Trans>MAX</Trans>
                  </StyledBalanceMax>
                ) : null}
                {account &&
                !currency?.isNative &&
                !loading &&
                SUPPORTED_AIRDROP_TOKENS.includes(currency?.symbol || '') ? (
                  <div ref={node as any}>
                    <StyledBalanceMax onClick={toggleMenu}>
                      <Trans>Get Tokens</Trans>
                    </StyledBalanceMax>
                    {open && (
                      <MenuFlyout>
                        <ToggleMenuItem onClick={handleAirdrop(100)}>
                          <Trans>Get 100</Trans>
                        </ToggleMenuItem>
                        <ToggleMenuItem onClick={handleAirdrop(10000)}>
                          <Trans>Get 10000</Trans>
                        </ToggleMenuItem>
                      </MenuFlyout>
                    )}
                  </div>
                ) : null}
              </RowFixed>
            ) : null}
          </StyledSelectedContainer>
        </InputRow>
        {priceImpact ? (
          <FiatRow>
            <FiatValue fiatValue={null} priceImpact={new Percent(pct, 10000000)} />
          </FiatRow>
        ) : null}
      </Container>
      {onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          showCurrencyAmount={showCurrencyAmount}
          disableNonToken={disableNonToken}
        />
      )}
    </InputPanel>
  )
}
