import { useQuery } from '@tanstack/react-query'
import { fetchEventUserScore } from 'connectors/CompetitionEvent'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useParticipateInfo = (
  account = ''
): { userScore: number; isUserParticipant: boolean; refetch: () => void } => {
  const { pathname } = useLocation()

  const { data: userScore, refetch } = useQuery(['userScore'], async () => await fetchEventUserScore(account), {
    enabled: false,
  })

  useEffect(() => {
    if (account && pathname) {
      refetch()
    }
  }, [account, pathname, refetch])

  return {
    userScore: userScore?.score ?? -1,
    isUserParticipant: userScore?.score >= 0,
    refetch,
  }
}
