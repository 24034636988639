import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import Loader from 'components/Loader'
import { ArrowBackgroundContainer, ArrowLineContainer, ArrowWrapper } from 'components/swap/styleds'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import { addLiquidity, splAirdrop } from 'connectors/Solana/methods'
import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc'
import { useAccount } from 'hooks/solana/useAccount'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useContext, useState } from 'react'
import { Plus } from 'react-feather'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import { maxAmountSolanaSpend } from 'utils/maxAmountSpend'
import { convertAmount, PoolInfo } from 'utils/solana/accounts'

import { ButtonLight, ButtonPrimary } from '../../components/Button'
import { LightCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFlat } from '../../components/Row'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useV2RouterContract } from '../../hooks/useContract'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { PairState } from '../../hooks/useV2Pairs'
import { useAddPopup, useLoading, useSetLoading, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/mint/actions'
import {
  useDerivedMintInfo,
  useMintActionHandlers,
  useMintState,
  useSolanaDerivedMintInfo,
} from '../../state/mint/hooks'
import { useUserSlippageToleranceWithDefault } from '../../state/user/hooks'
import { ThemedText } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import AppBody from '../AppBody'
import { Dots, Wrapper } from '../Pool/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'

const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

const FeeContainer = styled.div`
  margin: 16px 0;
  padding: 16px 8px;
  border-radius: 14px;
  border: 1px solid primary8;
  background-color: ${({ theme }) => theme.bg8};
`

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { chainId, library, connector } = useActiveWeb3React()
  const account = useAccount()
  const loading = useLoading()
  const setLoading = useSetLoading()
  const addPopup = useAddPopup()

  const theme = useContext(ThemeContext)

  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  const wrappedNativeCurrency = chainId ? WRAPPED_NATIVE_CURRENCY[chainId] : undefined

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      wrappedNativeCurrency &&
      ((currencyA && currencyA.equals(wrappedNativeCurrency)) || (currencyB && currencyB.equals(wrappedNativeCurrency)))
  )

  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies: uniCurrencies,
    pair,
    pairState,
    currencyBalances: uniCurrencyBalances,
    parsedAmounts,
    price,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const solanaMintInfo = useSolanaDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)
  const currencyBalances = solanaMintInfo.currencyBalances || uniCurrencies
  const currencies = solanaMintInfo.currencies || uniCurrencyBalances
  const [depositToken] = useState<string>(solanaMintInfo.pair.A.mintAddress)
  const [depositType] = useState('both')
  const noLiquidity = true

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  // const deadline = useTransactionDeadline() // custom from users settings
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE) // custom from users
  const [txHash, setTxHash] = useState<string>('')

  const amountA = solanaMintInfo.pair.A.amount
  const amountB = solanaMintInfo.pair.B.amount

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSolanaSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const maxAmountA = maxAmounts?.[Field.CURRENCY_A]?.toExact() ?? 0
  const maxAmountB = maxAmounts?.[Field.CURRENCY_B]?.toExact() ?? 0

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: !noLiquidity
      ? otherTypedValue
      : dependentField === 'CURRENCY_B'
      ? isNaN(Number(amountB))
        ? '0'
        : Number(amountB) >= maxAmountB
        ? String(maxAmountB)
        : amountB
      : isNaN(Number(amountA))
      ? '0'
      : Number(amountA) >= maxAmountA
      ? String(maxAmountA)
      : amountA,
  }

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )

  const router = useV2RouterContract()

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], router?.address)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], router?.address)

  const getDepositToken = () => {
    if (!depositToken) {
      return undefined
    }
    return depositToken === solanaMintInfo.pair.A.mintAddress ? solanaMintInfo.pair.A : solanaMintInfo.pair.B
  }

  const onSolanaAdd = async (instance?: PoolInfo) => {
    setLoading(true)

    const { A, B, options } = solanaMintInfo.pair
    const currentDepositToken = getDepositToken()
    if (depositType === 'one' && currentDepositToken?.account && currentDepositToken.mint) {
      const components = [
        {
          account: currentDepositToken.account,
          mintAddress: currentDepositToken.mintAddress,
          amount: currentDepositToken.convertAmount(),
        },
      ]

      try {
        await addLiquidity(
          library,
          connector,
          components,
          account,
          Number(allowedSlippage.toSignificant()),
          instance,
          options,
          depositType
        )
        addPopup(
          {
            text: 'Succeeded add liquidity',
          },
          'add-liq',
          DEFAULT_TXN_DISMISS_MS
        )
      } catch (err) {
        addPopup(
          {
            text: err?.message ?? 'Error',
          },
          'add-liq',
          DEFAULT_TXN_DISMISS_MS
        )
      } finally {
        setLoading(false)
        return
      }
    } else if (A.account && B.account && A.mint && B.mint) {
      const components = [
        {
          account: A.account,
          mintAddress: A.mintAddress,
          amount: convertAmount(formattedAmounts[Field.CURRENCY_A], A.mint),
        },
        {
          account: B.account,
          mintAddress: B.mintAddress,
          amount: convertAmount(formattedAmounts[Field.CURRENCY_B], B.mint),
        },
      ]

      try {
        await addLiquidity(
          library,
          connector,
          components,
          account,
          Number(allowedSlippage.toSignificant()),
          instance,
          options
        )
        addPopup(
          {
            text: 'Succeeded add liquidity',
          },
          'add-liq',
          DEFAULT_TXN_DISMISS_MS
        )
      } catch (err) {
        addPopup(
          {
            text: err?.message ?? 'Error',
          },
          'add-liq',
          DEFAULT_TXN_DISMISS_MS
        )
      } finally {
        setLoading(false)
        return
      }
    }
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <LightCard mt="20px" $borderRadius="20px">
          <RowFlat>
            <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10}>
              {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
            </Text>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px">
        <RowFlat style={{ marginTop: '20px' }}>
          <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10}>
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
        <Row>
          <Text fontSize="24px">
            {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol + ' Pool Tokens'}
          </Text>
        </Row>
        <ThemedText.Italic fontSize={12} textAlign="left" padding={'8px 0 0 0 '}>
          <Trans>
            Output is estimated. If the price changes by more than {allowedSlippage.toSignificant(4)}% your transaction
            will revert.
          </Trans>
        </ThemedText.Italic>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={() => onSolanaAdd(solanaMintInfo?.pool)}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = (
    <Trans>
      Supplying {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} {currencies[Field.CURRENCY_A]?.symbol} and{' '}
      {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} {currencies[Field.CURRENCY_B]?.symbol}
    </Trans>
  )

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`)
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, history, currencyIdA]
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/add/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/add/${currencyIdA ? currencyIdA : 'SOL'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const isCreate = history.location.pathname.includes('/create')

  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  const handleAirdrop = (mint: any, wallet: any, library: any, decimals: any) => (amount: any) => async () => {
    try {
      await splAirdrop(mint, wallet, library, amount, decimals)

      addPopup(
        {
          text: 'Requested airdrop',
        },
        'airdrop',
        DEFAULT_TXN_DISMISS_MS
      )
    } catch (err) {
      addPopup(
        {
          text: err?.message ?? 'Error',
        },
        'airdrop',
        DEFAULT_TXN_DISMISS_MS
      )
    } finally {
      setLoading(true)
      setLoading(false)
    }
  }

  const isPossibleAmount =
    parseFloat(formattedAmounts[Field.CURRENCY_A] ?? '0') > 0 &&
    parseFloat(formattedAmounts[Field.CURRENCY_B] ?? '0') > 0
      ? parseFloat(formattedAmounts[Field.CURRENCY_A] ?? '0') > 0 &&
        parseFloat(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '') >=
          parseFloat(formattedAmounts[Field.CURRENCY_A] ?? '0') &&
        parseFloat(formattedAmounts[Field.CURRENCY_B] ?? '0') > 0 &&
        parseFloat(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '') >=
          parseFloat(formattedAmounts[Field.CURRENCY_B] ?? '0')
      : (parseFloat(formattedAmounts[Field.CURRENCY_A] ?? '0') > 0 &&
          parseFloat(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '') >=
            parseFloat(formattedAmounts[Field.CURRENCY_A] ?? '0')) ||
        (parseFloat(formattedAmounts[Field.CURRENCY_B] ?? '0') > 0 &&
          parseFloat(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '') >=
            parseFloat(formattedAmounts[Field.CURRENCY_B] ?? '0'))

  return (
    <>
      <AppBody>
        <AddRemoveTabs creating={isCreate} adding={true} defaultSlippage={DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE} />
        <Wrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            content={() => (
              <ConfirmationModalContent
                title={noLiquidity ? <Trans>You are creating a pool</Trans> : <Trans>You will receive</Trans>}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
            currencyToAdd={pair?.liquidityToken}
          />
          <AutoColumn gap="20px">
            <AutoColumn gap={'sm'}>
              <div style={{ position: 'relative' }}>
                <CurrencyInputPanel
                  value={formattedAmounts[Field.CURRENCY_A]}
                  onUserInput={onFieldAInput}
                  onMax={() => {
                    onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                  }}
                  onAirdrop={handleAirdrop(
                    solanaMintInfo?.pair?.A?.mintAddress,
                    connector,
                    library,
                    solanaMintInfo?.pair?.A?.mint?.decimals
                  )}
                  onCurrencySelect={handleCurrencyASelect}
                  showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                  currency={currencies[Field.CURRENCY_A] ?? null}
                  id="add-liquidity-input-tokena"
                  showCommonBases
                  style={{ marginBottom: '4px' }}
                  loading={loading}
                />
                <ArrowBackgroundContainer>
                  <ArrowLineContainer />
                  <ArrowWrapper clickable>
                    <Plus size="18" color={theme.primaryText1} />
                  </ArrowWrapper>
                </ArrowBackgroundContainer>
                <CurrencyInputPanel
                  value={formattedAmounts[Field.CURRENCY_B]}
                  onUserInput={onFieldBInput}
                  onCurrencySelect={handleCurrencyBSelect}
                  onMax={() => {
                    onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                  }}
                  onAirdrop={handleAirdrop(
                    solanaMintInfo?.pair?.B?.mintAddress,
                    connector,
                    library,
                    solanaMintInfo?.pair?.B?.mint?.decimals
                  )}
                  showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                  currency={currencies[Field.CURRENCY_B] ?? null}
                  id="add-liquidity-input-tokenb"
                  showCommonBases
                  loading={loading}
                />
              </div>
            </AutoColumn>
            {solanaMintInfo?.pair?.pool ? (
              <FeeContainer>
                <AutoColumn gap="8px">
                  <RowBetween>
                    <ThemedText.SubHeader fontSize={14} color={theme.primaryText1}>
                      <Trans>Pool Fee</Trans>
                    </ThemedText.SubHeader>
                    <ThemedText.Black textAlign="right" fontSize={14} color={theme.primaryText1}>
                      {solanaMintInfo?.pair?.pool?.fee}%
                    </ThemedText.Black>
                  </RowBetween>
                </AutoColumn>
              </FeeContainer>
            ) : null}
            {addIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <ThemedText.Main mb="4px">
                  <Trans>Unsupported Asset</Trans>
                </ThemedText.Main>
              </ButtonPrimary>
            ) : !account ? (
              <ButtonLight onClick={toggleWalletModal}>
                <Trans>Connect Wallet</Trans>
              </ButtonLight>
            ) : (
              <AutoColumn gap={'md'}>
                {(approvalA === ApprovalState.NOT_APPROVED ||
                  approvalA === ApprovalState.PENDING ||
                  approvalB === ApprovalState.NOT_APPROVED ||
                  approvalB === ApprovalState.PENDING) &&
                  isValid && (
                    <RowBetween>
                      {approvalA !== ApprovalState.APPROVED && (
                        <ButtonPrimary
                          onClick={approveACallback}
                          disabled={approvalA === ApprovalState.PENDING}
                          width={approvalB !== ApprovalState.APPROVED ? '48%' : '100%'}
                        >
                          {approvalA === ApprovalState.PENDING ? (
                            <Dots>
                              <Trans>Approving {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                            </Dots>
                          ) : (
                            <Trans>Approve {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                          )}
                        </ButtonPrimary>
                      )}
                      {approvalB !== ApprovalState.APPROVED && (
                        <ButtonPrimary
                          onClick={approveBCallback}
                          disabled={approvalB === ApprovalState.PENDING}
                          width={approvalA !== ApprovalState.APPROVED ? '48%' : '100%'}
                        >
                          {approvalB === ApprovalState.PENDING ? (
                            <Dots>
                              <Trans>Approving {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                            </Dots>
                          ) : (
                            <Trans>Approve {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                          )}
                        </ButtonPrimary>
                      )}
                    </RowBetween>
                  )}
                {loading ? (
                  <ButtonPrimary disabled>
                    <Loader />
                  </ButtonPrimary>
                ) : (solanaMintInfo?.pair?.pool && !isPossibleAmount) || !solanaMintInfo?.pair?.pool ? (
                  <ButtonPrimary disabled>
                    <Trans>Insufficient liquidity</Trans>
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary onClick={() => onSolanaAdd(solanaMintInfo?.pool)}>
                    <Trans>Supply</Trans>
                  </ButtonPrimary>
                )}
              </AutoColumn>
            )}
          </AutoColumn>
        </Wrapper>
      </AppBody>
      <SwitchLocaleLink />

      {!addIsUnsupported ? (
        pair && !noLiquidity && pairState !== PairState.INVALID ? (
          <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
            <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
          </AutoColumn>
        ) : null
      ) : (
        <UnsupportedCurrencyFooter
          show={addIsUnsupported}
          currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
        />
      )}
    </>
  )
}
