export type HyperseaV1 = {
  version: '0.1.0'
  name: 'hypersea_v1'
  instructions: [
    {
      name: 'initialize'
      accounts: [
        {
          name: 'poolState'
          isMut: true
          isSigner: true
        },
        {
          name: 'poolMint'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolFeeMint'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolRewardMint'
          isMut: false
          isSigner: false
        },
        {
          name: 'participateMint'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolFees'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolRewards'
          isMut: false
          isSigner: false
        },
        {
          name: 'vaultA'
          isMut: true
          isSigner: false
        },
        {
          name: 'vaultB'
          isMut: true
          isSigner: false
        },
        {
          name: 'vaultAMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'vaultBMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'oracles'
          accounts: [
            {
              name: 'pyth'
              accounts: [
                {
                  name: 'tokenA'
                  isMut: false
                  isSigner: false
                },
                {
                  name: 'tokenB'
                  isMut: false
                  isSigner: false
                }
              ]
            },
            {
              name: 'chainlink'
              accounts: [
                {
                  name: 'tokenA'
                  isMut: false
                  isSigner: false
                },
                {
                  name: 'tokenB'
                  isMut: false
                  isSigner: false
                }
              ]
            },
            {
              name: 'chainlinkProgram'
              isMut: false
              isSigner: false
            }
          ]
        },
        {
          name: 'authority'
          isMut: false
          isSigner: false
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'rent'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'airdropMint'
          type: {
            option: 'publicKey'
          }
        },
        {
          name: 'swapFee'
          type: {
            defined: 'Decimal'
          }
        }
      ]
    },
    {
      name: 'airdrop'
      accounts: [
        {
          name: 'poolState'
          isMut: false
          isSigner: false
        },
        {
          name: 'token'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'participateToken'
          isMut: true
          isSigner: false
        },
        {
          name: 'participateTokenMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'authority'
          isMut: false
          isSigner: false
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: []
    },
    {
      name: 'swap'
      accounts: [
        {
          name: 'poolMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'poolFeeMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'poolRewardMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'poolState'
          isMut: false
          isSigner: false
        },
        {
          name: 'poolFees'
          isMut: true
          isSigner: false
        },
        {
          name: 'poolRewards'
          isMut: true
          isSigner: false
        },
        {
          name: 'vaultA'
          isMut: true
          isSigner: false
        },
        {
          name: 'vaultB'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenInput'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenOutput'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenInputMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenOutputMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'participateToken'
          isMut: false
          isSigner: false
        },
        {
          name: 'oracles'
          accounts: [
            {
              name: 'pyth'
              accounts: [
                {
                  name: 'tokenA'
                  isMut: false
                  isSigner: false
                },
                {
                  name: 'tokenB'
                  isMut: false
                  isSigner: false
                }
              ]
            },
            {
              name: 'chainlink'
              accounts: [
                {
                  name: 'tokenA'
                  isMut: false
                  isSigner: false
                },
                {
                  name: 'tokenB'
                  isMut: false
                  isSigner: false
                }
              ]
            },
            {
              name: 'chainlinkProgram'
              isMut: false
              isSigner: false
            }
          ]
        },
        {
          name: 'authority'
          isMut: false
          isSigner: false
        },
        {
          name: 'userAuthority'
          isMut: false
          isSigner: true
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'amountIn'
          type: 'u64'
        },
        {
          name: 'minAmountOut'
          type: 'u64'
        }
      ]
    },
    {
      name: 'viewSwapPrice'
      accounts: [
        {
          name: 'poolState'
          isMut: false
          isSigner: false
        },
        {
          name: 'vaultA'
          isMut: false
          isSigner: false
        },
        {
          name: 'vaultB'
          isMut: false
          isSigner: false
        },
        {
          name: 'oracles'
          accounts: [
            {
              name: 'pyth'
              accounts: [
                {
                  name: 'tokenA'
                  isMut: false
                  isSigner: false
                },
                {
                  name: 'tokenB'
                  isMut: false
                  isSigner: false
                }
              ]
            },
            {
              name: 'chainlink'
              accounts: [
                {
                  name: 'tokenA'
                  isMut: false
                  isSigner: false
                },
                {
                  name: 'tokenB'
                  isMut: false
                  isSigner: false
                }
              ]
            },
            {
              name: 'chainlinkProgram'
              isMut: false
              isSigner: false
            }
          ]
        }
      ]
      args: [
        {
          name: 'amountIn'
          type: 'u64'
        },
        {
          name: 'side'
          type: 'u32'
        }
      ]
      returns: 'u64'
    },
    {
      name: 'depositAllTokens'
      accounts: [
        {
          name: 'poolMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'poolState'
          isMut: true
          isSigner: false
        },
        {
          name: 'vaultA'
          isMut: true
          isSigner: false
        },
        {
          name: 'vaultB'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenA'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenB'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenAMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenBMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'destination'
          isMut: true
          isSigner: false
        },
        {
          name: 'participateToken'
          isMut: false
          isSigner: false
        },
        {
          name: 'authority'
          isMut: false
          isSigner: false
        },
        {
          name: 'userAuthority'
          isMut: false
          isSigner: true
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'tokenAAmount'
          type: 'u64'
        },
        {
          name: 'tokenBAmount'
          type: 'u64'
        }
      ]
    },
    {
      name: 'withdrawAllTokens'
      accounts: [
        {
          name: 'poolMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'poolRewardMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'poolRewards'
          isMut: true
          isSigner: false
        },
        {
          name: 'poolState'
          isMut: true
          isSigner: false
        },
        {
          name: 'source'
          isMut: true
          isSigner: false
        },
        {
          name: 'vaultA'
          isMut: true
          isSigner: false
        },
        {
          name: 'vaultB'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenA'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenB'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenAMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenBMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'rewardToken'
          isMut: true
          isSigner: false
        },
        {
          name: 'participateToken'
          isMut: false
          isSigner: false
        },
        {
          name: 'authority'
          isMut: false
          isSigner: false
        },
        {
          name: 'userAuthority'
          isMut: false
          isSigner: true
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'liquidity'
          type: 'u64'
        },
        {
          name: 'minTokenAAmountOut'
          type: 'u64'
        },
        {
          name: 'minTokenBAmountOut'
          type: 'u64'
        }
      ]
    },
    {
      name: 'depositOneToken'
      accounts: [
        {
          name: 'poolMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'poolState'
          isMut: false
          isSigner: false
        },
        {
          name: 'vault'
          isMut: true
          isSigner: false
        },
        {
          name: 'token'
          isMut: true
          isSigner: false
        },
        {
          name: 'destination'
          isMut: true
          isSigner: false
        },
        {
          name: 'authority'
          isMut: false
          isSigner: false
        },
        {
          name: 'userAuthority'
          isMut: false
          isSigner: true
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'tokenAmount'
          type: 'u64'
        }
      ]
    },
    {
      name: 'withdrawOneToken'
      accounts: [
        {
          name: 'poolMint'
          isMut: true
          isSigner: false
        },
        {
          name: 'poolState'
          isMut: false
          isSigner: false
        },
        {
          name: 'source'
          isMut: true
          isSigner: false
        },
        {
          name: 'vault'
          isMut: true
          isSigner: false
        },
        {
          name: 'token'
          isMut: true
          isSigner: false
        },
        {
          name: 'authority'
          isMut: false
          isSigner: false
        },
        {
          name: 'userAuthority'
          isMut: false
          isSigner: true
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'liquidity'
          type: 'u64'
        },
        {
          name: 'minTokenAmount'
          type: 'u64'
        }
      ]
    }
  ]
  accounts: [
    {
      name: 'poolState'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'bump'
            type: 'u8'
          },
          {
            name: 'poolMint'
            type: 'publicKey'
          },
          {
            name: 'poolFeeMint'
            type: 'publicKey'
          },
          {
            name: 'poolRewardMint'
            type: 'publicKey'
          },
          {
            name: 'poolFees'
            type: 'publicKey'
          },
          {
            name: 'poolRewards'
            type: 'publicKey'
          },
          {
            name: 'vaultA'
            type: 'publicKey'
          },
          {
            name: 'vaultB'
            type: 'publicKey'
          },
          {
            name: 'vaultAMint'
            type: 'publicKey'
          },
          {
            name: 'vaultBMint'
            type: 'publicKey'
          },
          {
            name: 'airdropMint'
            type: {
              option: 'publicKey'
            }
          },
          {
            name: 'participateMint'
            type: 'publicKey'
          },
          {
            name: 'authority'
            type: 'publicKey'
          },
          {
            name: 'pythTokenAOracle'
            type: 'publicKey'
          },
          {
            name: 'pythTokenBOracle'
            type: 'publicKey'
          },
          {
            name: 'chainlinkTokenAOracle'
            type: 'publicKey'
          },
          {
            name: 'chainlinkTokenBOracle'
            type: 'publicKey'
          },
          {
            name: 'tokenProgram'
            type: 'publicKey'
          },
          {
            name: 'swapFee'
            type: {
              defined: 'Decimal'
            }
          },
          {
            name: 'totalAmountMinted'
            type: 'u128'
          },
          {
            name: 'initialized'
            type: 'bool'
          }
        ]
      }
    }
  ]
  types: [
    {
      name: 'Decimal'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'value'
            type: 'u32'
          },
          {
            name: 'scale'
            type: 'u32'
          }
        ]
      }
    },
    {
      name: 'SwapSide'
      type: {
        kind: 'enum'
        variants: [
          {
            name: 'AtoB'
          },
          {
            name: 'BtoA'
          }
        ]
      }
    }
  ]
  events: [
    {
      name: 'PoolInitializedEvent'
      fields: [
        {
          name: 'vaultA'
          type: 'publicKey'
          index: false
        },
        {
          name: 'vaultB'
          type: 'publicKey'
          index: false
        },
        {
          name: 'poolState'
          type: 'publicKey'
          index: false
        }
      ]
    },
    {
      name: 'SwapEvent'
      fields: [
        {
          name: 'source'
          type: 'publicKey'
          index: false
        },
        {
          name: 'destination'
          type: 'publicKey'
          index: false
        },
        {
          name: 'amountIn'
          type: 'u64'
          index: false
        },
        {
          name: 'amountOut'
          type: 'u64'
          index: false
        }
      ]
    },
    {
      name: 'AddLiquidityEvent'
      fields: [
        {
          name: 'liquidity'
          type: 'u64'
          index: false
        },
        {
          name: 'tokenAAmount'
          type: 'u64'
          index: false
        },
        {
          name: 'tokenBAmount'
          type: 'u64'
          index: false
        }
      ]
    },
    {
      name: 'RemoveLiquidityEvent'
      fields: [
        {
          name: 'liquidity'
          type: 'u64'
          index: false
        },
        {
          name: 'tokenAAmount'
          type: 'u64'
          index: false
        },
        {
          name: 'tokenBAmount'
          type: 'u64'
          index: false
        }
      ]
    }
  ]
  errors: [
    {
      code: 6000
      name: 'InvalidOwner'
      msg: 'Invalid owner'
    },
    {
      code: 6001
      name: 'InvalidAuthority'
      msg: 'Invalid authority'
    },
    {
      code: 6002
      name: 'InvalidAirdrop'
      msg: 'Invalid airdrop'
    },
    {
      code: 6003
      name: 'DoubleAirdrop'
      msg: 'Double airdrop'
    },
    {
      code: 6004
      name: 'InvalidTokenParicipateTokens'
      msg: 'Invalid participate tokens'
    },
    {
      code: 6005
      name: 'InvalidVaultA'
      msg: 'Vault `a` is invalid'
    },
    {
      code: 6006
      name: 'InvalidVaultB'
      msg: 'Vault `b` is invalid'
    },
    {
      code: 6007
      name: 'InvalidTokenAValue'
      msg: 'Invalid token `a` value'
    },
    {
      code: 6008
      name: 'InvalidTokenBValue'
      msg: 'Invalid token `b` value'
    },
    {
      code: 6009
      name: 'InvalidWeight'
      msg: 'Invalid weight'
    },
    {
      code: 6010
      name: 'InvalidMint'
      msg: 'Invalid mint'
    },
    {
      code: 6011
      name: 'InvalidMints'
      msg: 'Invalid mints'
    },
    {
      code: 6012
      name: 'InvalidInput'
      msg: 'Invalid input'
    },
    {
      code: 6013
      name: 'InvalidSupply'
      msg: 'Invalid supply'
    },
    {
      code: 6014
      name: 'InvalidCloseAuthority'
      msg: 'Invalid close authority'
    },
    {
      code: 6015
      name: 'InvalidProgramId'
      msg: 'Invalid program id'
    },
    {
      code: 6016
      name: 'InvalidProgramAddress'
      msg: 'Invalid program address'
    },
    {
      code: 6017
      name: 'InvalidSwapAccount'
      msg: 'Invalid swap account'
    },
    {
      code: 6018
      name: 'InvalidSwapAmmount'
      msg: 'Invalid swap ammount'
    },
    {
      code: 6019
      name: 'InvalidPoolMint'
      msg: 'Invalid pool mint'
    },
    {
      code: 6020
      name: 'InvalidParticipateMint'
      msg: 'Invalid participate mint'
    },
    {
      code: 6021
      name: 'InvalidTokenProgram'
      msg: 'Invalid token program'
    },
    {
      code: 6022
      name: 'InvalidFeeAccount'
      msg: 'Invalid fee account'
    },
    {
      code: 6023
      name: 'SplippageExceeded'
      msg: 'Splippage exceeded'
    },
    {
      code: 6024
      name: 'PythInvalidAccountData'
      msg: 'Failed to convert account into a Pyth account'
    },
    {
      code: 6025
      name: 'PythBadVersionNumber'
      msg: 'Incorrect version number for Pyth account'
    },
    {
      code: 6026
      name: 'PythWrongAccountType'
      msg: 'Incorrect account type'
    },
    {
      code: 6027
      name: 'InvalidCurrentPrice'
      msg: 'Invalid current price'
    },
    {
      code: 6028
      name: 'FailedToGetPriceFeeds'
      msg: 'Failed to get price feeds'
    },
    {
      code: 6029
      name: 'InsufficientLiquidityMinted'
      msg: 'Insufficient liquidity minted'
    },
    {
      code: 6030
      name: 'InsufficientLiquidityBurned'
      msg: 'Insufficient liquidity burned'
    },
    {
      code: 6031
      name: 'NotEnoughTokens'
      msg: 'Not enough tokens'
    },
    {
      code: 6032
      name: 'Unknown'
      msg: 'Unknown'
    }
  ]
}

export const IDL: HyperseaV1 = {
  version: '0.1.0',
  name: 'hypersea_v1',
  instructions: [
    {
      name: 'initialize',
      accounts: [
        {
          name: 'poolState',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'poolMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolFeeMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolRewardMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'participateMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolFees',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolRewards',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'vaultA',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultB',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultAMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultBMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'oracles',
          accounts: [
            {
              name: 'pyth',
              accounts: [
                {
                  name: 'tokenA',
                  isMut: false,
                  isSigner: false,
                },
                {
                  name: 'tokenB',
                  isMut: false,
                  isSigner: false,
                },
              ],
            },
            {
              name: 'chainlink',
              accounts: [
                {
                  name: 'tokenA',
                  isMut: false,
                  isSigner: false,
                },
                {
                  name: 'tokenB',
                  isMut: false,
                  isSigner: false,
                },
              ],
            },
            {
              name: 'chainlinkProgram',
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: 'authority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'airdropMint',
          type: {
            option: 'publicKey',
          },
        },
        {
          name: 'swapFee',
          type: {
            defined: 'Decimal',
          },
        },
      ],
    },
    {
      name: 'airdrop',
      accounts: [
        {
          name: 'poolState',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'token',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'participateToken',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'participateTokenMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'swap',
      accounts: [
        {
          name: 'poolMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'poolFeeMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'poolRewardMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'poolState',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'poolFees',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'poolRewards',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultA',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultB',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenInput',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenOutput',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenInputMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenOutputMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'participateToken',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'oracles',
          accounts: [
            {
              name: 'pyth',
              accounts: [
                {
                  name: 'tokenA',
                  isMut: false,
                  isSigner: false,
                },
                {
                  name: 'tokenB',
                  isMut: false,
                  isSigner: false,
                },
              ],
            },
            {
              name: 'chainlink',
              accounts: [
                {
                  name: 'tokenA',
                  isMut: false,
                  isSigner: false,
                },
                {
                  name: 'tokenB',
                  isMut: false,
                  isSigner: false,
                },
              ],
            },
            {
              name: 'chainlinkProgram',
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: 'authority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'userAuthority',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'amountIn',
          type: 'u64',
        },
        {
          name: 'minAmountOut',
          type: 'u64',
        },
      ],
    },
    {
      name: 'viewSwapPrice',
      accounts: [
        {
          name: 'poolState',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'vaultA',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'vaultB',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'oracles',
          accounts: [
            {
              name: 'pyth',
              accounts: [
                {
                  name: 'tokenA',
                  isMut: false,
                  isSigner: false,
                },
                {
                  name: 'tokenB',
                  isMut: false,
                  isSigner: false,
                },
              ],
            },
            {
              name: 'chainlink',
              accounts: [
                {
                  name: 'tokenA',
                  isMut: false,
                  isSigner: false,
                },
                {
                  name: 'tokenB',
                  isMut: false,
                  isSigner: false,
                },
              ],
            },
            {
              name: 'chainlinkProgram',
              isMut: false,
              isSigner: false,
            },
          ],
        },
      ],
      args: [
        {
          name: 'amountIn',
          type: 'u64',
        },
        {
          name: 'side',
          type: 'u32',
        },
      ],
      returns: 'u64',
    },
    {
      name: 'depositAllTokens',
      accounts: [
        {
          name: 'poolMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'poolState',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultA',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultB',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenA',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenB',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenAMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenBMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'destination',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'participateToken',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'userAuthority',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'tokenAAmount',
          type: 'u64',
        },
        {
          name: 'tokenBAmount',
          type: 'u64',
        },
      ],
    },
    {
      name: 'withdrawAllTokens',
      accounts: [
        {
          name: 'poolMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'poolRewardMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'poolRewards',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'poolState',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'source',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultA',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultB',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenA',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenB',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenAMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenBMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'rewardToken',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'participateToken',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'userAuthority',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'liquidity',
          type: 'u64',
        },
        {
          name: 'minTokenAAmountOut',
          type: 'u64',
        },
        {
          name: 'minTokenBAmountOut',
          type: 'u64',
        },
      ],
    },
    {
      name: 'depositOneToken',
      accounts: [
        {
          name: 'poolMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'poolState',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'vault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'token',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'destination',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'userAuthority',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'tokenAmount',
          type: 'u64',
        },
      ],
    },
    {
      name: 'withdrawOneToken',
      accounts: [
        {
          name: 'poolMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'poolState',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'source',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'token',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'userAuthority',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'liquidity',
          type: 'u64',
        },
        {
          name: 'minTokenAmount',
          type: 'u64',
        },
      ],
    },
  ],
  accounts: [
    {
      name: 'poolState',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'bump',
            type: 'u8',
          },
          {
            name: 'poolMint',
            type: 'publicKey',
          },
          {
            name: 'poolFeeMint',
            type: 'publicKey',
          },
          {
            name: 'poolRewardMint',
            type: 'publicKey',
          },
          {
            name: 'poolFees',
            type: 'publicKey',
          },
          {
            name: 'poolRewards',
            type: 'publicKey',
          },
          {
            name: 'vaultA',
            type: 'publicKey',
          },
          {
            name: 'vaultB',
            type: 'publicKey',
          },
          {
            name: 'vaultAMint',
            type: 'publicKey',
          },
          {
            name: 'vaultBMint',
            type: 'publicKey',
          },
          {
            name: 'airdropMint',
            type: {
              option: 'publicKey',
            },
          },
          {
            name: 'participateMint',
            type: 'publicKey',
          },
          {
            name: 'authority',
            type: 'publicKey',
          },
          {
            name: 'pythTokenAOracle',
            type: 'publicKey',
          },
          {
            name: 'pythTokenBOracle',
            type: 'publicKey',
          },
          {
            name: 'chainlinkTokenAOracle',
            type: 'publicKey',
          },
          {
            name: 'chainlinkTokenBOracle',
            type: 'publicKey',
          },
          {
            name: 'tokenProgram',
            type: 'publicKey',
          },
          {
            name: 'swapFee',
            type: {
              defined: 'Decimal',
            },
          },
          {
            name: 'totalAmountMinted',
            type: 'u128',
          },
          {
            name: 'initialized',
            type: 'bool',
          },
        ],
      },
    },
  ],
  types: [
    {
      name: 'Decimal',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'value',
            type: 'u32',
          },
          {
            name: 'scale',
            type: 'u32',
          },
        ],
      },
    },
    {
      name: 'SwapSide',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'AtoB',
          },
          {
            name: 'BtoA',
          },
        ],
      },
    },
  ],
  events: [
    {
      name: 'PoolInitializedEvent',
      fields: [
        {
          name: 'vaultA',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'vaultB',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'poolState',
          type: 'publicKey',
          index: false,
        },
      ],
    },
    {
      name: 'SwapEvent',
      fields: [
        {
          name: 'source',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'destination',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'amountIn',
          type: 'u64',
          index: false,
        },
        {
          name: 'amountOut',
          type: 'u64',
          index: false,
        },
      ],
    },
    {
      name: 'AddLiquidityEvent',
      fields: [
        {
          name: 'liquidity',
          type: 'u64',
          index: false,
        },
        {
          name: 'tokenAAmount',
          type: 'u64',
          index: false,
        },
        {
          name: 'tokenBAmount',
          type: 'u64',
          index: false,
        },
      ],
    },
    {
      name: 'RemoveLiquidityEvent',
      fields: [
        {
          name: 'liquidity',
          type: 'u64',
          index: false,
        },
        {
          name: 'tokenAAmount',
          type: 'u64',
          index: false,
        },
        {
          name: 'tokenBAmount',
          type: 'u64',
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: 'InvalidOwner',
      msg: 'Invalid owner',
    },
    {
      code: 6001,
      name: 'InvalidAuthority',
      msg: 'Invalid authority',
    },
    {
      code: 6002,
      name: 'InvalidAirdrop',
      msg: 'Invalid airdrop',
    },
    {
      code: 6003,
      name: 'DoubleAirdrop',
      msg: 'Double airdrop',
    },
    {
      code: 6004,
      name: 'InvalidTokenParicipateTokens',
      msg: 'Invalid participate tokens',
    },
    {
      code: 6005,
      name: 'InvalidVaultA',
      msg: 'Vault `a` is invalid',
    },
    {
      code: 6006,
      name: 'InvalidVaultB',
      msg: 'Vault `b` is invalid',
    },
    {
      code: 6007,
      name: 'InvalidTokenAValue',
      msg: 'Invalid token `a` value',
    },
    {
      code: 6008,
      name: 'InvalidTokenBValue',
      msg: 'Invalid token `b` value',
    },
    {
      code: 6009,
      name: 'InvalidWeight',
      msg: 'Invalid weight',
    },
    {
      code: 6010,
      name: 'InvalidMint',
      msg: 'Invalid mint',
    },
    {
      code: 6011,
      name: 'InvalidMints',
      msg: 'Invalid mints',
    },
    {
      code: 6012,
      name: 'InvalidInput',
      msg: 'Invalid input',
    },
    {
      code: 6013,
      name: 'InvalidSupply',
      msg: 'Invalid supply',
    },
    {
      code: 6014,
      name: 'InvalidCloseAuthority',
      msg: 'Invalid close authority',
    },
    {
      code: 6015,
      name: 'InvalidProgramId',
      msg: 'Invalid program id',
    },
    {
      code: 6016,
      name: 'InvalidProgramAddress',
      msg: 'Invalid program address',
    },
    {
      code: 6017,
      name: 'InvalidSwapAccount',
      msg: 'Invalid swap account',
    },
    {
      code: 6018,
      name: 'InvalidSwapAmmount',
      msg: 'Invalid swap ammount',
    },
    {
      code: 6019,
      name: 'InvalidPoolMint',
      msg: 'Invalid pool mint',
    },
    {
      code: 6020,
      name: 'InvalidParticipateMint',
      msg: 'Invalid participate mint',
    },
    {
      code: 6021,
      name: 'InvalidTokenProgram',
      msg: 'Invalid token program',
    },
    {
      code: 6022,
      name: 'InvalidFeeAccount',
      msg: 'Invalid fee account',
    },
    {
      code: 6023,
      name: 'SplippageExceeded',
      msg: 'Splippage exceeded',
    },
    {
      code: 6024,
      name: 'PythInvalidAccountData',
      msg: 'Failed to convert account into a Pyth account',
    },
    {
      code: 6025,
      name: 'PythBadVersionNumber',
      msg: 'Incorrect version number for Pyth account',
    },
    {
      code: 6026,
      name: 'PythWrongAccountType',
      msg: 'Incorrect account type',
    },
    {
      code: 6027,
      name: 'InvalidCurrentPrice',
      msg: 'Invalid current price',
    },
    {
      code: 6028,
      name: 'FailedToGetPriceFeeds',
      msg: 'Failed to get price feeds',
    },
    {
      code: 6029,
      name: 'InsufficientLiquidityMinted',
      msg: 'Insufficient liquidity minted',
    },
    {
      code: 6030,
      name: 'InsufficientLiquidityBurned',
      msg: 'Insufficient liquidity burned',
    },
    {
      code: 6031,
      name: 'NotEnoughTokens',
      msg: 'Not enough tokens',
    },
    {
      code: 6032,
      name: 'Unknown',
      msg: 'Unknown',
    },
  ],
}
