import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'

import { PoolsList } from './reducer'

export const updateList: ActionCreatorWithPayload<{ poolList: PoolsList; url: string }> =
  createAction('pools/updateList')
export const updateLoadingState: ActionCreatorWithPayload<{ loading: boolean; url: string }> =
  createAction('pools/updateLoadingState')
export const updateErrorState: ActionCreatorWithPayload<{ error: any; url: string }> =
  createAction('pools/updateErrorState')
