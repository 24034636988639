const SOLANA_LIST_MAINNET =
  'https://gist.githubusercontent.com/MaxDrozdov/10abf17e1a770a77c7c4533af011e289/raw/e247499860e8db3c897653faafc62b0d5da70027/solana-mainnet-tokens.json'
const SOLANA_LIST_TESTNET =
  'https://gist.githubusercontent.com/MaxDrozdov/9451f924511097407628f4a83d8dcd26/raw/923483e9d81574516cfa4fcaa632940f9f90dff9/solana-tokens-testnet.json'
const SOLANA_LIST_DEVNET =
  'https://gist.githubusercontent.com/MaxDrozdov/0ca81de2fbf8974e2faf8e161dd4c8fd/raw/4ba50941d3fa188aa56594b48873968e5281934a/solana-devnet-tokens.json'

const DEFAULT_LIST_OF_LISTS_TO_DISPLAY: string[] = [SOLANA_LIST_MAINNET, SOLANA_LIST_TESTNET, SOLANA_LIST_DEVNET]

export const DEFAULT_LIST_OF_LISTS: string[] = [...DEFAULT_LIST_OF_LISTS_TO_DISPLAY]

export const DEFAULT_ACTIVE_LIST_URLS: string[] = [SOLANA_LIST_MAINNET, SOLANA_LIST_TESTNET, SOLANA_LIST_DEVNET]
