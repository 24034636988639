export async function fetchEventRegister(walletAddress: string): Promise<any> {
  try {
    const response = await fetch(`${process?.env?.REACT_APP_EVENT_API_DEV ?? ''}/register`, {
      method: 'POST',
      body: JSON.stringify({ id: walletAddress }),
      headers: { 'Content-Type': 'application/json' },
    })

    if (response.status === 200) {
      return
    }

    throw new Error()
  } catch (error) {
    const message = `failed to fetch user position: ${walletAddress}`
    console.debug(message, error)
    throw new Error(message)
  }
}

export async function fetchEventUserScore(walletAddress: string): Promise<any> {
  try {
    const response = await fetch(`${process?.env?.REACT_APP_EVENT_API_DEV ?? ''}/userScore?id=${walletAddress}`)
    const json = await response.json()

    return json
  } catch (error) {
    const message = `failed to fetch user position: ${walletAddress}`
    console.debug(message, error)
    throw new Error(message)
  }
}

export async function fetchLeaderboard(count = 50): Promise<any> {
  try {
    const response = await fetch(`${process?.env?.REACT_APP_EVENT_API_DEV ?? ''}/leaderboard?count=${count}`)
    const json = await response.json()

    const leaderboard: any = json?.length ? json : []

    return leaderboard.sort((a: any, b: any) => b.score - a.score)
  } catch (error) {
    const message = `failed to fetch user leaderboard`
    console.debug(message, error)
    throw new Error(message)
  }
}
