import { Trans } from '@lingui/macro'
import { ButtonLight } from 'components/Button'
import { fetchEventRegister } from 'connectors/CompetitionEvent'
import { eventAirdrop } from 'connectors/Solana/methods'
import {
  EVENT_FIRST_STEP_DAYS,
  EVENT_SECOND_STEP_DAYS,
  EVENT_START,
  EVENT_THIRD_STEP_DAYS,
} from 'constants/competitionEvent/dates'
import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc'
import { add, format } from 'date-fns'
import { useParticipateInfo } from 'hooks/competitionEvent/useParticipateInfo'
import { useAccount } from 'hooks/solana/useAccount'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useHistory } from 'react-router-dom'
import { useAddPopup, useLoading, useSetLoading, useWalletModalToggle } from 'state/application/hooks'
import styled from 'styled-components/macro'

const TimelineContainer = styled.div`
  width: 70%;

  > main {
    max-width: none;
  }
`

const RulesContainer = styled.div`
  width: 70%;
  margin-top: 80px;

  > main {
    max-width: none;
  }
`

const Title = styled.div`
  font-weight: 600;
  font-size: 48px;
  line-height: 57px;
  margin-bottom: 56px;
  color: ${({ theme }) => theme.primaryText1};
  width: 100%;
  text-align: center;
`

const BodyWrapper = styled.div<{ timeline?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  background: ${({ theme }) => theme.bg7};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 42px;
  padding: 86px 84px;
`

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
`

const SubTitle = styled.div<{ fontSize?: string; lineHeight?: string }>`
  display: flex;
  width: 100%;
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize || '24px'};
  line-height: ${({ fontSize }) => fontSize || '29px'};
  color: ${({ theme }) => theme.primaryText1};
  padding-right: 32px;
`

const TimelineDate = styled.div`
  display: flex;
  width: 100%;
  padding-right: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 15px 0 20px;
  color: ${({ theme }) => theme.primaryText2};
`

const TimelineDescription = styled.div`
  display: flex;
  width: 100%;
  padding-right: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 15px 0 20px;
  color: ${({ theme }) => theme.primaryText1};
`

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
`

const CircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.primaryText1};
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100px;
  background: ${({ theme }) => theme.primary4};
`

const Line = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.primary4};
`

const RulesTitle = styled.div<{ margin?: string }>`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin: ${({ margin }) => margin || 0};
  color: ${({ theme }) => theme.primaryText1};
`

const RulesDescription = styled.div<{ margin?: string }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin: ${({ margin }) => margin || 0};
  color: ${({ theme }) => theme.primaryText1};
`

const StyledList = styled.ul`
  padding-inline-start: 24px;

  > li:not(:last-of-type) {
    margin-bottom: 16px;
  }
`

const JoinText = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.primary6};
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`

const ButtonContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const Rules = () => {
  const { library, connector } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const account = useAccount()
  const addPopup = useAddPopup()
  const loading = useLoading()
  const setLoading = useSetLoading()
  const history = useHistory()

  const { isUserParticipant, refetch } = useParticipateInfo(account)

  const handleRegister = async () => {
    try {
      setLoading(true)
      try {
        await eventAirdrop(library, connector, account)
      } catch (err) {
        throw new Error('Cannot airdrop tokens.')
      }
      try {
        await fetchEventRegister(account)
      } catch (err) {
        throw new Error(`Cannot register user. ${err.message}`)
      }
      addPopup(
        {
          text: `Successful registration`,
        },
        'register',
        DEFAULT_TXN_DISMISS_MS
      )
      history.push('/swap')
    } catch (err) {
      console.error('Register cancelled.', err)
      addPopup(
        {
          text: `Register cancelled. (${err?.message})`,
        },
        'register',
        DEFAULT_TXN_DISMISS_MS
      )
    } finally {
      refetch()
      setLoading(false)
    }
  }

  return (
    <>
      <TimelineContainer>
        <Title>
          <Trans>Event timeline</Trans>
        </Title>
        <BodyWrapper>
          <RowContainer>
            <SubTitle>
              <Trans>Referral Challenge</Trans>
            </SubTitle>{' '}
            <SubTitle fontSize="14px" lineHeight="17px">
              <Trans>Team Competition / Individual Bout / Record-Breakers</Trans>
            </SubTitle>
            <SubTitle>
              <Trans>Competiton results</Trans>
            </SubTitle>
          </RowContainer>
          <RowContainer>
            <TimelineDate>
              <Trans>
                {format(EVENT_START, 'yyyy-MM-dd')} to{' '}
                {format(add(EVENT_START, { days: EVENT_FIRST_STEP_DAYS }), 'yyyy-MM-dd')}
              </Trans>
            </TimelineDate>
            <TimelineDate>
              <Trans>
                {format(add(EVENT_START, { days: EVENT_FIRST_STEP_DAYS }), 'yyyy-MM-dd')} to{' '}
                {format(add(EVENT_START, { days: EVENT_FIRST_STEP_DAYS + EVENT_SECOND_STEP_DAYS }), 'yyyy-MM-dd')}
              </Trans>
            </TimelineDate>
            <TimelineDate>
              <Trans>
                {format(add(EVENT_START, { days: EVENT_FIRST_STEP_DAYS + EVENT_SECOND_STEP_DAYS }), 'yyyy-MM-dd')} to{' '}
                {format(
                  add(EVENT_START, { days: EVENT_FIRST_STEP_DAYS + EVENT_SECOND_STEP_DAYS + EVENT_THIRD_STEP_DAYS }),
                  'yyyy-MM-dd'
                )}
              </Trans>
            </TimelineDate>
          </RowContainer>
          <RowContainer>
            <LineContainer>
              <CircleContainer>1</CircleContainer>
              <Line />
            </LineContainer>
            <LineContainer>
              <CircleContainer>2</CircleContainer>
              <Line />
            </LineContainer>
            <LineContainer>
              <CircleContainer>3</CircleContainer>
              <Line />
            </LineContainer>
          </RowContainer>
          <RowContainer>
            <TimelineDescription>
              <Trans>
                Invite friends to join the team and accumulate points to increase your chances of winning NFTs
              </Trans>
            </TimelineDescription>
            <TimelineDescription>
              <Trans>Trade COIN-M and USD-M Futures to Win Up to $1,800,000</Trans>
            </TimelineDescription>
            <TimelineDescription>
              <Trans>
                The results will be announced after {format(add(new Date(), { days: 10 }), 'yyyy-MM-dd hh:mm')} (UTC +0){' '}
                and the rewards will be distributed within 14 days
              </Trans>
            </TimelineDescription>
          </RowContainer>
          {!account ? (
            <ButtonContent>
              <ButtonLight width="240px" height="48px" padding="4px" onClick={toggleWalletModal}>
                <Trans>Connect Wallet</Trans>
              </ButtonLight>
            </ButtonContent>
          ) : !!account && !isUserParticipant ? (
            <ButtonContent>
              <ButtonLight width="170px" height="48px" padding="4px" onClick={handleRegister} disabled={loading}>
                <Trans>Participate now</Trans>
              </ButtonLight>
            </ButtonContent>
          ) : null}
        </BodyWrapper>
      </TimelineContainer>
      <RulesContainer>
        <Title>
          <Trans>Rules</Trans>
        </Title>
        <BodyWrapper>
          <SubTitle>
            <Trans>Trading Competition - $15k in Rewards to Be Won!</Trans>
          </SubTitle>
          <TimelineDate>
            <Trans>
              Competition period: {format(new Date(), 'yyyy-MM-dd hh:mm')} to{' '}
              {format(add(new Date(), { days: 10 }), 'yyyy-MM-dd hh:mm')}
            </Trans>
          </TimelineDate>
          <RulesDescription margin="14px 0 0">
            <Trans>
              All users that during the competition period and trade 100USDT ​​or more effective trading volume
              (including buys & sells).
            </Trans>
          </RulesDescription>
          <RulesTitle margin="36px 0 0">
            <Trans>Promotion A: NEW USER Exclusive Rewards of $20,000 in ​​DOT tokens!</Trans>
          </RulesTitle>
          <RulesDescription margin="8px 0 0">
            <Trans>
              All users that register for a Binance account during the competition period and trade 25 DOT ​​or more
              effective trading volume (including buys & sells) across DOT/USDT, DOT/BUSD, DOT/BTC and DOT/BNB spot
              trading pairs will equally split an exclusive prize pool of $20,000 in ​​DOT token vouchers.
            </Trans>
          </RulesDescription>
          <RulesTitle margin="36px 0 0">
            <Trans>Promotion B: DOT Trading Competition - $40,000 in DOT to Be Won!</Trans>
          </RulesTitle>
          <RulesDescription margin="8px 0 0">
            <Trans>
              The top 20 users ranked by effective trading volume (including both buys and sells) across DOT/USDT,
              DOT/BUSD, DOT/BTC and DOT/BNB spot trading pairs will share a prize pool of $40,000 in DOT token vouchers.
              The rewards will be split based on each user’s total trading volume as a proportion of all qualified
              users’ total effective trading volume during the competition period.
            </Trans>
          </RulesDescription>
          <RulesTitle margin="36px 0 0">
            <Trans>Reward Calculation:</Trans>
          </RulesTitle>
          <RulesDescription margin="8px 0 0">
            <Trans>
              The reward for each qualified user = (Each user’s total effective trading volume / Top 20 users’ total
              effective trading volume) * $40,000 in DOT token vouchers.
            </Trans>
          </RulesDescription>
          <RulesTitle margin="36px 0 0">
            <Trans>Promotion C: Take the DOT Trading Challenge to Win $400 in DOT Token Vouchers</Trans>
          </RulesTitle>
          <RulesDescription margin="8px 0 0">
            <Trans>
              All users with a total trading volume (including both buys and sells) of 100 DOT or more across the
              activity qualified spot trading pairs during the competition period will be qualified to enter a
              challenge, where 100 winners will be selected based on the rules to each win $400 in DOT token vouchers.
            </Trans>
          </RulesDescription>
          <RulesTitle margin="36px 0 0">
            <Trans>Terms & Conditions:</Trans>
          </RulesTitle>
          <RulesDescription margin="8px 0 0">
            <StyledList>
              <li>
                <Trans>
                  If you don’t have a Binance account, click here to get a 10% trading fee discount. The 10% spot
                  trading fee discount will remain valid as long as the Binance referral program is in place. The 10%
                  futures trading fee discount shared by the inviter will last for 30 days from the date of Futures
                  account activation.
                </Trans>
              </li>
              <li>
                <Trans>Eligible DOT spot trading pairs: DOT/USDT, DOT/BUSD, DOT/BTC, DOT/BNB</Trans>
              </li>
              <li>
                <Trans>
                  Binance will use the average closing price at 11:59:59 PM (UTC) of the DOT/USDT trading pair during
                  the competition period as the DOT exchange rate used for distribution.
                </Trans>
              </li>
              <li>
                <Trans>
                  All users must have completed identity verification by the end of the activity period to be qualified
                  for any rewards.
                </Trans>
              </li>
              <li>
                <Trans>
                  For the award selection mechanics, please refer to Award Selection Rules Bases on Binance chain Hash
                  value.
                </Trans>
              </li>
              <li>
                <Trans>
                  Rewards will be distributed within 2 weeks after the competition ends. You will be able to log in and
                  redeem your voucher via Account &gt; Reward Center.
                </Trans>
              </li>
              <li>
                <Trans>
                  The validity period for the cash voucher is set at 14 days from the day of distribution. How to Redeem
                  a Cash Voucher.
                </Trans>
              </li>
              <li>
                <Trans>
                  Sub-account trading volume will be combined with the master account’s standard trading volume in the
                  final calculation. Each sub-account will not be viewed as an independent account when participating in
                  this activity.
                </Trans>
              </li>
              <li>
                <Trans>
                  Trading volume counts both buys and sells, excluding any wash trades, across all DOT spot trading
                  pairs.
                </Trans>
              </li>
              <li>
                <Trans>
                  Binance reserves the right to disqualify trades that are deemed to be wash trades or illegally bulk
                  registered accounts, as well as trades that display attributes of self-dealing or market manipulation.
                </Trans>
              </li>
              <li>
                <Trans>
                  Binance reserves the right to cancel or amend any Activity or Activity Rules at our sole discretion.
                </Trans>
              </li>
              <li>
                <Trans>
                  Where any discrepancy arises between the translated versions and the original English version, the
                  English version shall prevail.
                </Trans>
              </li>
            </StyledList>
          </RulesDescription>
          {!account ? (
            <ButtonContent>
              <ButtonLight width="240px" height="48px" padding="4px" onClick={toggleWalletModal}>
                <Trans>Connect Wallet</Trans>
              </ButtonLight>
            </ButtonContent>
          ) : !!account && !isUserParticipant ? (
            <>
              <JoinText>
                <Trans>Join the Trading Competition Now!</Trans>
              </JoinText>
              <ButtonContent>
                <ButtonLight width="170px" height="48px" padding="4px" onClick={handleRegister} disabled={loading}>
                  <Trans>Participate now</Trans>
                </ButtonLight>
              </ButtonContent>
            </>
          ) : null}
        </BodyWrapper>
      </RulesContainer>
    </>
  )
}

export default Rules
