/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  //Feature CHAIN
  MAINNET = 5,
  // ROPSTEN = 3,
  // RINKEBY = 4,
  // GOERLI = 5,
  // KOVAN = 42,

  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,

  OPTIMISM = 10,
  OPTIMISTIC_KOVAN = 69,

  POLYGON = 137,
  POLYGON_MUMBAI = 80001,

  SOLANA_MAINNET = 101,
  SOLANA_TESTNET = 102,
  SOLANA_DEVNET = 103,
}

export const enum ChainId {
  //feature CHAIN
  // MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  OPTIMISM = 10,
  OPTIMISTIC_KOVAN = 69,
  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,
  POLYGON = 137,
  POLYGON_MUMBAI = 80001,

  SOLANA_MAINNET = 101,
  SOLANA_TESTNET = 102,
  SOLANA_DEVNET = 103,
}

export const CHAIN_IDS_TO_NAMES = {
  //Feature CHAIN
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.POLYGON]: 'polygon',
  [SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
  [SupportedChainId.ARBITRUM_RINKEBY]: 'arbitrum_rinkeby',
  [SupportedChainId.OPTIMISM]: 'optimism',
  [SupportedChainId.OPTIMISTIC_KOVAN]: 'optimistic_kovan',
  [SupportedChainId.SOLANA_MAINNET]: 'solana_mainnet',
  [SupportedChainId.SOLANA_TESTNET]: 'solana_testnet',
  [SupportedChainId.SOLANA_DEVNET]: 'solana_devnet',
}

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = []

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  //Feature CHAIN
  SupportedChainId.SOLANA_MAINNET,
  SupportedChainId.SOLANA_TESTNET,
  SupportedChainId.SOLANA_DEVNET,
] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]

export function isSolanaChain(chainId: number): boolean {
  return (
    chainId === SupportedChainId.SOLANA_MAINNET ||
    chainId === SupportedChainId.SOLANA_TESTNET ||
    chainId === SupportedChainId.SOLANA_DEVNET
  )
}
