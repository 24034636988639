import { Web3Provider } from '@ethersproject/providers'
import { AccountLayout, MintLayout } from '@solana/spl-token'
import { AccountInfo, PublicKey } from '@solana/web3.js'
import { SOLANA_POOLS_DEVNET, SOLANA_POOLS_MAINNET, SOLANA_POOLS_TESTNET } from 'constants/pools'
import { HYPERSEA_PROGRAM_ID, SOLANA_CHAIN_IDS } from 'constants/solana'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLoading } from 'state/application/hooks'
import { useAppSelector } from 'state/hooks'
import { PoolInfo, queryAccountInfo, tokenAccountFactory } from 'utils/solana/accounts'

import useActiveWeb3React from '../useActiveWeb3React'
import { useFetchPoolsCallback } from '../useFetchPoolsCallback'
import { useAccount } from './useAccount'

export enum RequestState {
  pending = 'pending',
  succeed = 'succeed',
}

export type RequestStateType = Record<string, RequestState>

const defaultPoolsState: {
  cachedPools: PoolInfo[]
  cachedAccounts: Map<string, AccountInfo<Buffer>>
  setCachedPools: (nextValues: PoolInfo[]) => void
  setCachedAccounts: (nextValues: any) => void
  requestsState: { current: RequestStateType }
  onChangeRequestState: (id: string, nextState: RequestState) => void
  userBalance: number
  setUserBalance: () => number
  cachedUserAccounts: Map<string, AccountInfo<Buffer>>
  setCachedUserAccounts: (nextValues: any) => void
} = {
  cachedPools: [],
  cachedAccounts: new Map(),
  setCachedPools: () => null,
  setCachedAccounts: () => null,
  requestsState: { current: {} },
  onChangeRequestState: () => null,
  userBalance: 0,
  setUserBalance: () => 0,
  cachedUserAccounts: new Map(),
  setCachedUserAccounts: () => null,
}
export const PoolsContext = createContext(defaultPoolsState)

export const PoolsProvider = (props: any) => {
  const globalLoading = useLoading()
  const userAccount = useAccount()
  const { chainId, library } = useActiveWeb3React()
  const poolList: any = useAppSelector((state) => state.pools.byUrl)
  const fetchPools = useFetchPoolsCallback()

  const [cachedPools, setCachedPools] = useState<PoolInfo[]>([])
  const [cachedAccounts, setCachedAccounts] = useState(new Map())
  const [cachedUserAccounts, setCachedUserAccounts] = useState(new Map())
  const [userBalance, setUserBalance] = useState(0)
  const requestsState = useRef() as any

  useEffect(() => {
    ;[SOLANA_POOLS_DEVNET, SOLANA_POOLS_MAINNET, SOLANA_POOLS_TESTNET].forEach((url) => fetchPools(url))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let url = ''
  switch (chainId) {
    case SOLANA_CHAIN_IDS['mainnet-beta']:
      url = SOLANA_POOLS_MAINNET
      break
    case SOLANA_CHAIN_IDS.testnet:
      url = SOLANA_POOLS_TESTNET
      break
    case SOLANA_CHAIN_IDS.devnet:
      url = SOLANA_POOLS_DEVNET
  }

  const onChangeRequestState = useCallback(
    (id: string, state: RequestState) => {
      if (!requestsState.current) {
        requestsState.current = {
          [id]: state,
        }
      } else {
        requestsState.current[id] = state
      }
    },
    [requestsState]
  )

  const updateUserBalance = async () => {
    const result = await library?.send('eth_getBalance', [userAccount])

    setUserBalance(result || 0)
  }

  const updatePools = async () => {
    const queryPools = async (swapId: PublicKey) => {
      onChangeRequestState(swapId.toBase58(), RequestState.pending)
      const poolsArray: PoolInfo[] = []
      const preparedPools: any = poolList?.[url]?.current?.length
        ? poolList[url].current.map((pool: any) => ({
            ...pool,
            pubkeys: {
              program: new PublicKey(pool?.pubkeys?.program),
              account: new PublicKey(pool?.pubkeys?.account),
              holdingAccounts: [
                new PublicKey(pool?.pubkeys?.holdingAccounts[0]),
                new PublicKey(pool?.pubkeys?.holdingAccounts[1]),
              ],
              holdingMints: [
                new PublicKey(pool?.pubkeys?.holdingMints[0]),
                new PublicKey(pool?.pubkeys?.holdingMints[1]),
              ],
              mint: new PublicKey(pool?.pubkeys?.mint),
              feeAccount: new PublicKey(pool?.pubkeys?.feeAccount),
              feeMint: new PublicKey(pool?.pubkeys?.feeMint),
              rewardsAccount: new PublicKey(pool?.pubkeys?.rewardsAccount),
              rewardsMint: new PublicKey(pool?.pubkeys?.rewardsMint),
            },
            raw: {
              pubkey: new PublicKey(0),
              data: null,
              account: {
                executable: true,
                owner: new PublicKey(0),
                lamports: 100000000,
                data: new Buffer(''),
                rentEpoch: 123,
              },
            },
          }))
        : []

      preparedPools.forEach((item: any) => {
        poolsArray.push(item)
      })

      const toQuery = [
        ...poolsArray
          .map(
            (p) =>
              [
                ...p.pubkeys.holdingAccounts.map((h) => h.toBase58()),
                ...p.pubkeys.holdingMints.map((h) => h.toBase58()),
                p.pubkeys.account?.toBase58(),
                p.pubkeys.feeAccount?.toBase58(),
                p.pubkeys.mint?.toBase58(),
              ].filter((p) => p) as string[]
          )
          .filter((p) => p !== undefined)
          .flat()
          .reduce((acc, item) => {
            acc.add(item)
            return acc
          }, new Set<string>())
          .keys(),
      ].sort()

      const tempMap = new Map()
      await getMultipleAccounts(library, toQuery, 'single').then(({ keys, array }) => {
        return array
          .map((obj, index) => {
            if (!obj) {
              return undefined
            }
            const pubKey = keys[index]
            if (obj.data.length === AccountLayout.span) {
              return tempMap.set(pubKey, obj)
            } else if (obj.data.length === MintLayout.span) {
              return tempMap.set(pubKey, obj)
            }
            return tempMap.set(pubKey, obj)
          })
          .filter((a) => !!a) as any[]
      })
      setCachedAccounts(tempMap)
      return poolsArray
    }

    Promise.all([queryPools(HYPERSEA_PROGRAM_ID)]).then((all: any) => {
      onChangeRequestState(HYPERSEA_PROGRAM_ID.toBase58(), RequestState.succeed)
      setCachedPools(all.flat())
    })
  }

  const updateUserBalances = async () => {
    const tempMap = new Map()
    const responseBalances = await library?.send('sol_getTokenBalances', [userAccount])
    responseBalances?.forEach((balance: any) => {
      tempMap.set(balance.pubkey.toBase58(), balance.account)
    })
    setCachedUserAccounts(tempMap)
  }

  useEffect(() => {
    if (userAccount && !globalLoading) {
      updateUserBalance()
      updateUserBalances()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccount, globalLoading])

  useEffect(() => {
    if (chainId && !globalLoading && url) {
      updatePools()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, globalLoading, url])

  return (
    <PoolsContext.Provider
      value={{
        cachedPools,
        setCachedPools,
        requestsState,
        onChangeRequestState,
        cachedAccounts,
        setCachedAccounts,
        cachedUserAccounts,
        setCachedUserAccounts,
        userBalance,
        setUserBalance,
      }}
      {...props}
    />
  )
}

export const chunks = (array: any, size: number) => {
  return Array.apply(0, new Array(Math.ceil(array.length / size))).map((_, index) =>
    array.slice(index * size, (index + 1) * size)
  )
}

const getMultipleAccountsCore = async (library: any, keys: string[], commitment: string) => {
  const unsafeRes = await library?.send('sol_getMultipleAccounts', [keys.map((key) => new PublicKey(key)), commitment])

  if (unsafeRes) {
    const array = unsafeRes as AccountInfo<string[]>[]
    return { keys, array }
  } else {
    throw new Error()
  }
}

export const getMultipleAccounts = async (connection: any, keys: string[], commitment: string) => {
  const result = await Promise.all(
    chunks(keys, 99).map((chunk) => getMultipleAccountsCore(connection, chunk, commitment))
  )

  const array = result
    .map(
      (a) =>
        a.array.map((acc: any) => {
          if (!acc) {
            return undefined
          }

          const { data, ...rest } = acc
          const obj = {
            ...rest,
            data: Buffer.from(data, 'base64'),
          } as AccountInfo<Buffer>
          return obj
        }) as AccountInfo<Buffer>[]
    )
    .flat()
  return { keys, array }
}

export const usePoolForBasket = (mints: (string | undefined)[], library: Web3Provider | undefined) => {
  const { cachedPools: pools } = useContext(PoolsContext)
  const { requestsState, cachedAccounts, setCachedAccounts } = useContext(PoolsContext)
  const [pool, setPool] = useState<PoolInfo>()
  const sortedMints = useMemo(() => [...mints].sort(), [...mints]); // eslint-disable-line

  useEffect(() => {
    const getPools = async () => {
      // reset pool during query
      setPool(undefined)
      const matchingPool = pools
        .filter((p) => !p.legacy)
        .filter((p) =>
          p.pubkeys.holdingMints
            .map((a) => a.toBase58())
            .sort()
            .every((address, i) => address === sortedMints[i])
        )

      const poolQuantities: { [pool: string]: number } = {}

      const poolsKeys = matchingPool.reduce<string[]>(
        (acc, pool) => [...acc, pool.pubkeys.holdingAccounts[0].toBase58(), pool.pubkeys.holdingAccounts[1].toBase58()],
        []
      )

      const accounts = await Promise.all(
        poolsKeys.map(async (key) => {
          if (cachedAccounts.has(key)) {
            return tokenAccountFactory(new PublicKey(key), cachedAccounts.get(key))
          } else {
            const acc = await queryAccountInfo(library, key)
            setCachedAccounts((prev: any) => new Map(prev).set(key, acc))
            return tokenAccountFactory(new PublicKey(key), acc)
          }
        })
      )

      for (let i = 0; i < accounts.length; i += 2) {
        const account0 = accounts[i]
        const account1 = accounts[i + 1]

        const amount =
          (Number.parseInt(account0?.info.amount.toString() || '0') || 0) +
          (Number.parseInt(account1?.info.amount.toString() || '0') || 0)
        if (amount > 0) {
          poolQuantities[(i / 2).toString()] = amount
        }
      }
      if (Object.keys(poolQuantities).length > 0) {
        const sorted = Object.entries(poolQuantities).sort(([pool0Idx, amount0], [pool1Idx, amount1]) =>
          amount0 > amount1 ? -1 : 1
        )
        const bestPool = matchingPool[parseInt(sorted[0][0])]
        setPool(bestPool)
      } else if (matchingPool.length > 0) {
        setPool(matchingPool[0])
      }

      return
    }

    if (requestsState.current?.[HYPERSEA_PROGRAM_ID.toBase58()] === RequestState.succeed && cachedAccounts.size) {
      getPools()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [library, sortedMints, pools, requestsState, cachedAccounts])

  return pool
}
