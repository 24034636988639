import { Trans } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import { fetchLeaderboard } from 'connectors/CompetitionEvent'
import { EVENT_END } from 'constants/competitionEvent/dates'
import { formatDuration, intervalToDuration } from 'date-fns'
import { useParticipateInfo } from 'hooks/competitionEvent/useParticipateInfo'
import { useAccount } from 'hooks/solana/useAccount'
import { darken } from 'polished'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components/macro'
import { formatWalletAddress } from 'utils/common'
import { numberConversation } from 'utils/conversation'

const TableContainer = styled.div`
  width: 70%;

  > main {
    max-width: none;
  }

  > a {
    text-align: center;
  }
`

const Title = styled.div`
  font-weight: 600;
  font-size: 48px;
  line-height: 57px;
  color: ${({ theme }) => theme.primaryText1};
  width: 100%;
  text-align: center;
`

const BodyWrapper = styled.div<{ timeline?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  background: ${({ theme }) => theme.bg7};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 42px;
  padding: 40px 32px;
  margin-top: 49px;

  .table {
    display: flex;
    flex-flow: column nowrap;
    font-size: 0.8rem;
    margin: 0.5rem;
    line-height: 1.5;
    flex: 1 1 auto;

    > .tr:not(:first-child) {
      margin-bottom: 8px;
    }
  }

  .th {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;

    > div:nth-child(2) {
      flex-grow: 4;
      margin-left: 16px;
    }
  }

  .th > .td {
    white-space: normal;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 24px;
  }

  .tr {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    align-items: center;
    height: 68px;

    > div:nth-child(2) {
      flex-grow: 4;
      justify-content: flex-start;
      margin-left: 16px;
    }
  }

  .rainbow-border {
    border-radius: 14px;
    border: 2px solid transparent;
    background: linear-gradient(${({ theme }) => theme.bg12}, ${({ theme }) => theme.bg12}) padding-box,
      linear-gradient(
          90deg,
          #1d189f -0.07%,
          #9222ff 19.96%,
          #ff0cbf 39.99%,
          #ff536d 60.01%,
          #ff7bac 80.04%,
          #26bcff 100.07%
        )
        border-box;
    background-repeat: no-repeat;
  }

  .pink-border {
    border-radius: 14px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    background: linear-gradient(${({ theme }) => theme.bg12}, ${({ theme }) => theme.bg12}) padding-box,
      linear-gradient(90deg, #ff0bc1 0%, rgba(255, 11, 193, 0) 100%) border-box;
    background-repeat: no-repeat;
  }

  .pink-background {
    border-radius: 14px;
    background: linear-gradient(90deg, rgba(255, 11, 193, 0.5) 0%, rgba(255, 11, 193, 0) 100%);
    background-repeat: no-repeat;
  }

  .blue-border {
    border-radius: 14px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    background: linear-gradient(${({ theme }) => theme.bg12}, ${({ theme }) => theme.bg12}) padding-box,
      linear-gradient(90deg, #26bcff 0%, rgba(38, 188, 255, 0) 100%) border-box;
    background-repeat: no-repeat;
  }

  .blue-background {
    border-radius: 14px;
    background: linear-gradient(90deg, rgba(38, 188, 255, 0.5) 0%, rgba(38, 188, 255, 0) 100%);
    background-repeat: no-repeat;
  }

  .purple-border {
    border-radius: 14px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    background: linear-gradient(${({ theme }) => theme.bg12}, ${({ theme }) => theme.bg12}) padding-box,
      linear-gradient(90deg, #9222ff 0%, rgba(146, 34, 255, 0) 100%) border-box;
    background-repeat: no-repeat;
  }

  .purple-background {
    border-radius: 14px;
    background: linear-gradient(90deg, rgba(146, 34, 255, 0.5) 0%, rgba(146, 34, 255, 0) 100%);
    background-repeat: no-repeat;
  }

  .gray-border {
    border-radius: 14px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    background: linear-gradient(${({ theme }) => theme.bg12}, ${({ theme }) => theme.bg12}) padding-box,
      ${({ theme }) => theme.bg14};
    background-repeat: no-repeat;
  }

  .gray-background {
    border-radius: 14px;
    background: ${({ theme }) => theme.bg13};
    background-repeat: no-repeat;
  }

  .td {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    flex-basis: 0;
    padding: 8px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0px;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
  }
`

const TimeEnds = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.primary9};
  text-align: center;
  margin-top: 16px;
  margin-bottom: 19px;
`

const StyledTime = styled.span`
  font-size: 24px;
`

const RulesContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
`

const CompetitionRulesLink = styled(Link)`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryText1};

  :hover {
    color: ${({ theme }) => darken(0.05, theme.primaryText1)};
  }

  :focus {
    outline: none;
  }

  :active {
    text-decoration: none;
  }
`

const PositionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 36px;
  color: ${({ theme }) => theme.white};

  .ring-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 34px;
    height: 34px;
    background: #241b46;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 8px 18px rgba(12, 2, 50, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 34px;
  }

  .position-container {
    display: flex;
    height: 34px;
    min-width: 34px;
    border-radius: 34px;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const PositionSubheader = styled.div`
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 2px;
  color: ${({ theme }) => darken(0.05, theme.primaryText2)};
`

const LeaderboardContainer = styled.div`
  margin-top: 48px;
  width: 100%;
`

const CurrentPositionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 87px;
  background: linear-gradient(${({ theme }) => theme.bg12}, ${({ theme }) => theme.bg12}) padding-box,
    linear-gradient(
        90deg,
        #1d189f -0.07%,
        #9222ff 19.96%,
        #ff0cbf 39.99%,
        #ff536d 60.01%,
        #ff7bac 80.04%,
        #26bcff 100.07%
      )
      border-box;
  border-radius: 14px;
  border: 2px solid transparent;

  .position-label {
    width: 140px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.black};
    top: -23px;
    left: 20px;
    background: linear-gradient(
      89.77deg,
      #1d189f -53.68%,
      #9222ff -12.1%,
      #ff0cbf 29.48%,
      #ff536d 71.07%,
      #ff7bac 112.65%,
      #26bcff 154.23%
    );
    border-radius: 8px 8px 0px 0px;
  }
`

const rewards = [
  { position: '1', reward: 1000 },
  { position: '2', reward: 500 },
  { position: '3', reward: 250 },
  { position: '4-10', reward: 125 },
  { position: '10-20', reward: 65 },
  { position: '20-50', reward: 30 },
]

const Leaderboard = () => {
  const account = useAccount()
  const [eventDuration, setEventDuration] = useState<string>('')

  const { userScore } = useParticipateInfo(account)

  const { data: leaderboard }: any = useQuery(['leaderboard'], async () => await fetchLeaderboard())

  const userPosition = useMemo(() => {
    const positionInArray = leaderboard?.findIndex(({ id }: { id: string }) => id === account)

    if (positionInArray >= 0) {
      return positionInArray + 1
    }

    return 0
  }, [account, leaderboard])

  const userReward = useMemo(() => {
    if (userScore >= 0) {
      const rewardsId = rewards.findIndex((reward) => {
        const range = reward.position.split('-')

        if (userPosition === 0) {
          return false
        }

        if (range.length > 1) {
          return Number(range[0]) <= userPosition && Number(range[1]) >= userPosition
        } else {
          return Number(range[0]) === userPosition
        }
      })

      return rewards?.[rewardsId]?.reward ?? 0
    }

    return 0
  }, [userPosition, userScore])

  const groupedLeaderBoard = useMemo(() => {
    if (leaderboard && leaderboard.length) {
      const groupByRewards = new Map()

      leaderboard.forEach((user: any, index: number) => {
        const rewardsId = rewards.findIndex((reward) => {
          const range = reward.position.split('-')

          if (range.length > 1) {
            return Number(range[0]) <= index + 1 && Number(range[1]) >= index + 1
          } else {
            return Number(range[0]) === index + 1
          }
        })

        if (groupByRewards.has(rewards[rewardsId].position)) {
          groupByRewards.set(rewards[rewardsId].position, [
            ...groupByRewards.get(rewards[rewardsId].position),
            { ...user, isCurrentUser: account === user.id },
          ])
        } else {
          groupByRewards.set(rewards[rewardsId].position, [{ ...user, isCurrentUser: account === user.id }])
        }
      })

      return Array.from(groupByRewards, ([name, value]) => ({
        id: name,
        users: value,
        reward: rewards.find(({ position }) => position === name)?.reward ?? 0,
      }))
    }

    return []
  }, [account, leaderboard])

  useEffect(() => {
    if (!eventDuration) {
      setEventDuration(
        formatDuration(intervalToDuration({ start: new Date(), end: EVENT_END }), {
          format: ['days', 'hours', 'minutes'],
        })
      )
    }

    const id = setInterval(() => {
      setEventDuration(
        formatDuration(intervalToDuration({ start: new Date(), end: EVENT_END }), {
          format: ['days', 'hours', 'minutes'],
        })
      )
    }, 60000)

    return () => {
      clearInterval(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TableContainer>
        <Title>
          <Trans>Leaderboard</Trans>
        </Title>
        <TimeEnds>
          <Trans>
            Competition ends in <StyledTime>{eventDuration}</StyledTime>
          </Trans>
        </TimeEnds>
        <RulesContainer>
          <CompetitionRulesLink to="/rules">Terms and conditions</CompetitionRulesLink>
        </RulesContainer>
        <BodyWrapper>
          {!!account ? (
            <CurrentPositionContainer>
              <div key="id" className={`tr `}>
                <div className="td">
                  <PositionContainer>
                    <div className="ring-container">
                      <Text className={`position-container position-gray-background`} fontWeight={600} fontSize={16}>
                        {userPosition === 0 ? '50+' : userPosition || '-'}
                      </Text>
                    </div>
                  </PositionContainer>
                </div>
                <div className="td">
                  <Text fontSize={14} fontWeight={500}>
                    {!!account ? formatWalletAddress(account) : '-'}
                  </Text>
                </div>
                <div className="td">
                  <Column>
                    <PositionSubheader>Score</PositionSubheader>
                    <Text fontSize={14} fontWeight={500}>
                      {numberConversation(userScore) || '0'}
                    </Text>
                  </Column>
                </div>
                <div className="td">
                  <Column>
                    <PositionSubheader>Reward</PositionSubheader>
                    <Text fontSize={14} fontWeight={500}>
                      ${userReward}
                    </Text>
                  </Column>
                </div>
              </div>
              <div className="position-label">Your current position</div>
            </CurrentPositionContainer>
          ) : null}
          <LeaderboardContainer>
            <div className="table">
              <div className="th">
                <div className="td">Position</div>
                <div className="td"></div>
                <div className="td">Score</div>
                <div className="td">Reward</div>
              </div>
              {groupedLeaderBoard?.length > 0 ? (
                groupedLeaderBoard.map(({ id, users, reward }: any, index: number) => {
                  const hasCurrentUser = users.some(({ isCurrentUser }: { isCurrentUser: boolean }) => isCurrentUser)

                  const borderClass =
                    hasCurrentUser === true
                      ? 'rainbow-border'
                      : index === 0
                      ? 'pink-border'
                      : index === 1
                      ? 'blue-border'
                      : index === 2
                      ? 'purple-border'
                      : 'gray-border'

                  const backgroundClass =
                    index === 0
                      ? 'pink-background'
                      : index === 1
                      ? 'blue-background'
                      : index === 2
                      ? 'purple-background'
                      : 'gray-background'

                  return (
                    <div key={id} className={`tr ${borderClass}`}>
                      <div className={`tr ${backgroundClass}`}>
                        <div className="td">
                          <PositionContainer>
                            <div className="ring-container">
                              <Text
                                className={`position-container position-${backgroundClass}`}
                                fontWeight={600}
                                fontSize={16}
                              >
                                {id}
                              </Text>
                            </div>
                          </PositionContainer>
                        </div>
                        <div className="td">
                          {users.length > 1 ? (
                            <Text fontSize={14} fontWeight={500}>
                              {hasCurrentUser ? 'Your position here' : ''}
                            </Text>
                          ) : (
                            <Text fontSize={14} fontWeight={500}>
                              {hasCurrentUser ? 'Your position here' : formatWalletAddress(users[0].id)}
                            </Text>
                          )}
                        </div>
                        <div className="td">
                          {users.length > 1 ? (
                            <Text fontSize={14} fontWeight={500}>
                              {numberConversation(users[users.length - 1].score)}
                            </Text>
                          ) : (
                            <Text fontSize={14} fontWeight={500}>
                              {numberConversation(users[0].score)}
                            </Text>
                          )}
                        </div>
                        <div className="td">
                          <Text fontSize={14} fontWeight={500}>
                            ${reward}
                          </Text>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <Text fontSize={24} fontWeight={600} textAlign="center">
                  <Trans>Empty leaderboard</Trans>
                </Text>
              )}
            </div>
          </LeaderboardContainer>
        </BodyWrapper>
      </TableContainer>
    </>
  )
}

export default Leaderboard
