import { Trans } from '@lingui/macro'
import { BN } from '@project-serum/anchor'
import { PublicKey } from '@solana/web3.js'
import { Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useAccount } from 'hooks/solana/useAccount'
import { getMultipleAccounts, PoolsContext, usePoolForBasket } from 'hooks/solana/usePools'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useLoading } from 'state/application/hooks'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'
import { deserializeAccount, deserializeMint } from 'utils/solana/accounts'

import { useTotalSupply } from '../../hooks/useTotalSupply'
import { useV2Pair } from '../../hooks/useV2Pairs'
import { AppState } from '../index'
import { useTokenBalances } from '../wallet/hooks'
import { Field, typeInput } from './actions'

export function useBurnState(): AppState['burn'] {
  return useAppSelector((state) => state.burn)
}

export function useDerivedBurnInfo(
  currencyA: Currency | undefined,
  currencyB: Currency | undefined
): {
  pair?: Pair | null
  parsedAmounts: {
    [Field.LIQUIDITY_PERCENT]: Percent
    [Field.LIQUIDITY]?: CurrencyAmount<Token>
    [Field.CURRENCY_A]?: CurrencyAmount<Currency>
    [Field.CURRENCY_B]?: CurrencyAmount<Currency>
  }
  error?: ReactNode
} {
  const { account } = useActiveWeb3React()

  const { independentField, typedValue } = useBurnState()

  // pair + totalsupply
  const [, pair] = useV2Pair(currencyA, currencyB)

  // balances
  const relevantTokenBalances = useTokenBalances(account ?? undefined, [pair?.liquidityToken])
  const userLiquidity: undefined | CurrencyAmount<Token> = relevantTokenBalances?.[pair?.liquidityToken?.address ?? '']

  const [tokenA, tokenB] = [currencyA?.wrapped, currencyB?.wrapped]
  const tokens = {
    [Field.CURRENCY_A]: tokenA,
    [Field.CURRENCY_B]: tokenB,
    [Field.LIQUIDITY]: pair?.liquidityToken,
  }

  // liquidity values
  const totalSupply = useTotalSupply(pair?.liquidityToken)
  const liquidityValueA =
    pair &&
    totalSupply &&
    userLiquidity &&
    tokenA &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalSupply.quotient, userLiquidity.quotient)
      ? CurrencyAmount.fromRawAmount(tokenA, pair.getLiquidityValue(tokenA, totalSupply, userLiquidity, false).quotient)
      : undefined
  const liquidityValueB =
    pair &&
    totalSupply &&
    userLiquidity &&
    tokenB &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalSupply.quotient, userLiquidity.quotient)
      ? CurrencyAmount.fromRawAmount(tokenB, pair.getLiquidityValue(tokenB, totalSupply, userLiquidity, false).quotient)
      : undefined
  const liquidityValues: { [Field.CURRENCY_A]?: CurrencyAmount<Token>; [Field.CURRENCY_B]?: CurrencyAmount<Token> } = {
    [Field.CURRENCY_A]: liquidityValueA,
    [Field.CURRENCY_B]: liquidityValueB,
  }

  let percentToRemove: Percent = new Percent('0', '100')
  // user specified a %
  if (independentField === Field.LIQUIDITY_PERCENT) {
    percentToRemove = new Percent(typedValue, '100')
  }
  // user specified a specific amount of liquidity tokens
  else if (independentField === Field.LIQUIDITY) {
    if (pair?.liquidityToken) {
      const independentAmount = tryParseCurrencyAmount(typedValue, pair.liquidityToken)
      if (independentAmount && userLiquidity && !independentAmount.greaterThan(userLiquidity)) {
        percentToRemove = new Percent(independentAmount.quotient, userLiquidity.quotient)
      }
    }
  }
  // user specified a specific amount of token a or b
  else {
    if (tokens[independentField]) {
      const independentAmount = tryParseCurrencyAmount(typedValue, tokens[independentField])
      const liquidityValue = liquidityValues[independentField]
      if (independentAmount && liquidityValue && !independentAmount.greaterThan(liquidityValue)) {
        percentToRemove = new Percent(independentAmount.quotient, liquidityValue.quotient)
      }
    }
  }

  const parsedAmounts: {
    [Field.LIQUIDITY_PERCENT]: Percent
    [Field.LIQUIDITY]?: CurrencyAmount<Token>
    [Field.CURRENCY_A]?: CurrencyAmount<Currency>
    [Field.CURRENCY_B]?: CurrencyAmount<Currency>
  } = {
    [Field.LIQUIDITY_PERCENT]: percentToRemove,
    [Field.LIQUIDITY]:
      userLiquidity && percentToRemove && percentToRemove.greaterThan('0')
        ? CurrencyAmount.fromRawAmount(
            userLiquidity.currency,
            percentToRemove.multiply(userLiquidity.quotient).quotient
          )
        : undefined,
    [Field.CURRENCY_A]:
      tokenA && percentToRemove && percentToRemove.greaterThan('0') && liquidityValueA
        ? CurrencyAmount.fromRawAmount(tokenA, percentToRemove.multiply(liquidityValueA.quotient).quotient)
        : undefined,
    [Field.CURRENCY_B]:
      tokenB && percentToRemove && percentToRemove.greaterThan('0') && liquidityValueB
        ? CurrencyAmount.fromRawAmount(tokenB, percentToRemove.multiply(liquidityValueB.quotient).quotient)
        : undefined,
  }

  let error: ReactNode | undefined
  if (!account) {
    error = <Trans>Connect Wallet</Trans>
  }

  if (!parsedAmounts[Field.LIQUIDITY] || !parsedAmounts[Field.CURRENCY_A] || !parsedAmounts[Field.CURRENCY_B]) {
    error = error ?? <Trans>Enter an amount</Trans>
  }

  return { pair, parsedAmounts, error }
}

export function useBurnActionHandlers(): {
  onUserInput: (field: Field, typedValue: string) => void
} {
  const dispatch = useAppDispatch()

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  return {
    onUserInput,
  }
}

export function useSolanaDerivedBurnInfo(currencyA: Currency | undefined, currencyB: Currency | undefined): any {
  const account = useAccount()
  const loading = useLoading()
  const { library, chainId } = useActiveWeb3React()
  const [burnInfo, setBurnInfo] = useState<any>(null)
  const { cachedUserAccounts } = useContext(PoolsContext)

  const { typedValue } = useBurnState()

  const [tokenA, tokenB]: any = [currencyA?.wrapped, currencyB?.wrapped]

  const pool: any = usePoolForBasket([tokenA?._checksummedAddress, tokenB?._checksummedAddress], library)

  useEffect(() => {
    const getPoolOfLiq = async () => {
      const accounts: any =
        Array.from(cachedUserAccounts, ([name, value]) => ({
          info: deserializeAccount(value.data),
          pubkey: new PublicKey(name),
        })) || []

      const liqAccount = accounts.find((acc: any) => acc.info.mint.toBase58() === pool?.pubkeys?.mint?.toBase58()) ?? ''

      const percentToRemove = new Percent(typedValue, '100')

      const liqAmount = liqAccount?.info?.amount
        ? new BN(percentToRemove.multiply(JSBI.BigInt(liqAccount.info.amount)).quotient.toString())
        : new BN(0)

      const liqAccounts = await getMultipleAccounts(
        library,
        [pool?.pubkeys?.mint?.toBase58(), pool?.pubkeys.holdingAccounts[0], pool?.pubkeys.holdingAccounts[1]],
        'single'
      )

      const totalSupply = deserializeMint(liqAccounts?.array[0]?.data)?.supply || new BN(0)
      const totalValueA = deserializeAccount(liqAccounts?.array[1]?.data)?.amount || new BN(0)
      const totalValueB = deserializeAccount(liqAccounts?.array[2]?.data)?.amount || new BN(0)

      const tokenAmountA = liqAmount.mul(totalValueA).div(totalSupply)
      const tokenAmountB = liqAmount.mul(totalValueB).div(totalSupply)

      setBurnInfo({
        [Field.LIQUIDITY_PERCENT]: percentToRemove,
        [Field.LIQUIDITY]:
          liqAmount.toNumber() > 0 && percentToRemove && percentToRemove.greaterThan('0')
            ? CurrencyAmount.fromRawAmount(
                new WrappedTokenInfo({
                  chainId: 101,
                  address: liqAccount.pubkey,
                  name: 'LIQ',
                  decimals: 9,
                  symbol: 'LIQ',
                }),
                liqAmount.toString()
              )
            : undefined,
        [Field.CURRENCY_A]:
          tokenAmountA &&
          tokenAmountA.gt(new BN(0)) &&
          percentToRemove &&
          percentToRemove.greaterThan('0') &&
          totalValueA &&
          totalValueA.gt(new BN(0))
            ? CurrencyAmount.fromRawAmount(tokenA, tokenAmountA.toString())
            : undefined,
        [Field.CURRENCY_B]:
          tokenAmountB &&
          tokenAmountB.gt(new BN(0)) &&
          percentToRemove &&
          percentToRemove.greaterThan('0') &&
          totalValueB &&
          totalValueB.gt(new BN(0))
            ? CurrencyAmount.fromRawAmount(tokenB, tokenAmountB.toString())
            : undefined,
      })
    }

    if (pool && !loading) {
      getPoolOfLiq()
    }
  }, [pool, chainId, library, account, loading, typedValue, tokenA, tokenB, cachedUserAccounts])

  return { pool, burnInfo }
}
