import { useEffect, useState } from 'react'
import { useWeb3React } from 'web3-react-core'

export const useAccount = () => {
  const { connector } = useWeb3React()
  const [solanaAccount, setSolanaAccount] = useState<string>('')

  useEffect(() => {
    const getAccount = async () => {
      try {
        const connectorAccount = (await connector?.getAccount()) ?? ''
        setSolanaAccount(connectorAccount)
      } catch (err) {
        console.error(err)
      }
    }

    getAccount()
  }, [connector])

  return solanaAccount ?? ''
}
