import { Web3Provider } from '@ethersproject/providers'
import { MintInfo, u64 } from '@solana/spl-token'
import { AccountInfo, PublicKey } from '@solana/web3.js'
import { WRAPPED_SOL_MINT } from 'constants/solana'
import { useContext, useEffect, useMemo, useState } from 'react'
import {
  convertAmount,
  PoolConfig,
  queryAccountInfo,
  queryMintInfo,
  TokenAccount,
  tokenMintFactory,
} from 'utils/solana/accounts'

import { useAccount } from './useAccount'
import { PoolsContext } from './usePools'

const wrapNativeAccount = (
  pubkey: string | null | undefined,
  account?: AccountInfo<Buffer>
): TokenAccount | undefined => {
  if (!account || !pubkey) {
    return undefined
  }

  return {
    pubkey: new PublicKey(pubkey),
    account,
    info: {
      address: new PublicKey(pubkey),
      mint: WRAPPED_SOL_MINT,
      owner: new PublicKey(pubkey),
      amount: new u64(account.lamports),
      delegate: null,
      delegatedAmount: new u64(0),
      isInitialized: true,
      isFrozen: false,
      isNative: true,
      rentExemptReserve: null,
      closeAuthority: null,
    },
  }
}

export const useCurrencyLeg = (config: PoolConfig, library: Web3Provider | undefined, defaultMint?: string) => {
  const currentUserAccount = useAccount()
  const [amount, setAmount] = useState('')
  const { cachedAccounts } = useContext(PoolsContext)
  const [theBestAmount, setTheBestAmount] = useState('')
  const [mintAddress, setMintAddress] = useState(defaultMint || '')
  const [mint, setMint] = useState<MintInfo | undefined>(undefined)
  const [account, setAccount] = useState<TokenAccount | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      if (mintAddress) {
        let info: any = null
        if (cachedAccounts.has(mintAddress)) {
          info = tokenMintFactory(cachedAccounts.get(mintAddress))
        } else {
          info = await queryMintInfo(library, new PublicKey(mintAddress))
          info = tokenMintFactory(info)
        }

        setMint(info)
      }

      if (currentUserAccount) {
        let accountInfo: any = null
        if (cachedAccounts.has(currentUserAccount)) {
          accountInfo = cachedAccounts.get(currentUserAccount)
        } else {
          accountInfo = await queryAccountInfo(library, new PublicKey(currentUserAccount))
        }

        setAccount(wrapNativeAccount(currentUserAccount, accountInfo))
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintAddress, currentUserAccount])

  return useMemo(
    () => ({
      mintAddress,
      account,
      mint,
      amount,
      theBestAmount,
      setAmount,
      setTheBestAmount,
      setMint: setMintAddress,
      convertAmount: () => convertAmount(amount, mint),
    }),
    [mintAddress, account, mint, amount, theBestAmount]
  )
}
